// 新闻文章配置相关
import { POST, GET } from '@/utils/util';
var NewsLetterApi = {
  // 列表
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/news/letter/manage/list', params);
  },
  // 创建/编辑
  save: function save() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/news/letter/save', params);
  },
  // 删除
  delete: function _delete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/news/letter/delete', params);
  },
  // 详情
  find: function find(id) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return GET("/api/accountservice/news/letter/".concat(id), params);
  }
};
export default NewsLetterApi;