import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var order = [
//鉴定订单
{
  path: '/check/index',
  name: '鉴定订单管理',
  type: 2,
  meta: {
    title: '鉴定订单管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/orderManagement/index.vue");
  }
}, {
  path: '/check/detail',
  name: '鉴定订单详情',
  type: 3,
  meta: {
    title: '鉴定订单详情',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/orderManagement/detail.vue");
  }
},
// sales order
{
  path: '/saleOrder/index',
  name: '销售订单管理',
  type: 3,
  meta: {
    title: '销售订单管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/saleOrder/index");
  }
}, {
  path: '/saleOrder/detail',
  name: '销售订单详情',
  type: 3,
  meta: {
    title: '销售订单详情',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/saleOrder/detail");
  }
}, {
  path: '/saleOrder/addressInfo',
  name: '销售订单物流信息',
  type: 3,
  meta: {
    title: '销售订单物流信息',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/afterSalesOrder/addressInfo");
  }
},
// after sales order
{
  path: '/afterSalesOrder/index',
  name: '售后订单管理',
  type: 3,
  meta: {
    title: '售后订单管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/afterSalesOrder/index");
  }
}, {
  path: '/afterSalesOrder/detail',
  name: '售后订单详情',
  type: 3,
  meta: {
    title: '售后订单详情',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/saleOrder/detail");
  }
}, {
  path: '/afterSalesOrder/addressInfo',
  name: '售后订单物流信息',
  type: 3,
  meta: {
    title: '售后订单物流信息',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/afterSalesOrder/addressInfo");
  }
},
// Identification currency
{
  path: '/identificationCurrency/index',
  name: '鉴定币充值订单',
  type: 3,
  meta: {
    title: '鉴定币充值订单',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/identificationCurrency");
  }
}];
export default order;