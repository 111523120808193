export default {
  name: "popup",
  data: function data() {
    return {};
  },
  props: {
    // 标题
    title: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    // 是否展示
    comVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    // 宽度
    modalWidth: {
      type: String,
      default: function _default() {
        return '496px';
      }
    },
    // 容器是否垂直居中
    isCentered: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    // 点击蒙层是否允许关闭
    isMaskClosable: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    // 是否展示关闭icon
    isClosable: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    // 关闭时销毁 Modal 里的子元素
    isDestroyOnClose: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  computed: {},
  watch: {},
  methods: {}
};