export default {
  searchForm: {
    creatorNickName: 'Creator nickname',
    keywordSearching: 'Keyword searching',
    reporterID: 'Reporter ID',
    creatorID: 'Creator ID',
    modifiedTime: 'Modified time',
    beginTime: 'begin_time',
    overTime: 'over_time',
    annotatorName: 'Annotator username'
  },
  detail: 'Detail',
  fans: 'Fans',
  source: 'Source',
  creator: 'Creator',
  statusList: {
    state0: 'Auditing',
    state1: 'Pass',
    state2: 'Rejected',
    state3: 'user deleted'
  },
  viewDetail: {
    state: 'State',
    title: 'Title'
  },
  modifiedTime: 'Modified Time',
  videoState: 'Video state',
  state: 'State',
  annotator: 'Annotator',
  unprocessed: 'Unprocessed',
  processed: 'Processed',
  mark: 'Detail',
  logTitle01: 'Batch Pass',
  logTitle02: 'Batch Reject',
  logTitle03: 'Log',
  logTitle04: 'Post Upload',
  detailDefaultTitle: 'Post Annotation',
  detailTitleCompleted: '{title} 【Post ID: {editId}】',
  detailForm: {
    title: 'Title：',
    nickName: 'Nickname：',
    userId: 'User ID：',
    category: 'Category',
    timeliness: 'Timeliness：',
    quality: 'Quality',
    issueCBK: 'Issue CBK',
    yes: 'Yes',
    no: 'No',
    remark: 'Remark',
    placeholder01: 'Enter the title',
    placeholder02: 'Choose',
    placeholder03: 'Select reason template',
    placeholder04: 'Enter reject reason',
    message01: 'Select state',
    message02: 'Enter reject reason',
    message03: 'Maximum 200 characters',
    message04: 'Enter the title',
    message05: 'Select content category',
    message06: 'Select content quality',
    warining01: 'Category,Timeliness,Quality select at least one'
  },
  detailBtn: {
    submit: 'Submit',
    next: 'Next',
    viewLogs: 'View logs'
  },
  add: 'Post Upload',
  postId: 'Post ID',
  username: 'Nickname',
  keyword: 'Keyword',
  creatorID: 'Creator ID',
  createTime: 'Create time',
  beginTime: 'begin_time',
  overTime: 'over_time',
  type: 'Type',
  typeList: {
    type1: 'Video',
    type2: 'Photo'
  },
  dragUploadHint: '{0} format, maximum single file size {1}, up to {2} files in once.',
  form: {
    title: 'Post title',
    creator: 'Creator',
    note: 'Notes',
    creatorNote: 'Enter nickname to search',
    tag: 'Tag',
    cover: 'Cover',
    allowDownload: 'Allow user download',
    uploadSubtitle: 'Upload subtitle',
    allow: 'Allow',
    notAllowed: 'Not allowed',
    noFile: 'No {0}'
  },
  edit: 'Edit',
  selectLeastOne: 'Please select at least one post.',
  reason: 'Reason',
  score: 'Score'
};