// 获奖列表页面的所有接口
import { POST, GET } from '@/utils/util';
var AwardsApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000767
  // 获奖列表-列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/phpservice/admin/activity/awards/index', params);
  },
  // 获奖列表-详情
  detail: function detail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/phpservice/admin/activity/awards/detail', params);
  },
  notes: function notes() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/phpservice/admin/activity/awards/notes', params);
  }
};
export default AwardsApi;