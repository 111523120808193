import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var brand = [{
  path: '/brand/index',
  name: '品牌管理',
  type: 2,
  meta: {
    title: '品牌管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/brand");
  }
}, {
  path: '/brand/add',
  name: '新增',
  type: 2,
  meta: {
    title: '新增品牌',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/brand/addBrand");
  }
}, {
  path: '/brand/edit',
  name: '修改',
  type: 2,
  meta: {
    title: '修改品牌',
    breadcrumbHidden: true
  },
  component: function component() {
    return import("@/views/brand/addBrand");
  }
}];
export default brand;