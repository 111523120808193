import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
// 使用中文 test2
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import enUS from "ant-design-vue/lib/locale-provider/en_US";
// import moment from "moment";
import "moment/locale/zh-cn";
// import store from "@/store";
import { mapGetters } from 'vuex';
if (window.addEventListener) {
  window.addEventListener("resize", function () {
    setHtmlFontSize();
  }, false);
}
function setHtmlFontSize() {
  var deviceWidth;
  //  1366是设计稿的宽度，当大于1366时采用1366宽度，比例也是除以13.66 //
  deviceWidth = document.documentElement.clientWidth > 1366 ? 1366 : document.documentElement.clientWidth;
  document.getElementsByTagName("html")[0].style.cssText = "font-size:" + deviceWidth / 13.66 + "px !important";
}
export default {
  name: "app",
  data: function data() {
    return {
      // locale: zhCN,
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('lang', ['lang'])), {}, {
    key: function key() {
      return this.$route.name || this.$route.path;
    },
    locale: function locale() {
      if (this.lang == 'en') {
        return enUS;
      }
      return zhCN;
    }
  }),
  created: function created() {
    this.$i18n.locale = this.lang;
    // setTimeout(()=>{
    //     store.state.base.allMenuList.forEach(item => {
    //         if(item.name.includes("商家管理")){
    //                 item.children.forEach(ite => {
    //                     if(ite.name.includes("商家审核")){
    //                         console.log("商家审核");
    //                     }
    //                 })
    //         }

    //     });
    // },1000)
    setHtmlFontSize();
    this.bindEvent();
  },
  methods: {
    bindEvent: function bindEvent() {
      var _this = this;
      // level success' | 'error' | 'info' | 'warning' | 'warn' | 'loading'
      window.GLOBAL.vbus.$on("message", function (level, content, onClose) {
        var message = _this.$message;
        message[level](content, onClose);
      });
      // 在接口统一处理用到
      window.GLOBAL.vbus.$on("router", function (router) {
        _this.$router.push(router);
      });
    }
  }
};