export default {
  appName: 'Content Operation Platform',
  admin: "Admin",
  search: "Search",
  reset: "Reset",
  submit: "Submit",
  confirm: "Confirm",
  cancel: "Cancel",
  close: "Close",
  total: "Total",
  type: "Type",
  edit: "Edit",
  delete: "Delete",
  operation: "Operation",
  all: "All",
  select: "Select",
  selectAll: "Select All",
  batchPass: "Batch Pass",
  batchDelete: "Batch Delete",
  batchReject: "Batch Reject",
  video: "Video",
  photo: "Photo",
  postID: "Post ID",
  createTime: "Create time",
  postTitle: "Post title",
  source: "Source",
  sourceID: "Source ID",
  creator: "Creator",
  passed: "Passed",
  pass: "Pass",
  reject: "Reject",
  rejected: "Rejected",
  userDeleted: "User deleted",
  reviewing: "Reviewing",
  nickname: "Nickname",
  allow: "Allow",
  notAllow: "Not allow",
  cover: "Cover",
  tag: "Tag",
  upload: "Upload",
  next: "Next",
  detail: "Detail",
  fans: "Fans",
  userID: "User ID",
  viewLog: "View logs",
  logs: "Logs",
  page: "Page",
  goTo: "Go to",
  valid: "Valid",
  batchValid: "Batch valid",
  invalid: "Invalid",
  batchInvalid: "Batch invalid",
  state: "State",
  uprocessed: "Uprocessed",
  normal: "Normal",
  adminDeleted: "Admin Deleted",
  remark: "Remark",
  logID: "Log ID",
  to: "To",
  beginTime: "Begin time",
  overTime: "Over time",
  keywordSearching: "Keyword searching",
  multipleRowsByEachID: "Multiple rows by each ID",
  recover: "Recover",
  reason: 'Reason',
  operationTip: "Operation tip",
  manage: "Manage",
  date: "Date",
  yes: "Yes",
  no: "No",
  success: "Success",
  failed: "Failed",
  leftMenu: {
    home: "Home",
    allPost: "All Post",
    postReport: "Post Report",
    postReview: "Post Review",
    postAnnotation: "Post Annotation",
    allComment: "All Comment",
    contentManage: "Content Manage",
    welcome: 'Welcome'
  },
  logout: 'Logout',
  copy: 'Copy',
  noMore: 'No more',
  nodata: 'No data',
  pleaseEnter: 'Please enter',
  pleaseSelect: 'Please select',
  location: 'Location',
  fileupload: {
    txtBtn: 'Upload {0}',
    formatType: '{0} format',
    formatPixel: 'Pixel:{0}',
    formatSize: 'Maximum size:{0}',
    typeVideo: 'video',
    typePic: 'picture',
    typeFile: 'file',
    numMaximum: 'Maximum number:{0}'
  }
};