import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var courseManage = [{
  path: '/courseManage/index',
  name: '视频课程配置',
  type: 2,
  meta: {
    title: '视频课程配置',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/courseManage/index.vue");
  }
}, {
  path: '/videoCollection/index',
  name: '视频合集管理',
  type: 2,
  meta: {
    title: '视频合集管理',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/videoCollection/index.vue");
  }
}];
export default courseManage;