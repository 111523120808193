var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      visible: _vm.isShow,
      centered: true,
      width: _vm.modalWidth,
      footer: null,
      destroyOnClose: _vm.destroyOnClose
    },
    on: {
      cancel: _vm.closeModal
    }
  }, [_vm.mediaType == "audio" ? _c("div", [_c("audio", {
    attrs: {
      controls: "controls",
      height: "54",
      width: "300",
      controlslist: "nodownload"
    }
  }, [_c("source", {
    attrs: {
      src: _vm.previewSource,
      type: "audio/mp3"
    }
  }), _c("source", {
    attrs: {
      src: _vm.previewSource,
      type: "audio/ogg"
    }
  }), _c("embed", {
    attrs: {
      height: "54",
      width: "300",
      src: _vm.previewSource
    }
  })])]) : _vm._e(), _vm.mediaType == "video" ? _c("div", [_c("video", {
    attrs: {
      width: "540",
      height: "432",
      controls: "controls",
      controlslist: "nodownload"
    }
  }, [_c("source", {
    attrs: {
      src: _vm.previewSource,
      type: "video/mp4"
    }
  }), _c("source", {
    attrs: {
      src: _vm.previewSource,
      type: "video/ogg"
    }
  }), _c("source", {
    attrs: {
      src: _vm.previewSource,
      type: "video/webm"
    }
  }), _vm._v(" Your browser does not support the video tag. ")])]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };