export default {
  creatorID: "Creator ID",
  reporter: "Reporter",
  reporterID: "Reporter ID",
  reportReason: "Report reason",
  orderID: "Order ID",
  subOrderID: "Sub order ID",
  modifiedtime: "Modified time",
  postState: "Post state",
  reasonTemplate: "Reason template",
  editReasonTemplate: "Edit reason template",
  chooseReasonTemplate: "Choose reason template",
  chooseReportReason: "Choose report reason",
  enterRemark: "Enter remark",
  threateningViolence: 'Threatening Violence',
  confirmDescPrefix: 'Confirm to {state}',
  confirmDescSuffix: 'Selected Order IDs, click "Confirm" to process',
  operateMessage: 'There is no "{status}" status in the options, please check before proceeding!',
  operatTip: 'Please select at least one item to operate!',
  reportDate: 'Report date',
  operationLog: 'Operation log'
};