// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var SeriesApi = {
  //https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000268
  //  列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/brand/serie/page/list', params);
  },
  // 删除
  deleteseries: function deleteseries() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/brand/serie/delete', params);
  },
  //新增
  addbatch: function addbatch() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/brand/serie/add/batch', params);
  },
  //详情
  serieDetail: function serieDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/brand/serie/detail', params);
  },
  // 更新
  serieUpdate: function serieUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/brand/serie/update', params);
  },
  // 获取所有品牌系列
  getbrnadseriesall: function getbrnadseriesall() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/brand/serie/getbrnadseriesall', params);
  }
};
export default SeriesApi;