// DC列表相关接口
import { POST, GET } from '@/utils/util';
var DcListApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000989
  // DC新闻列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    // return POST('/api/communityservice/news/manage/list', params)
    return POST('/api/communityservice/news/list', params);
  },
  // 新闻分类列表
  getcategory: function getcategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/news/category/manage/list', params);
  },
  //集合列表
  getcollections: function getcollections() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/collections', params);
  },
  // 预览
  makeviewurl: function makeviewurl() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/news/manage/makeviewurl', params);
  },
  // 新闻发布恢复/回收
  putmanage: function putmanage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    // return POST('/api/communityservice/news/manage/put', params)
    return POST('/api/communityservice/news/updatenews', params);
  },
  // 新闻详情
  newsmanage: function newsmanage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/news/manage/' + params.id, params);
  },
  // 后台咨询媒体列表 
  newsconfig: function newsconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/newsconfig/list', params);
  },
  // 新闻标签列表 
  newslabel: function newslabel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/newslabel/list', params);
  },
  // 获取新闻详情
  newsDetail: function newsDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/news/findonenews', params);
  },
  // 新建新闻
  addmanage: function addmanage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/news/save', params);
  },
  // 修改新闻
  updatemanage: function updatemanage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/news/update', params);
  },
  // 发布新闻
  release: function release() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/news/updatenews', params);
  },
  // 新闻编辑
  savemanage: function savemanage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/news/manage/save', params);
  },
  // 保存草稿
  savesnapshot: function savesnapshot() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/news/manage/save/snapshot', params);
  },
  // DC-data
  // https://api.test.solestage.com/doc.html#/solestage-datas-api/admin-collection-manage-controller/queryListUsingPOST
  // 列表

  dataFindpage: function dataFindpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dataservice/manage/collection/list', params);
  },
  // 新增合集
  saveCollection: function saveCollection() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dataservice/manage/collection/save', params);
  },
  // 查看
  collectDetail: function collectDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    console.log(params);
    return GET('/api/dataservice/manage/collection/byContractId/' + params.contract_id, params);
  },
  // 修改
  updateCollection: function updateCollection() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dataservice/manage/collection/update', params);
  },
  // 下架
  putCollection: function putCollection() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dataservice/manage/collection/put/' + params.collectionId, params);
  },
  // 获取集合分类
  getcategories: function getcategories() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dataservice/collections/categories', params);
  },
  // 添加集合分类
  addcategories: function addcategories() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dataservice/collections/categories', params);
  },
  // 修改集合分类
  updcategories: function updcategories() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dataservice/collections/categories/' + params.categoryId, params);
  },
  // 删除集合分类z
  delcategories: function delcategories() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dataservice/collections/categories/".concat(params.id, "/del"), params);
  },
  // 查询来源合集
  getsourceList: function getsourceList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dataservice/source/dict/list', params);
  },
  // 获取dclist列表
  getDcList: function getDcList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/item/open/management/list', params);
  },
  // 获取推荐新闻
  getRecommendNews: function getRecommendNews() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/open/news/getsidenews', params);
  },
  // 获取新闻详情
  getNewsDetail: function getNewsDetail(id) {
    return GET('/api/communityservice/open/newsdetail', {
      slug: id
    });
  },
  // 获取集合详情
  getCollectDetail: function getCollectDetail(id) {
    return GET('api/communityservice/news/getcollectiondetail', {
      id: id
    });
  }
};
export default DcListApi;