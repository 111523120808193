// 首页模块
import { POST, GET } from '@/utils/util';
var HomeApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000282
  // 列表
  findhomepage: function findhomepage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/homemodule/page', params);
  },
  // 新增
  save: function save() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/homemodule/save', params);
  },
  // 删除
  homedelete: function homedelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/homemodule/delete', params);
  },
  // 修改排序
  updatesort: function updatesort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/homemodule/updatesort', params);
  },
  // 子项重新排序
  updateitemsort: function updateitemsort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/homemodule/updateitemsort', params);
  },
  // 详情
  homedetail: function homedetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/homemodule/detail', params);
  },
  // 修改
  homeupdate: function homeupdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/homemodule/update', params);
  },
  // 状态个数统计
  tabcount: function tabcount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/homemodule/tabcount', params);
  },
  // 品牌
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/brand/findpage/available', params);
  },
  // 活动列表
  activity: function activity() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/homemodule/find/activity', params);
  }
};
export default HomeApi;