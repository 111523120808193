import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var commodity = [{
  path: '/commodity/index',
  name: '商品管理',
  type: 2,
  meta: {
    title: '商品管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/commoditymanagement");
  }
}, {
  path: '/commodity/addgoods',
  name: '新增商品',
  type: 2,
  meta: {
    title: '新增商品',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/commoditymanagement/addgoods.vue");
  }
}, {
  path: '/commodity/viewgoods',
  name: '查看商品',
  type: 2,
  meta: {
    title: '查看商品',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/commoditymanagement/addgoods.vue");
  }
}, {
  path: '/commodity/editgoods',
  name: '编辑商品',
  type: 2,
  meta: {
    title: '编辑商品',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/commoditymanagement/addgoods.vue");
  }
}, {
  path: '/commodity/copygoods',
  name: '复制商品',
  type: 2,
  meta: {
    title: '复制商品',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/commoditymanagement/addgoods.vue");
  }
}, {
  path: '/commodity/recommendgoods',
  name: '推荐商品',
  type: 3,
  meta: {
    title: '推荐商品',
    breadcrumbHidden: true
    // keepAlive: true  // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/commoditymanagement/recommendgoods.vue");
  }
}, {
  path: '/commodity/addrecommendgoods',
  name: '新增推荐商品',
  type: 3,
  meta: {
    title: '新增推荐商品',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/commoditymanagement/addrecommendgoods.vue");
  }
}, {
  path: '/autoBidding/index',
  name: '自动出价',
  type: 2,
  meta: {
    title: '自动出价',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/autoBidding/index.vue");
  }
}, {
  path: '/autoBidding/detail',
  name: '详情',
  type: 2,
  meta: {
    title: '详情',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/autoBidding/detail.vue");
  }
}];
export default commodity;