// 特征组页面的所有接口
import { POST, GET } from '@/utils/util';
var DcFeaturesApi = {
  // 场景列表
  scenesList: function scenesList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/features/scenes/list', params);
  },
  // 特征组列表
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/features/list', params);
  },
  // 批量停用启用
  updatestatus: function updatestatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/features/update/status', params);
  },
  // 新增
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/features', params);
  },
  // 编辑
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/features/update', params);
  },
  // 二级列表
  sublist: function sublist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/features/ids', params);
  },
  // 添加关联内容
  subAdd: function subAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/features/ids/create', params);
  },
  subUpdate: function subUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/features/ids/update', params);
  },
  // 批量恢复/删除
  subUpdateStatus: function subUpdateStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/features/ids/update/status', params);
  },
  // 日志
  subLogs: function subLogs() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/features/ids/logs', params);
  },
  // 内容ID类型列表
  typelist: function typelist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/features/contentid/type/list', params);
  }
};
export default DcFeaturesApi;