import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcBricks = [{
  path: '/bricksettle/index',
  name: 'bricksettle',
  type: 2,
  meta: {
    title: '砖块管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/bricksettle/");
  }
}, {
  path: '/bricksocialmedia/index',
  name: 'bricksocialmedia',
  type: 2,
  meta: {
    title: 'Brick社媒任务',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/bricksocialmedia/");
  }
}, {
  path: '/avatarset/index',
  name: 'avatarset',
  type: 2,
  meta: {
    title: 'Avatar装备价格设置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/avatarset/");
  }
}, {
  path: '/dcChannelaudit/index',
  name: 'dcChannelaudit',
  type: 2,
  meta: {
    title: 'DC-DiscordChannel审核',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcChannelaudit/");
  }
}, {
  path: '/usdtflow',
  name: 'usdtflow',
  type: 2,
  meta: {
    title: '资金管理-USDT',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/usdtflow/");
  }
}, {
  path: '/cbkflow',
  name: 'cbkflow',
  type: 2,
  meta: {
    title: '资金管理-CBK',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/usdtflow/");
  }
}];
export default dcBricks;