// 话题
import { POST, GET } from "@/utils/util";
var TopicApi = {
  // 话题列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/usertopics/findpage", params);
  },
  //  话题关联视频列表
  findpage2: function findpage2() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/videotopics/findpage", params);
  },
  // 日志列表
  findpage3: function findpage3() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/logs/findpage", params);
  },
  save: function save() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/usertopics/save", params);
  },
  getdetaile: function getdetaile() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/usertopics/getdetaile", params);
  },
  updatestatus: function updatestatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/usertopics/updatestatus", params);
  },
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/usertopics/update", params);
  },
  queryname: function queryname() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/usertopics/queryname", params);
  },
  getmaxrecommend: function getmaxrecommend() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/usertopics/getmaxrecommend", params);
  }
};
export default TopicApi;