// 首页模块配置相关
import { POST, GET } from '@/utils/util';
var DcVideoApi = {
  // http://13.214.186.200:8888/docs/api-docs/-/blob/lzm/DC%E7%A4%BE%E5%8C%BA%E5%92%8C%E6%96%B0%E9%97%BB/%E7%A4%BE%E5%8C%BAAPP%E6%8E%A5%E5%8F%A3/%E7%AE%A1%E7%90%86%E5%90%8E%E5%8F%B0
  // DC视频管理相关接口
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videos/save', params);
  },
  batchAdd: function batchAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return POST('/api/communityservice/videos/save/batch', params);
  },
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videos/update', params);
  },
  findone: function findone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/videos/findone', params);
  },
  // 隐藏/显示/删除
  updatestatus: function updatestatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videos/updatestatus', params);
  },
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videos/list', params);
  },
  // 获取nft列表
  getNftList: function getNftList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/item/open/list', params);
  },
  // 获取dc合集列表
  getCollectList: function getCollectList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/collection/open/list', params);
  },
  // 视频列表/视频审核列表/视频标注列表
  getreviewed: function getreviewed() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videos/getreviewed', params);
  },
  // 视频日志列表
  getLogs: function getLogs() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videolog/findpage', params);
  },
  // 视频详情v2
  getdetails: function getdetails() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/videos/getdetails', params);
  },
  // 批量标注
  batchmark: function batchmark() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videos/batchmark', params);
  },
  // 批量审批：通过/不通过
  batchcheck: function batchcheck() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videos/batchcheck', params);
  },
  // 待驳回原因列表
  rejectreason: function rejectreason() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/videos/rejectreason', params);
  },
  // 标签列表V2
  lablesV2: function lablesV2() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/v2/label/list', params);
  },
  // 获取视频标注类型
  getVideoMarkLabel: function getVideoMarkLabel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/communityservice/videos/getvideoannotationtype', params);
  }
};
export default DcVideoApi;