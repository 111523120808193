import "core-js/modules/es.number.constructor.js";
export default {
  name: "SelectPage",
  props: {
    // 下拉框总数据
    list: {
      type: Array,
      require: true
    },
    size: {
      type: String,
      default: "large"
    },
    total: {
      type: Number,
      require: true
    },
    page: {
      type: Number,
      require: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    // 提示
    placeholder: {
      type: String,
      default: "请选择"
    },
    // 下拉框显示值
    labelKey: {
      type: String,
      default: 'name'
    },
    // 下拉框value
    valueKey: {
      type: String,
      default: 'id'
    },
    // 默认值
    defaultValue: {
      type: [String, Number, Object]
    },
    loading: {
      type: Boolean,
      default: false
    },
    searchMode: {
      // 搜索模式
      type: String,
      default: "local" // local || remote
    }
  },

  components: {
    VNodes: {
      functional: true,
      render: function render(h, ctx) {
        return ctx.props.vnodes;
      }
    }
  },
  data: function data() {
    return {
      value: this.defaultValue
    };
  },
  watch: {
    defaultValue: function defaultValue(newVal, oldVal) {
      this.value = newVal;
    }
  },
  computed: {
    selectList: function selectList() {
      return this.list;
    },
    maxPage: function maxPage() {
      return Math.ceil(this.total / this.pageSize);
    },
    nowPage: function nowPage() {
      return this.page;
    }
  },
  methods: {
    // 清空数据
    clearValue: function clearValue() {
      this.value = "";
    },
    // 换页事件，0切换到上一页，1切换到下一页
    changePage: function changePage(status) {
      if (this.nowPage == 1 && status == 0) return;
      if (this.nowPage == this.maxPage && status == 1) return;
      var page = status == 0 ? this.nowPage - 1 : this.nowPage + 1;
      this.$emit("changePage", page);
    },
    // 选择下拉框时进行子传父
    chooseOption: function chooseOption(value) {
      this.$emit("change", value);
      this.$emit("input", value);
    },
    // 下拉框筛选
    filterOption: function filterOption(input, option) {
      if (this.searchMode == 'remote') return true;
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  }
};