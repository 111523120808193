import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcmedia = [{
  path: '/dcmedia/index',
  name: 'dcmedia',
  type: 2,
  meta: {
    title: '资讯媒体管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcmedia/index.vue");
  }
}];
export default dcmedia;