import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var liveStreaming = [
// 直播
{
  path: '/live-streaming',
  name: '直播管理',
  type: 2,
  meta: {
    title: '直播管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/live-streaming");
  }
}, {
  path: '/live-streaming/gift',
  name: '礼物配置',
  type: 2,
  meta: {
    title: '礼物配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/live-streaming/gift");
  }
}, {
  path: '/live-streaming/gift/detail',
  name: '礼物明细表',
  type: 2,
  meta: {
    title: '礼物明细表',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/live-streaming/gift/detail");
  }
}];
export default liveStreaming;