import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// 账号管理页面的所有接口
import { POST, GET, MD5 } from '@/utils/util';
var Api = {
  // 运营账户登录
  login: function login() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _ref = params || {},
      password = _ref.password;
    return POST('/api/accountservice/adminuser/open/login', _objectSpread(_objectSpread({}, params), {}, {
      password: MD5(password)
    }));
  },
  signout: function signout() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/adminuser/signout', params);
  },
  getMenuListById: function getMenuListById() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/adminuser/menu', params);
    // return GET('/api/accountservice/adminuser/getsysmenu', params)
  }
};

export default Api;