export default {
  creatorID: "发布人ID",
  reporter: "举报人",
  reporterID: "举报人ID",
  reportReason: "举报理由",
  modifiedtime: "更新时间",
  orderID: "工单ID",
  subOrderID: "子工单ID",
  postState: "视频状态",
  reasonTemplate: "理由模板",
  editReasonTemplate: "编辑理由模板",
  chooseReasonTemplate: "选择理由模板",
  chooseReportReason: "请选择举报理由",
  enterRemark: "输入备注",
  threateningViolence: '视频存在敏感词',
  confirmDescPrefix: '确认{state}处理',
  confirmDescSuffix: '所选数据，点击确认后将执行',
  operateMessage: '选项中没有“{state}”状态，请检查后再操作！',
  operatTip: '请至少选中一项进行操作！',
  reportDate: '举报时间',
  operationLog: '操作日志'
};