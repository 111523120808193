import "core-js/modules/es.number.constructor.js";
// body Overflow原始样式
var originBodyOverflow = '';
export default {
  name: 'KwDialog',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    lockScroll: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    modal: {
      type: Boolean,
      default: true
    },
    center: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 3000
    },
    top: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    beforeClose: Function,
    showClose: {
      type: Boolean,
      default: true
    },
    showFullscreen: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  watch: {
    visible: {
      immediate: true,
      handler: function handler(val) {
        this.showModal = val;
        if (process.client && val && this.lockScroll) {
          var style = document.body.style;
          var overflow = document.body.style.overflow;
          originBodyOverflow = overflow;
          style.overflow = 'hidden';
        }
      }
    }
  },
  data: function data() {
    return {
      isFullscreen: false,
      showModal: false
    };
  },
  methods: {
    handleConfirm: function handleConfirm() {
      this.$emit('confirm');
      setTimeout(this.clickClose);
    },
    handleCancel: function handleCancel() {
      this.$emit('cancel');
      setTimeout(this.clickClose);
    },
    clickModal: function clickModal() {
      if (!this.closeOnClickModal) return;
      this.clickClose();
    },
    handleClose: function handleClose() {
      this.isFullscreen = false;
      this.showModal = false;
      this.$emit('change', false);
    },
    clickClose: function clickClose() {
      this.lockScroll && (document.body.style.overflow = originBodyOverflow);
      if (typeof this.beforeClose === 'function') {
        this.beforeClose(this.handleClose);
      } else {
        this.handleClose();
      }
    },
    // 全屏开关
    toggleFullscreen: function toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.isFullscreen = true;
        this.$refs['kwdialog'].requestFullscreen();
      } else {
        this.isFullscreen = false;
        document.exitFullscreen();
      }
    },
    destroyed: function destroyed() {
      // if appendToBody is true, remove DOM node after destroy
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  }
};