import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcTool = [{
  path: '/dctool/index',
  name: '工具列表',
  type: 2,
  meta: {
    title: '工具列表',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dctoolManage/index.vue");
  }
}, {
  path: '/dctool/add',
  name: '添加工具',
  type: 3,
  meta: {
    title: '新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dctoolManage/add.vue");
  }
}, {
  path: '/dctool/edit',
  name: '编辑工具',
  type: 3,
  meta: {
    title: '编辑',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dctoolManage/add.vue");
  }
}, {
  path: '/dctool/view',
  name: '查看工具',
  type: 3,
  meta: {
    title: '查看',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dctoolManage/add.vue");
  }
}, {
  path: '/dctool/category',
  name: '工具分类管理',
  type: 2,
  meta: {
    title: '工具分类管理',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dctoolManage/category.vue");
  }
}];
export default dcTool;