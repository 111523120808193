// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var MerchantsLevelApi = {
  // 商家等级-列表
  getList: function getList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/business/level/list', params);
  },
  // 商家等级-新增
  addMerchantsLevel: function addMerchantsLevel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/phpservice/admin/business/level/add', params);
  },
  // 商家等级-编辑
  editMerchantsLevel: function editMerchantsLevel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/phpservice/admin/business/level/edit', params);
  },
  // 商家等级-删除
  delMerchantsLevel: function delMerchantsLevel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/business/level/delete', params);
  }
};
export default MerchantsLevelApi;