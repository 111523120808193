import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import Vue from 'vue';
import Router from 'vue-router';
import Routes from '@/routes';
import store from "@/store";
import { ROUTER_DEFAULT_CONFIG } from '@/config';
import { allUserRutes } from '@/routes/componentConf';
import { filterSeaRoutePath } from '@/utils/help';
Vue.use(Router);
var originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
var createRouter = function createRouter() {
  return new Router(_objectSpread(_objectSpread({}, ROUTER_DEFAULT_CONFIG), {}, {
    routes: Routes,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    mode: 'history'
  }));
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

// 免token可进入的页面
var whiteList = ["/", "/login", '/404'];
router.beforeEach(function (to, from, next) {
  var _to$query;
  var lang = (_to$query = to.query) === null || _to$query === void 0 ? void 0 : _to$query.lang;
  if (lang) localStorage.setItem('lang', lang);
  if (whiteList.indexOf(to.path) !== -1) {
    next();
    return;
  }
  // console.log('to.fullPath1', to.fullPath)
  store.commit("setRouteTitle", to.name);
  store.commit("setRoutePath", to.fullPath.split('?')[0]);
  // store.getters["base/getUserInfo"]
  var isOversea = store.getters["lang/isOversea"];
  if ((store.getters["base/getUserInfo"] || {}).token) {
    // 每次路由变更都去请求授权关闭
    if ((store.state.base.pagePermissions || []).length) {
      next();
      return;
    }
    store.dispatch("base/getMenuList", {
      paths: filterSeaRoutePath(allUserRutes),
      isOversea: isOversea
    }).then(function (res) {
      var userPath = store.getters["pagePermissions"];
      // 新增欢迎页
      userPath.push('/welcome/index');
      setTimeout(function () {
        var isPush = userPath.some(function (it) {
          // return to.fullPath.indexOf(it) != -1
          return to.fullPath.split('?')[0] == it;
        });
        if (isPush) {
          next();
        } else {
          next('/404');
          return;
        }
      });
    });
  } else {
    // localStorage 信息被清除
    store.commit("clearState");
    if (whiteList.indexOf(to.fullPath) !== -1) {
      // 免token可进入的页面
      next();
    } else {
      // 跳转至登陆页面
      next("/login");
    }
  }
});
export default router;