import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var community = [{
  path: "/community/index",
  name: "社区内容管理",
  type: 2,
  meta: {
    title: "社区内容管理",
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/community");
  }
}];
export default community;