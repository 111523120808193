import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "myFileList"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.confirmLoading
    }
  }, [_c("a-upload", {
    staticClass: "upload-list-inline",
    attrs: {
      listType: "picture",
      fileList: [],
      customRequest: _vm.uploadImage,
      beforeUpload: _vm.beforeUpload,
      multiple: true
    }
  }, [_c("a-button", {
    staticStyle: {
      width: "100px",
      height: "100px"
    },
    attrs: {
      disabled: _vm.disabled
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "24px"
    },
    attrs: {
      type: "plus"
    }
  }), _c("div", [_vm._v("上传图片")])], 1)], 1), _c("div", {
    staticClass: "ant-upload-list ant-upload-list-picture"
  }, [_c("draggable", {
    staticClass: "syllable_ul",
    attrs: {
      element: "ul",
      list: _vm.fileList,
      options: {
        group: "title",
        animation: 150
      },
      "no-transition-on-drag": true
    },
    on: {
      change: _vm.change
    }
  }, [_c("transition-group", {
    staticStyle: {
      display: "flex",
      "flex-wrap": "wrap"
    },
    attrs: {
      type: "transition",
      name: !_vm.drag ? "syll_li" : null,
      css: true
    }
  }, _vm._l(_vm.fileList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "ant-upload-list-item ant-upload-list-item-done",
      staticStyle: {
        "margin-right": "10px",
        width: "100px",
        height: "100px"
      }
    }, [_c("div", {
      staticClass: "ant-upload-list-item-info"
    }, [_c("span", {
      on: {
        click: function click($event) {
          return _vm.previewImg(item);
        }
      }
    }, [_c("a", {
      staticClass: "ant-upload-list-item-thumbnail",
      attrs: {
        target: "_blank",
        rel: "noopener noreferrer"
      }
    }, [_c("img", {
      staticStyle: {
        width: "80px",
        height: "80px"
      },
      attrs: {
        src: item.url,
        alt: item.name
      }
    })]), _c("a", {
      staticClass: "ant-upload-list-item-name",
      attrs: {
        target: "_blank",
        rel: "noopener noreferrer",
        title: "4.jpg",
        href: item.url
      }
    })])]), _vm.showTips ? _c("i", {
      staticClass: "anticon anticon-close",
      attrs: {
        "aria-label": "图标: close",
        tabindex: "-1"
      },
      on: {
        click: function click($event) {
          return _vm.removeCoupon(item.uid);
        }
      }
    }, [_c("svg", {
      attrs: {
        viewBox: "64 64 896 896",
        "data-icon": "close",
        width: "1em",
        height: "1em",
        fill: "currentColor"
      }
    }, [_c("path", {
      attrs: {
        d: "M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
      }
    })])]) : _vm._e()]);
  }), 0)], 1)], 1), _vm.showTips ? _c("div", {
    staticClass: "img_rules"
  }, [_vm.memorySize ? _c("div", {
    staticClass: "suport_text"
  }, [_vm._v(" 大小: " + _vm._s(_vm.memorySize))]) : _vm._e(), _vm.imgProportion.length ? _c("div", {
    staticClass: "suport_text"
  }, [_c("span", [_vm._v("尺寸: " + _vm._s(_vm.imgProportion[0] + "*" + _vm.imgProportion[1]) + " ")])]) : _vm._e()]) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };