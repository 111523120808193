// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var CommodityApi = {
  // 添加商品信息
  addproduct: function addproduct() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/product/save', params);
  },
  // 修改商品信息
  updateproduct: function updateproduct() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/product/update', params);
  },
  // 系统商品列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/product/findpage', params);
  },
  // 商品详情
  findpagedetail: function findpagedetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/product/findone', params);
  },
  //   商品上下架
  updatestatus: function updatestatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/product/updatestatus', params);
  },
  //   删除商品
  deletegoods: function deletegoods() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/product/delete', params);
  },
  // 获取类目列表
  getlist: function getlist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syscategory/getlist', params);
  },
  //推荐商品列表
  recommendproduct: function recommendproduct() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/recommendproduct/findpage', params);
  },
  // 添加推荐商品
  addrecommendproduct: function addrecommendproduct() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/recommendproduct/save', params);
  },
  // 删除推荐商品
  deleterecommendproduct: function deleterecommendproduct() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/recommendproduct/delete', params);
  },
  // 添加组合商品
  addcombinationproduct: function addcombinationproduct() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/combinationproduct/save', params);
  },
  //组合商品列表
  combinationproductfindpage: function combinationproductfindpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/combinationproduct/findpage', params);
  },
  // 组合商品详情
  combinationproductdetail: function combinationproductdetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/combinationproduct/findone', params);
  },
  // 更新组合商品信息
  updatecombinationproduct: function updatecombinationproduct() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/combinationproduct/update', params);
  },
  // 删除组合
  deletecombinationproduct: function deletecombinationproduct() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/combinationproduct/delete', params);
  },
  // 查询商品
  searchgoods: function searchgoods() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/product/getproductbyskus', params);
  },
  // 获取尺码
  getsysszie: function getsysszie() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syssize/getsysszie', params);
  },
  // 获取物流公司
  getexpresstemplate: function getexpresstemplate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/orderservice/expresstemplate/v3/find', params);
  },
  // 验证货号是否存在
  getproductbyskucount: function getproductbyskucount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/product/getproductbyskucount', params);
  },
  // 商品列表tab数量
  gettabcount: function gettabcount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/product/gettabcount', params);
  },
  // 商品批量上架
  bulkshelves: function bulkshelves() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/productservice/product/bulkshelves?ids=".concat(params.ids));
  },
  // 商品批量下架
  bulkremoval: function bulkremoval() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/productservice/product/bulkremoval?ids=".concat(params.ids));
  }
};
export default CommodityApi;