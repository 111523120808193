var state = {
  wordrow: []
};

// 全局计算属性
var getters = {};
var mutations = {
  Updatewordsrow: function Updatewordsrow(state, payload) {
    state.wordrow = payload || state.wordrow;
  }
};

// 异步的action,请求工具限制请求数据不能使用vuex。
var actions = {};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};