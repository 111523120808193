import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _typeof from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/typeof.js";
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.includes.js";
import { getCurrentDate } from "./dateutil";
export function downloadExcel(content) {
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ".xls";
  if (content && content instanceof Blob) {
    var blob = new Blob([content]);
    var fileName = getCurrentDate() + name;
    if ("download" in document.createElement("a")) {
      // 非IE下载
      var elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    } else {
      // IE10+下载
      navigator.msSaveBlob(blob, fileName);
    }
  }
}
export function sortByKey() {
  var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return function (value1, value2) {
    var val1 = value1[key];
    var val2 = value2[key];
    return val1 - val2;
  };
}
export function objToArray() {
  var object = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var array = [];
  if (object) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        var element = object[key];
        array.push({
          id: parseInt(key).toString(),
          name: element
        });
      }
    }
  }
  return array;
}

// base64转码
export function getBase64(img, callback) {
  var reader = new FileReader();
  reader.addEventListener("load", function () {
    return callback(reader.result.split(",")[1]);
  });
  reader.readAsDataURL(img);
}

// 打开外部链接
export function openOutLink(link) {
  if (link) {
    var win = window.open("", "_blank");
    if (win) {
      win.opener = null;
      var doc = win.document;
      doc.open();
      doc.write('<meta http-equiv="refresh" content="0; url=' + link + '">');
      doc.close();
    }
  }
}
export function filterInvalidObj() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var temp = _objectSpread({}, obj);
  Object.keys(temp).forEach(function (key) {
    if (typeof temp[key] === "string") temp[key] = temp[key].trim();
  });
  for (var item in temp) {
    if (temp[item] === "" || temp[item] === undefined || temp[item] === null) {
      delete temp[item];
    }
  }
  return temp;
}
export function numFormat(num) {
  num = num.toString().split("."); // 分隔小数点
  var arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
  var res = [];
  for (var i = 0, len = arr.length; i < len; i++) {
    if (i % 3 === 0 && i !== 0) {
      res.push(","); // 添加分隔符
    }

    res.push(arr[i]);
  }
  res.reverse(); // 再次倒序成为正确的顺序
  if (num[1]) {
    // 如果有小数的话添加小数部分
    res = res.join("").concat("." + num[1]);
  } else {
    res = res.join("");
  }
  return res;
}

// 深拷贝实现
export function deepClone(obj) {
  // 写法1
  var newObj = obj.constructor === Array ? [] : {};
  if (_typeof(obj) !== "object") {
    return obj;
  } else {
    for (var i in obj) {
      if (_typeof(obj[i]) === "object") {
        newObj[i] = deepClone(obj[i]);
      } else {
        newObj[i] = obj[i];
      }
    }
  }
  return newObj;

  // 写法2
  // if (!obj && typeof obj !== "object") {
  //     throw new Error("error arguments", "deepClone");
  // }
  // const targetObj = obj.constructor === Array ? [] : {};
  //     Object.keys(obj).forEach(keys => {
  // if (obj[keys] && typeof obj[keys] === "object") {
  //     targetObj[keys] = deepClone(obj[keys]);
  // } else {
  //     targetObj[keys] = obj[keys];
  // }
  // });
  // return targetObj;
}

// Object对象按照key排序
export function objKeySort(arys) {
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获 取的属性名进行排序，newkey是一个数组
  var newkey = Object.keys(arys).sort();
  //console.log(‘newkey=’+newkey);
  var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
  for (var i = 0; i < newkey.length; i++) {
    //遍历newkey数组
    newObj[newkey[i]] = arys[newkey[i]];
    //向新创建的对象中按照排好的顺序依次增加键值对
  }

  return newObj;
}

/**
 * 字节转换下单位
 * @param bytes 字节数
 * @returns
 */
export function ByteConvert(bytes) {
  if (isNaN(bytes)) {
    return "";
  }
  var symbols = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var exp = Math.floor(Math.log(bytes) / Math.log(2));
  if (exp < 1) {
    exp = 0;
  }
  var i = Math.floor(exp / 10);
  bytes = bytes / Math.pow(2, 10 * i);
  if (bytes.toString().length > bytes.toFixed(2).toString().length) {
    bytes = bytes.toFixed(2);
  }
  return bytes + "" + symbols[i];
}

// 是否为字幕
export function isSubtitle(filePath) {
  // 字幕后缀
  var extention = ["vtt"];
  // 文件后缀
  var type = fileExtension(filePath);
  // 是否包含
  return extention.indexOf(type) !== -1;
}
// 是否为图片
export function isImage(filePath) {
  // 图片后缀
  var types = ["png", "jpg", "jpeg", "gif", "svg"]; // 'tiff','webp','bmp','psd',
  // 文件后缀
  var type = fileExtension(filePath);
  // 是否包含
  return types.indexOf(type) !== -1;
}
// 是否为音频
export function isAudio(filePath) {
  // 后缀
  var types = ["mp3", "wav", "ogg"];
  // 文件后缀
  var type = fileExtension(filePath);
  // 是否包含
  return types.indexOf(type) !== -1;
}
// 是否为视频
export function isVideo(filePath) {
  // 后缀
  var types = ["avi", "wmv", "mpg", "mpeg", "mov", "rm", "ram", "swf", "flv", "mp4", "wma", "avi", "rm", "rmvb", "flv", "mpg", "mkv"]; //['avi', 'wmv', 'mpg', 'mpeg', 'mov', 'rm', 'ram', 'swf', 'flv', 'mp4', 'wma', 'avi', 'rm', 'rmvb', 'flv', 'mpg', 'mkv']
  // 文件后缀
  var type = fileExtension(filePath);
  // 是否包含
  return types.indexOf(type) !== -1;
}
// 获取文件后缀类型
export function fileExtension(filePath) {
  // 获取最后一个.的位置
  var index = filePath.lastIndexOf(".");
  // 获取后缀
  var type = filePath.substr(index + 1);
  // 返回类型
  return type.toLowerCase();
}

// 获取文件名（不含后缀）
export function filenameWithoutExtenstion(filePath) {
  if (!filePath) return "";
  // 获取最后一个.的位置
  var index = filePath.lastIndexOf(".");
  return filePath.substr(0, index);
}

// 分类树扁平化
export function flattenTreeData(tree) {
  return tree.reduce(function (flatten, curr) {
    flatten.push(curr);
    if (Array.isArray(curr.children) && curr.children.length) {
      flatten.push.apply(flatten, _toConsumableArray(flattenTreeData(curr.children)));
    }
    return flatten;
  }, []);
}
// 一行一个的数据处理成数组
export function formatTextareaToArray(text) {
  if (text) {
    var lines = text.split(/[(\r\n)\r\n]+/);
    lines = lines.map(function (item) {
      return item.trim();
    });
    lines = lines.filter(function (item) {
      return item.trim() ? true : false;
    });
    if (lines.length > 0) return lines;
  }
  return undefined;
}

// 批量导入
export function importAll(r, ext) {
  var __modules = {};
  var path = require('path');
  r.keys().forEach(function (key) {
    var m = r(key).default;
    var n = path.basename(key, ext);
    __modules[n] = m;
  });
  return __modules;
}
// 过滤海外需要的路由path
export function filterSeaRoutePath(routes) {
  var data = [];
  routes.forEach(function (it) {
    if (it.issea !== true) {
      data.push(it.path);
    }
  });
  return data;
}
export function isOversea() {
  var host = location.host;

  // 1.先进行域名判断
  // manage.know3here.co
  // return ['cotools.hashmade.io', 'cstool.test2.knowherex.com'].includes(host);
  return true;
}
export function getLang() {
  if (isOversea()) return 'en';
  var searchStr = location.search || '';
  var lang = '';
  if (searchStr) {
    lang = new URLSearchParams(searchStr).get('lang');
  } else {
    lang = localStorage.getItem('lang');
  }
  if (['zh', 'en'].includes(lang)) return lang;
  return 'zh';
}