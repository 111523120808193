import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import i18n from "@/lang";
var state = {
  // 来源列表
  sourceList: [{
    id: 0,
    name: '全部'
  }, {
    id: 1,
    name: '用户发布'
  }, {
    id: 2,
    name: 'Tiktok'
  }, {
    id: 3,
    name: 'Youtube'
  }],
  // 状态列表
  statusList: [{
    id: 0,
    name: i18n.tc('dcReview.statusList.state0')
  }, {
    id: 1,
    name: i18n.tc('dcReview.statusList.state1')
  }, {
    id: 2,
    name: i18n.tc('dcReview.statusList.state2')
  }, {
    id: 3,
    name: i18n.tc('dcReview.statusList.state3')
  }],
  // 作品类型列表
  typeList: [{
    id: 1,
    name: i18n.tc('dcReview.typeList.type1')
  }, {
    id: 2,
    name: i18n.tc('dcReview.typeList.type2')
  }]
};

// 全局计算属性
var getters = {
  dcTypeList: function dcTypeList(state) {
    return [{
      id: '',
      name: i18n.tc('common.all')
    }].concat(_toConsumableArray(state.typeList));
  },
  dcVideoStatusList: function dcVideoStatusList(state) {
    return [{
      id: '',
      name: i18n.tc('common.all')
    }].concat(_toConsumableArray(state.statusList));
  },
  dcReviewStatusList: function dcReviewStatusList(state) {
    return [state.statusList.find(function (it) {
      return it.id === 0;
    })];
  },
  statusListObj: function statusListObj(state) {
    var data = {};
    state.statusList.forEach(function (it) {
      data[it.id] = it.name;
    });
    return data;
  },
  typeListObj: function typeListObj(state) {
    var data = {};
    state.typeList.forEach(function (it) {
      data[it.id] = it.name;
    });
    return data;
  }
};
var mutations = {};

// 异步的action,请求工具限制请求数据不能使用vuex。
var actions = {};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};