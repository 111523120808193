var state = {
  typeList: [{
    id: 1,
    name: "H5"
  }, {
    id: 2,
    name: "一级类目"
  }, {
    id: 3,
    name: "二级类目"
  }, {
    id: 4,
    name: "品牌"
  }, {
    id: 5,
    name: "搜索词"
  }, {
    id: 6,
    name: "商品详情"
  }, {
    id: 7,
    name: "限量发售"
  }],
  splashTypeList: [
  // 闪屏配置的类型列表
  {
    id: 1,
    name: "H5"
  }, {
    id: 2,
    name: "视频ID"
  }, {
    id: 3,
    name: "视频合集"
  }, {
    id: 4,
    name: "话题"
  }, {
    id: 5,
    name: "群ID"
  }, {
    id: 6,
    name: "用户ID"
  }]
};

// 全局计算属性
var getters = {};
var mutations = {};

// 异步的action,请求工具限制请求数据不能使用vuex。
var actions = {};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};