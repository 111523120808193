import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcVideo = [{
  path: '/dcVideo/index',
  name: 'dcVideo',
  type: 2,
  meta: {
    title: 'DC-视频管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dcReview/");
  }
}];
export default dcVideo;