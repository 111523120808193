import { POST, GET } from '@/utils/util';
var aiVideoApi = {
  // ai视频列表
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ai/video/list', params);
  },
  // 读取配置
  getconfigbyname: function getconfigbyname() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/accountservice/config/getconfigbyname', params);
  },
  // 配置更新
  updatebyname: function updatebyname() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/accountservice/config/updatebyname', params);
  }
};
export default aiVideoApi;