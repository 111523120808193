// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var IMApi = {
  // 群列表
  channellist: function channellist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/imchannel/channellist', params);
  },
  // 群编辑
  channeledit: function channeledit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/imchannel/channeledit', params);
  },
  // 群详情
  channeldetail: function channeldetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/imchannel/channeldetail', params);
  },
  // 群加入机器人
  addChannelRotbot: function addChannelRotbot() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/imchannelainum/save', params);
  },
  //  群标识list-全部返回
  iconlist: function iconlist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/icon/list', params);
  },
  // 群标识page-分页返回
  iconlistpage: function iconlistpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/icon/page', params);
  },
  // 群标识删除
  icondelete: function icondelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/icon/delete', params);
  },
  // 群标识 增加
  iconadd: function iconadd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/icon/add', params);
  },
  // 群标识 更新
  iconupdate: function iconupdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/icon/update', params);
  },
  // 群分类分页列表
  getCategory: function getCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/category/page', params);
  },
  // 所有群分类数据
  getallgroups: function getallgroups() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/category/getallgroups', params);
  },
  // 群分类删除
  delCategory: function delCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/category/delete', params);
  },
  // 群分类增加
  addCategory: function addCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/category/add', params);
  },
  // 群聊列表，关键词搜索
  getGroups: function getGroups() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/category/getGroups', params);
  },
  // 群分类排序
  sortCategory: function sortCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/category/sortcategory', params);
  },
  // 群分类更新
  updateCategory: function updateCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/category/updatecategory', params);
  },
  // 群分类详情
  detailCategory: function detailCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/category/detail', params);
  },
  // aibot列表
  aiBotList: function aiBotList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/ai-bot/searchList', params);
  },
  // aibot问答创建、更新
  addAiBot: function addAiBot() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/ai-bot/add', params);
  },
  // aibot删除问答
  aiBotItemDel: function aiBotItemDel() {
    var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return POST('/api/accountservice/ai-bot/del/' + id);
  },
  // aibot问答详情
  aiBotItemDetail: function aiBotItemDetail() {
    var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return GET('/api/accountservice/ai-bot/searchDetail/' + id);
  },
  /* 举报管理-start */
  // 举报类型
  reportTypes: function reportTypes() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/report/reporttype/list', params, '', {
      Language: 'zh'
    });
  },
  // 数量统计
  reportGroupSum: function reportGroupSum() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/report/groupsum', params);
  },
  // 用户举报列表
  reportUserList: function reportUserList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/report/backenduser/list', params);
  },
  //  用户举报列表
  reportChannelList: function reportChannelList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/report/backendgroup/list', params);
  },
  // 审核
  reportAudit: function reportAudit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/report/backend/audit', params);
  },
  // 详情
  reportDetail: function reportDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/report/backend/detail', params);
  },
  /* 举报管理-end */
  /* 反馈-start */
  // 反馈列表
  feedBackList: function feedBackList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/feedback/backend/list', params);
  },
  // 反馈回复
  feedBackReply: function feedBackReply() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/feedback/reply', params);
  },
  // 查询反馈类型
  feedBackType: function feedBackType() {
    return GET('/api/accountservice/feedback/findtype');
  },
  // 删除回复
  feedBackDelete: function feedBackDelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/feedback/backend/delete', params);
  },
  // 反馈详情
  feedBackDetails: function feedBackDetails() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/feedback/backend/details', params);
  }
  /* 反馈-end */
};

export default IMApi;