// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var bricksconfigApi = {
  /**************社媒任务********************/
  // 分页查询社媒任务列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/bricksconfig/findpage', params);
  },
  // 添加后台社媒任务
  addtask: function addtask() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/bricksconfig/save', params);
  },
  getmediadetails: function getmediadetails() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/bricksconfig/getmediadetails', params);
  },
  updatetask: function updatetask() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/bricksconfig/update', params);
  },
  deltask: function deltask() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/bricksconfig/delete', params);
  },
  /**************砖块结算********************/
  sellterfindpage: function sellterfindpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/brickservice/brick/flow/list', params);
  },
  sellterfindpage2: function sellterfindpage2() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/timelimitbricks/list/v2', params);
  },
  /*****************Avatar***********************/
  getAvatarTab: function getAvatarTab() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/avatarsourcematerial/gettabcount', params);
  },
  avatarfindpage: function avatarfindpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/avatarsourcematerial/findpage', params);
  },
  getavtarDetail: function getavtarDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/avatarsourcematerial/getdetails', params);
  },
  updateAvtar: function updateAvtar() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/avatarsourcematerial/update', params);
  },
  /******************dcChannel审核**************************/
  // 列表
  channelfindpage: function channelfindpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/socialmedia/list', params);
  },
  // 审核
  channelcheck: function channelcheck() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/socialmedia/check', params);
  },
  // 详情
  channeldetail: function channeldetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/socialmedia/getone', params);
  },
  // 申请
  channelsave: function channelsave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/socialmedia/save', params);
  },
  // 修正砖块数目
  updateBricksConut: function updateBricksConut() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/timelimitbricks/correct', params);
  },
  // 获取CBK-GBK任务类型
  getBrickType: function getBrickType() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/timelimitbricks/getbricktype', params);
  },
  // 砖块total
  getTimelimitbricksTotal: function getTimelimitbricksTotal() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/brickservice/brick/flow/total', params);
  },
  // 流水类型列表
  getFlowTypes: function getFlowTypes() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/brickservice/brick/flow/type', params);
  }
};
export default bricksconfigApi;