var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-config-provider", {
    attrs: {
      locale: _vm.locale
    }
  }, [_c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("keep-alive", [_vm.$route.meta.keepAlive ? _c("router-view", {
    key: _vm.key
  }) : _vm._e()], 1), !_vm.$route.meta.keepAlive ? _c("router-view", {
    key: _vm.key
  }) : _vm._e()], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };