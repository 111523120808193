// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var ConfigurationApi = {
  // 获取提现规则
  getwithdrawalconfig: function getwithdrawalconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/config/getwithdrawalconfig', params);
  },
  // 获取买家规则
  getbuyerserviceconfig: function getbuyerserviceconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/config/getbuyerserviceconfig', params);
  },
  // 获取卖家规则
  getsellerservicechargeconfig: function getsellerservicechargeconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/config/getsellerservicechargeconfig', params);
  },
  // 修改协议配置
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/config/update', params);
  },
  //获取卖家规则类目服务费
  getsellerservicecharglist: function getsellerservicecharglist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/config/getsellerservicecharglist', params);
  },
  //新增或修改类目服务费
  updateoraddsellerlist: function updateoraddsellerlist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/config/updateoraddsellerlist', params);
  }
};
export default ConfigurationApi;