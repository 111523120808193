import { POST, GET } from '@/utils/util';

// 认证管理相关接口
var AuthenticationApi = {
  // https://gitlab.test.solestagesupply.com/docs/api-docs/-/tree/release/%E7%94%A8%E6%88%B7%E6%9C%8D%E5%8A%A1/%E8%AE%A4%E8%AF%81%E7%AE%A1%E7%90%86
  // 列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userattestations/findpage', params);
  },
  // 日志列表
  logs: function logs() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userattestations/findpagelog', params);
  },
  // 详情
  find: function find() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/userattestations/getdetails', params);
  },
  // 审核
  updateStatus: function updateStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userattestations/checkstatus', params);
  },
  // 新增
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userattestations/batch/save', params);
  },
  // 变更
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userattestations/updatedetails', params);
  },
  //用户列表
  userList: function userList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/searchByUsernameOrUid', params);
  }
};
export default AuthenticationApi;