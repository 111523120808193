// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var BusinessApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000051
  // 查询列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/getmanagementlist', params);
  },
  // 品牌 详情
  getSale: function getSale() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/sales/getSaleProducts', params);
  },
  // 修改商家类型
  updatetype: function updatetype() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/updatetype', params);
  },
  // 查询在售商家商品明细
  getProductDetails: function getProductDetails() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/saleservice/sales/getSaleProductDetails', params);
  },
  // 商家-查看详情
  busDetail: function busDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/business/view/detail', params);
  },
  // 商家-查看详情-历史数据
  bushistoryData: function bushistoryData() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/business/view/history/data', params);
  },
  // 商家-由类型获取等级
  levelAllByType: function levelAllByType() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/business/levelAllByType', params);
  }
};
export default BusinessApi;