// 机器人打招呼
import { POST, GET, DELETE } from '@/utils/util';
var greetingApi = {
  //  机器人打招呼列表
  managmentList: function managmentList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/machine/msg/managment/list', params);
  },
  //  新增机器人消息配置
  save: function save() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/machine/msg/managment/save', params);
  },
  // 修改和删除机器人消息配置
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/machine/msg/managment/update', params);
  }
};
export default greetingApi;