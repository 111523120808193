import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import store from "@/store";
import router, { resetRouter } from "../../plugins/router";
var outRuter = [{
  path: "/login",
  name: "login",
  hiddenMenu: true,
  component: function component() {
    return import("@/views/login");
  }
}, {
  path: "*",
  name: "404",
  hiddenMenu: true,
  component: function component() {
    return import("@/views/error/404.vue");
  }
}];
// 免token登陆页面,登陆页面 & 首页
var whiteList = ["/login", "/"];

// 仅允许未登录访问的页面
var guestList = ["/login", "/"];
router.beforeEach(function (to, from, next) {
  // router.options.routes.push(...outRuter)
  // router.addRoute(...outRuter)
  // console.log('A-Routes', router);
  // next()

  // sessionStorage.setItem("path", to.fullPath);
  // let path = sessionStorage.getItem("path") || "";
  if ((store.getters["base/getUserInfo"] || {}).token) {
    //     // menu判断
    //     console.log('store.getters.addRoutes', store.getters.addRoutes)
    //     if(localStorage.getItem('isOK')) return
    //     if (store.getters.addRoutes.length === 0) {
    //     store
    //         .dispatch("base/getMenuList")
    //         .then((menuList) => {
    //             localStorage.setItem('isOK', true)
    //             // 动态加载侧边栏的菜单。
    //             // resetRouter();
    //             console.log('menuList', menuList)
    //             router.addRoutes(menuList);
    //             next()
    //             // hack方法 确保addRoutes已完成
    //             // next({
    //             //     path: path ? path : to.path,
    //             // });
    //         })
    //         .catch((err) => {
    //             window.console.log("base/getMenuList--err", JSON.stringify(err), err);
    //             // store.commit("clearState");
    //             // store.commit("base/clearState");
    //             if (whiteList.indexOf(to.path) !== -1) {
    //                 next();
    //             } else {
    //                 next("/login");
    //             }
    //         });
    //     } else {
    if (guestList.includes(to.path)) {
      next("/welcome/index");
    } else {
      next();
    }

    //     }
  } else {
    store.commit("clearState");
    store.commit("base/clearState");
    // // 没有token,免登录白名单直接进入，否则去登录
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next("/login");
    }
  }
});
router.afterEach(function () {});