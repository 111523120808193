export default {
  commentID: "Comment ID",
  releaseTime: "Send time",
  rootComment: "Root comment",
  commentContent: "Comment content",
  likeCnts: "Like cnts",
  senderID: "Sender ID",
  senderName: "Sender Name",
  confirmDesc: 'Operation tip Confirm to {action} the selected Comment IDs, click "Confirm" to process',
  chooseReasonTemplate: "Choose reason template",
  pleaseChooseTheSuitableReason: "Please choose the suitable reason",
  enterTheRemark: "Enter the rmark",
  setAsPinnedComment: "Set as pinned comment",
  previewVideo: "Preview video",
  sendTimeBegin: "Send time_begin",
  sendTimeOver: "Send time_over",
  pin: "Pin",
  operatTip: 'Please select at least one item to operate!',
  operatTip2: 'There is no "invaild" data in the options',
  sourceType: 'SourceType'
};