/**
 * @description 交易明细相关所有接口
 */

import { POST
// GET,
} from '@/utils/util';
var ConsumptionApi = {
  // 获取交易明细列表
  getList: function getList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userconsumelog/page', params);
  }
};
export default ConsumptionApi;