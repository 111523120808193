import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var businessaudit = [{
  path: '/businessaudit/index',
  name: '商家审核',
  type: 2,
  meta: {
    title: '商家审核',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/businessaudit");
  }
}, {
  path: '/businessaudit/detail',
  name: '商家审核详情',
  type: 2,
  meta: {
    title: '商家审核商家审核详情',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/businessaudit/detail");
  }
}];
export default businessaudit;