import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "clickoutside",
      rawName: "v-clickoutside",
      value: function value() {
        return _vm.visible = false;
      },
      expression: "() => (visible = false)"
    }],
    staticClass: "highlight-box"
  }, [_vm.type === "textarea" ? [_vm.text ? _c("div", {
    staticClass: "textarea-outer"
  }, [_c("div", {
    staticClass: "ant-input outer-inner",
    domProps: {
      innerHTML: _vm._s(_vm.highlightHtml(_vm.text))
    }
  })]) : _vm._e(), _c("a-textarea", _vm._g(_vm._b({
    ref: "inputRef",
    class: {
      "text-transparent": _vm.text
    },
    attrs: {
      placeholder: _vm.placeholder,
      "auto-size": _vm.autoSize,
      value: _vm.text
    },
    on: {
      change: _vm.onChange
    }
  }, "a-textarea", _vm.$attrs, false), _vm.$listeners))] : _vm._e(), _vm.type === "input" ? [_vm.text ? _c("div", {
    staticClass: "ant-input input-outer",
    domProps: {
      innerHTML: _vm._s(_vm.highlightHtml(_vm.text))
    }
  }) : _vm._e(), _c("a-input", _vm._g(_vm._b({
    ref: "inputRef",
    class: {
      "text-transparent": _vm.text
    },
    attrs: {
      placeholder: _vm.placeholder,
      value: _vm.text
    },
    on: {
      change: _vm.onChange
    }
  }, "a-input", _vm.$attrs, false), _vm.$listeners))] : _vm._e(), _c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "tags-wrapper"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchList.length,
      expression: "searchList.length"
    }]
  }, _vm._l(_vm.searchList, function (item) {
    return _c("li", {
      key: item.id,
      class: ["tag-item", _vm.tags.includes(item.name) ? "is-checked" : ""],
      on: {
        click: function click($event) {
          return _vm.handleCheck(item);
        }
      }
    }, [_c("span", {
      staticClass: "tag-name"
    }, [_vm._v(_vm._s(item.name))]), _c("a-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.tags.includes(item.name),
        expression: "tags.includes(item.name)"
      }],
      staticClass: "icon-check",
      attrs: {
        type: "check"
      }
    })], 1);
  }), 0), _c("a-empty", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.searchList.length,
      expression: "!searchList.length"
    }],
    staticStyle: {
      margin: "20px 0"
    }
  }), _vm.fetching ? _c("div", {
    staticClass: "loading-wrap"
  }, [_c("a-spin")], 1) : _vm._e()], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };