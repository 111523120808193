import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcBanner = [{
  path: '/dcBanner/index',
  name: 'dcBanner',
  type: 2,
  meta: {
    title: '首页运营位管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcBanner/");
  }
}];
export default dcBanner;