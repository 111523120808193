import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var splashScreen = [
// 
{
  path: '/splashScreen/index',
  name: '闪屏配置',
  type: 3,
  meta: {
    title: '闪屏配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/splashScreen");
  }
}, {
  path: '/splashScreen/add',
  name: '新增',
  type: 3,
  meta: {
    title: '新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/splashScreen/addSplash.vue");
  }
}, {
  path: '/splashScreen/edit',
  name: '编辑',
  type: 3,
  meta: {
    title: '编辑',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/splashScreen/addSplash.vue");
  }
}];
export default splashScreen;