import { POST, GET } from '@/utils/util';
var AppConfigApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000744
  // 语言包列表
  getLangList: function getLangList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/languagePack/index', params);
  },
  // 新增语言包
  createLang: function createLang() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/phpservice/admin/account/languagePack/add', params);
  },
  // 语言包详情
  getLangInfo: function getLangInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/languagePack/detail', params);
  },
  //  语言包编辑
  updateInfo: function updateInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/phpservice/admin/account/languagePack/edit', params);
  },
  //  语言包删除
  deletInfo: function deletInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/languagePack/delete', params);
  }
};
export default AppConfigApi;