// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var IdentificationCurrencyApi = {
  // 鉴定币充值订单
  balanceList: function balanceList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/check/recharge/list', params);
  },
  //导出鉴定币充值订单
  downBalanceList: function downBalanceList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/check/recharge/list', params, true);
  }
};
export default IdentificationCurrencyApi;