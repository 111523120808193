var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ant-cascader-select"
  }, [_c("a-select", {
    ref: "selector",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      mode: "multiple",
      dropdownClassName: "ant-cascader-select-drop",
      dropdownMatchSelectWidth: false,
      open: _vm.menuVisible,
      allowClear: _vm.allowClear,
      placeholder: _vm.placeholder,
      size: _vm.size,
      disabled: _vm.disabled,
      defaultOpen: _vm.defaultOpen,
      suffixIcon: _vm.suffixIcon,
      removeIcon: _vm.removeIcon,
      clearIcon: _vm.clearIcon,
      maxTagCount: _vm.maxTagCount,
      maxTagPlaceholder: _vm.maxTagPlaceholder,
      maxTagTextLength: _vm.maxTagTextLength,
      optionLabelProp: _vm.optionLabelProp,
      optionFilterProp: _vm.optionFilterProp,
      labelInValue: ""
    },
    on: {
      focus: _vm.openMenu,
      blur: _vm.closeMenu
    },
    model: {
      value: _vm.selectedLabels,
      callback: function callback($$v) {
        _vm.selectedLabels = $$v;
      },
      expression: "selectedLabels"
    }
  }, [_c("div", {
    staticClass: "heightLimit",
    attrs: {
      slot: "dropdownRender"
    },
    slot: "dropdownRender"
  }, [_c("a-col", [_c("a-list", {
    attrs: {
      size: "small",
      "data-source": _vm.listData,
      split: false
    },
    scopedSlots: _vm._u([{
      key: "renderItem",
      fn: function fn(item, index) {
        return _c("a-list-item", {
          class: {
            active: _vm.activeIdx === index
          },
          on: {
            click: function click() {
              return _vm.handleClick(item, index);
            }
          }
        }, [_c("span", [_vm._v(_vm._s(item[_vm.optionName]))]), _c("a-icon", {
          attrs: {
            type: "right"
          }
        })], 1);
      }
    }])
  })], 1), _vm.childList && _vm.childList.length ? _c("a-col", [_c("a-list", {
    attrs: {
      size: "small",
      "data-source": _vm.childList,
      split: false
    },
    scopedSlots: _vm._u([{
      key: "renderItem",
      fn: function fn(item, index) {
        return _c("a-list-item", {
          on: {
            click: function click() {
              return _vm.handleClick(item, index);
            }
          }
        }, [_c("a-checkbox", {
          attrs: {
            checked: _vm.getChecked(item)
          }
        }), _c("span", [_vm._v(_vm._s(item[_vm.optionName]))])], 1);
      }
    }], null, false, 2699182545)
  })], 1) : _vm._e()], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };