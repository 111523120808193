// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var OrderApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000101
  // 销售订单tab
  getTab: function getTab() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/orderservice/order/getsalestable', params);
  },
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000102
  // 销售订单列表
  getOrderList: function getOrderList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/orderservice/order/salesorderpage', params);
  },
  // 待发货信息
  getdropshiping: function getdropshiping() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/orderservice/order/getdropshiping', params);
  },
  // 操作日志
  getorderloglist: function getorderloglist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/orderservice/order/getorderloglist', params);
  },
  getAdresInfo: function getAdresInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/orderservice/order/shipping/info', params);
  }
};
export default OrderApi;