import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "select-wrapper"
  }, [_c("a-select", _vm._g(_vm._b({
    ref: "selectRef",
    style: {
      width: _vm.width
    },
    attrs: {
      mode: _vm.mode,
      size: _vm.size,
      open: _vm.open,
      filterOption: false,
      placeholder: _vm.placeholder
    },
    on: {
      change: _vm.handleChange,
      search: _vm.fetchUser
    },
    scopedSlots: _vm._u([{
      key: "dropdownRender",
      fn: function fn(menu) {
        return _c("div", {
          on: {
            click: function click(e) {
              e.stopPropagation();
            }
          }
        }, [_c("div", {
          staticStyle: {
            display: "flex",
            padding: "5px 10px"
          }
        }, [_c("a-input", {
          staticStyle: {
            flex: "auto",
            width: "100%",
            "border-top-right-radius": "0",
            "border-bottom-right-radius": "0"
          },
          attrs: {
            placeholder: "添加自定义标签"
          },
          model: {
            value: _vm.tagname,
            callback: function callback($$v) {
              _vm.tagname = $$v;
            },
            expression: "tagname"
          }
        }), _c("a-button", {
          staticStyle: {
            "min-width": "auto",
            "border-top-left-radius": "0",
            "border-bottom-left-radius": "0"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: _vm.handleAddTag
          }
        }, [_vm._v(" 添加 ")])], 1), _c("v-nodes", {
          attrs: {
            vnodes: menu
          }
        })], 1);
      }
    }]),
    model: {
      value: _vm.selects,
      callback: function callback($$v) {
        _vm.selects = $$v;
      },
      expression: "selects"
    }
  }, "a-select", _vm.$attrs, false), _vm.$listeners), [_vm.fetching ? _c("a-spin", {
    staticStyle: {
      padding: "5px 0px",
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      height: "80px"
    },
    attrs: {
      slot: "notFoundContent",
      size: "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.tagList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.open,
      expression: "!open"
    }],
    staticClass: "select_overlap",
    style: {
      width: _vm.width
    },
    on: {
      mouseup: _vm.openSelect
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };