import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
// default props by ant-design-vue
import { SelectProps } from "ant-design-vue/es/select/index";
import { flattenTreeData } from "@/utils/help";
export default {
  props: _objectSpread(_objectSpread({}, SelectProps), {}, {
    listData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  }),
  model: {
    prop: "value",
    event: "change"
  },
  created: function created() {
    var _this = this;
    this.selectedLabels = this.selectedVals.map(function (key) {
      var it = _this.flattens.find(function (item) {
        return item[_this.optionValue] == key;
      });
      return {
        key: key,
        label: it[_this.optionName]
      };
    });
  },
  data: function data() {
    return {
      selectedVals: [],
      selectedLabels: [],
      activeIdx: 0,
      menuVisible: false
    };
  },
  methods: {
    handleClick: function handleClick(option, index) {
      var val = option[this.optionValue];
      var label = option[this.optionName];
      var isParent = option.children && option.children.length;
      if (isParent) {
        // get the active list's data
        this.activeIdx = index;
      } else {
        // change the model's data
        if (this.selectedVals.includes(val)) {
          var curIdx = this.selectedVals.findIndex(function (item) {
            return val === item;
          });
          this.selectedVals.splice(curIdx, 1);
          this.selectedLabels.splice(curIdx, 1);
        } else {
          this.selectedVals.push(val);
          this.selectedLabels.push({
            key: val,
            label: label
          });
        }
      }
      // keep menu display
      this.$refs.selector.focus();
    },
    getChecked: function getChecked(item) {
      return this.selectedVals.includes(item[this.optionValue]);
    },
    openMenu: function openMenu() {
      this.menuVisible = true;
    },
    closeMenu: function closeMenu() {
      this.menuVisible = false;
    },
    setValue: function setValue(val) {
      this.selectedVals = val || [];
    }
  },
  computed: {
    childList: function childList() {
      return this.listData[this.activeIdx]["children"] || [];
    },
    optionName: function optionName() {
      return this.optionLabelProp || "label";
    },
    optionValue: function optionValue() {
      return this.optionFilterProp || "value";
    },
    flattens: function flattens() {
      return flattenTreeData(this.listData);
    }
  },
  watch: {
    // selectedVals(val) {
    //   this.$emit("change", val);
    // },
    value: {
      handler: "setValue",
      immediate: true
    },
    selectedLabels: function selectedLabels(val) {
      val = val || [];
      this.selectedVals = val.map(function (item) {
        return item.key;
      });
      // console.log(this.selectedVals);
      this.$emit("change", this.selectedVals);
    }
  }
};