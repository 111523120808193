import { POST, GET } from "../utils/util";
var CourseManageApi = {
  studycurriculumList: function studycurriculumList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/studycurriculum/findpage', params);
  },
  studycurriculumInfo: function studycurriculumInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/studycurriculum/getdetails', params);
  },
  studycurriculumAdd: function studycurriculumAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/studycurriculum/save', params);
  },
  studycurriculumUpdate: function studycurriculumUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/studycurriculum/update', params);
  },
  studycurriculumDel: function studycurriculumDel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/studycurriculum/delete', params);
  },
  studycollectionsList: function studycollectionsList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/studycollections/findpage', params);
  },
  studycollectionsInfo: function studycollectionsInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/studycollections/getdetails', params);
  },
  studycollectionsAdd: function studycollectionsAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/studycollections/save', params);
  },
  studycollectionsUpdate: function studycollectionsUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/studycollections/update', params);
  },
  studycollectionsDel: function studycollectionsDel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/studycollections/delete', params);
  },
  categoryList: function categoryList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/studycategory/getstudycategorylist', params);
  },
  setCategory: function setCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/studycategory/save', params);
  },
  gradeList: function gradeList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/studygrade/getstudygradelist', params);
  },
  setGrade: function setGrade() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/studygrade/save', params);
  },
  searchUser: function searchUser() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/accountservice/user/open/search/username', params);
  },
  searchVideo: function searchVideo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/communityservice/videos/getdetails', params);
  }
};
export default CourseManageApi;