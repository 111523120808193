import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var lottery = [{
  path: '/lottery/index',
  name: '奖项管理',
  type: 2,
  meta: {
    title: '奖项管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/lotteryManagement/index.vue");
  }
}, {
  path: '/lottery/add',
  name: 'addLottery',
  type: 3,
  meta: {
    title: '新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/lotteryManagement/add.vue");
  }
}, {
  path: '/lottery/view',
  name: 'viewLottery',
  type: 3,
  meta: {
    title: '查看',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/lotteryManagement/add.vue");
  }
}, {
  path: '/lottery/edit',
  name: 'editLottery',
  type: 3,
  meta: {
    title: '编辑',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/lotteryManagement/add.vue");
  }
}];
export default lottery;