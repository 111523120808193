import { POST, GET } from '@/utils/util';
var BcManageApi = {
  // 投稿任务列表分类统计
  getTaskStatistic: function getTaskStatistic() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/ad/manage/task/statistic', params);
  },
  // 广告任务列表
  getTaskList: function getTaskList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/task/list', params);
  },
  // 任务详情
  getTaskDetail: function getTaskDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/ad/creator/open/task/detail', params);
  },
  getTaskVideos: function getTaskVideos() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/ad/creator/open/task/videos', params);
  },
  // 广告任务审核通过
  taskPass: function taskPass() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/task/audit/pass', params);
  },
  // 广告任务审核拒绝
  taskReject: function taskReject() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/task/audit/reject', params);
  },
  taskInfo: function taskInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/ad/manage/task/detail/".concat(params.taskId), params);
  },
  // 任务投稿列表
  getManuscriptList: function getManuscriptList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/manuscript/list', params);
  },
  // 投稿任务列表分类统计
  getManuscriptStatistic: function getManuscriptStatistic() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/ad/manage/manuscript/statistic', params);
  },
  // 任务投稿审核通过
  manuscriptPass: function manuscriptPass() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/manuscript/audit/pass', params);
  },
  // 任务投稿审核拒绝
  manuscriptReject: function manuscriptReject() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/manuscript/audit/reject', params);
  },
  // 任务投稿详情
  manuscriptDetail: function manuscriptDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/ad/manage/manuscript/detail/".concat(params.id), params);
  },
  // 创作者评分列表
  evaluateList: function evaluateList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/evaluate/list', params);
  },
  // 数据服务支付记录
  orderList: function orderList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/analytics/order/list', params);
  },
  //获取支付配置
  getPayConfig: function getPayConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/ad/manage/payment/config', params);
  },
  //支付配置
  setPayConfig: function setPayConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/payment/setting', params);
  },
  //获取CPM限制范围
  getTaskcpmConfig: function getTaskcpmConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/ad/manage/task/cpm', params);
  },
  //配置CPM限制范围
  setTaskcpmConfig: function setTaskcpmConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/ad/manage/task/cpm/setting', params);
  }
};
export default BcManageApi;