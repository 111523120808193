import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 充值记录路由配置
 */

var rechargeRecord = [{
  path: '/rechargeRecord/index',
  name: 'rechargeRecord',
  type: 2,
  meta: {
    title: 'rechargeRecord',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/rechargeRecord/index.vue");
  }
}];
export default rechargeRecord;