import { message } from 'ant-design-vue';
message.config({
  maxCount: 3
});
var GlobalFunc = {
  methods: {
    /** type: 'success'、'error' 、'warning' */toast: function toast(msg, type) {
      switch (type) {
        case 'success':
          message.success(msg);
          break;
        case 'error':
          message.error(msg);
          break;
        case 'warning':
          message.warning(msg);
          break;
        default:
          message.open({
            content: msg
          });
      }
    }
  }
};
var ModalConfg = {
  centered: true,
  maskClosable: true,
  cancelText: '取消',
  destroyOnClose: true,
  okText: '确定',
  icon: 'null'
};
export { GlobalFunc, ModalConfg };