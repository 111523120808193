import { POST, GET } from '@/utils/util';
var AfterSaleOrderApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000500
  // 售后订单列表
  aftersalepage: function aftersalepage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/orderservice/order/aftersalepage', params);
  },
  // 售后详情
  getaftersaleinfo: function getaftersaleinfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/orderservice/order/getaftersaleinfo', params);
  },
  // 查询商家物流信息
  getsellerexpress: function getsellerexpress() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/orderservice/order/getsellerexpress', params);
  },
  // 查询平台物流详情
  getsysexpress: function getsysexpress() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/orderservice/order/getsysexpress', params);
  }
};
export default AfterSaleOrderApi;