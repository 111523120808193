export default {
  searchForm: {
    creatorNickName: '请输入用户昵称',
    keywordSearching: '请输入视频关键词',
    reporterID: '发布人ID',
    creatorID: '发布人ID',
    modifiedTime: '创建时间',
    beginTime: '开始时间',
    overTime: '结束时间',
    annotatorName: '请输入标注人名称'
  },
  detail: '详情审核',
  fans: '粉丝数',
  source: '来源',
  creator: '发布人',
  statusList: {
    state0: '待审核',
    state1: '审核通过',
    state2: '驳回',
    state3: '用户删除'
  },
  viewDetail: {
    state: '状态',
    title: '标题'
  },
  modifiedTime: '更新时间',
  videoState: '视频状态',
  state: '处理状态',
  annotator: '标注人',
  unprocessed: '未处理',
  processed: '已处理',
  mark: '标注',
  logTitle01: '批量通过',
  logTitle02: '批量驳回',
  logTitle03: '操作日志',
  logTitle04: '视频上传',
  detailDefaultTitle: '内容标注',
  detailTitleCompleted: '{title} 【视频ID: {editId}】',
  detailForm: {
    title: '标题：',
    nickName: '昵称：',
    userId: '用户ID：',
    category: '内容分类：',
    timeliness: '内容时效：',
    quality: '内容质量：',
    issueCBK: '是否发放CBK：',
    yes: '发',
    no: '不发',
    remark: '备注：',
    placeholder01: '请输入标题',
    placeholder02: '请选择',
    placeholder03: '请选择理由模板',
    placeholder04: '请输入驳回理由',
    message01: '请选择状态',
    message02: '请输入驳回理由',
    message03: '最多200个字符',
    message04: '请输入标题',
    message05: '请选择内容分类',
    message06: '请选择内容质量',
    warining01: '内容分类、内容时效、内容质量至少选择一项'
  },
  detailBtn: {
    sumbit: '提交',
    next: '下一条',
    viewLogs: '查看日志'
  },
  add: '作品上传',
  postId: '作品ID',
  username: '用户昵称',
  keyword: '关键词',
  creatorID: '发布人ID',
  createTime: '创建时间',
  beginTime: '开始时间',
  overTime: '结束时间',
  type: '类型',
  typeList: {
    type1: '视频',
    type2: '图文'
  },
  dragUploadHint: '支持{0}格式，单文件大小不超过{1}，最多{2}个。',
  form: {
    title: '作品标题',
    creator: '创作者',
    note: '注',
    creatorNote: '请输入用户名进行搜索',
    tag: '标签',
    cover: '封面图',
    allowDownload: '允许用户下载',
    uploadSubtitle: '上传字幕',
    allow: '允许',
    notAllowed: '不允许',
    noFile: '{0}未上传'
  },
  edit: '编辑',
  selectLeastOne: '至少选择一个作品.',
  reason: '理由模板',
  score: '机审评分'
};