import { POST, GET } from '@/utils/util';
var AutoBiddingApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000749
  // 自动出价列表
  getBiddingList: function getBiddingList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/autooffer/list', params);
  },
  // 自动出价详情
  biddingInfo: function biddingInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/saleservice/autooffer/details', params);
  },
  // 自动出价详情 - 操作日志
  infoListlog: function infoListlog() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/saleservice/autooffer/listlog', params);
  },
  // 批量上架
  shelf: function shelf() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/autooffer/shelvesInbulk', params);
  },
  // 批量下架
  takeDown: function takeDown() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/autooffer/offshelf', params);
  },
  // 批量修改
  revise: function revise() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/autooffer/update', params);
  },
  // 批量删除
  delet: function delet() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/autooffer/batchdelete', params);
  },
  // 批量导入
  import: function _import() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/autooffer/save', params);
  },
  // 恢复
  recover: function recover() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/autooffer/recover', params);
  }
};
export default AutoBiddingApi;