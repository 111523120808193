import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
var state = {
  moduleList: [{
    id: 1,
    name: "H5Top Banner"
  }, {
    id: 2,
    name: "News"
  }, {
    id: 3,
    name: "Launchpad"
  }, {
    id: 4,
    name: "Collection Rankings"
  }, {
    id: 5,
    name: "Recommended NFT"
  }, {
    id: 6,
    name: "Data"
  }, {
    id: 7,
    name: "Brand Video"
  }],
  bannerTypeList: [{
    id: 1,
    name: '通屏'
  }, {
    id: 2,
    name: 'Slogan'
  }, {
    id: 3,
    name: 'Launchpad'
  }],
  newsTypeList: [{
    id: 1,
    name: '1-4'
  }
  // {id: 2, name: '4-1'},
  ]
};

// 全局计算属性
var getters = {
  moduleListObj: function moduleListObj(state) {
    var result = {};
    state.moduleList.forEach(function (row) {
      result[row.id] = row.name;
    });
    return result;
  },
  newsTypeListObj: function newsTypeListObj(state) {
    var result = {};
    state.newsTypeList.forEach(function (row) {
      result[row.id] = row.name;
    });
    return result;
  }
};
var mutations = {};

// 异步的action,请求工具限制请求数据不能使用vuex。
var actions = {};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};