// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var DcBannerApi = {
  // banner列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/manage/open/banner/list', params);
  },
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000055
  // 删除 banner
  bannerDelete: function bannerDelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/manage/open/banner/delete', params);
  },
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000053
  // 添加 banner
  bannerAdd: function bannerAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/manage/open/banner/add', params);
  },
  // 更新 banner
  bannerUpdate: function bannerUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/manage/open/banner/edit', params);
  },
  // banner 详情
  bannerDetail: function bannerDetail() {
    var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return GET("/api/dcservice/manage/open/banner/detail/".concat(id));
  },
  // 运营位列表
  configList: function configList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/manage/open/banner/config', params);
  },
  // 运营位配置
  configSetting: function configSetting() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/manage/open/banner/config/setting', params);
  },
  // 排序
  sort: function sort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/manage/open/banner/sort', params);
  }
};
export default DcBannerApi;