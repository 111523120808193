import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var appConfig = [{
  path: '/langPack/index',
  name: '语言配置',
  type: 2,
  meta: {
    title: '语言配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/langPack/index");
  }
}, {
  path: '/langPack/detail',
  name: '',
  type: 2,
  meta: {
    title: '详情',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/langPack/detail");
  }
}];
export default appConfig;