import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// 账号管理页面的所有接口
import { POST, GET, MD5 } from '@/utils/util';
var CheckerApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000080
  // 鉴定师列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checker/findpage', params);
  },
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000084
  // 删除鉴定师信息
  deleteChecker: function deleteChecker() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checker/delete', params);
  },
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000082
  // 新增鉴定师
  save: function save() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _ref = params || {},
      password = _ref.password;
    var filterParams = _objectSpread(_objectSpread({}, params), {}, {
      password: password && MD5(password) || ''
    });
    if (!filterParams.password) delete filterParams.password;
    return POST('/api/checkservice/checker/save', filterParams);
  },
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000105
  // 获取全部鉴定师信息
  getAllChecker: function getAllChecker() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checker/getall', params);
  },
  // 鉴定师详情
  checkerDetail: function checkerDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checker/findone', params);
  },
  // 查询可鉴定范围
  getcheckrangelist: function getcheckrangelist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checkrange/getcheckrangelist', params);
  },
  // 鉴定师 停用/启用
  updatestatus: function updatestatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checker/updatestatus', params);
  },
  // 查看鉴定师详情
  getcheckerinfo: function getcheckerinfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checker/getcheckerinfo', params);
  },
  // 鉴定师更新
  checkerUpdate: function checkerUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _ref2 = params || {},
      password = _ref2.password;
    var filterParams = _objectSpread(_objectSpread({}, params), {}, {
      password: password && MD5(password) || ''
    });
    if (!filterParams.password) delete filterParams.password;
    return POST('/api/checkservice/checker/update', filterParams);
  },
  // 获取类目列表
  categoryAll: function categoryAll() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/category/getall', params);
  },
  // 获取品牌列表
  brandAll: function brandAll() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/brand/getall', params);
  },
  // 鉴定范围列表
  checkrangeList: function checkrangeList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checkrange/findpage', params);
  },
  // 添加鉴定范围
  checkrangeSave: function checkrangeSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checkrange/save', params);
  },
  // 更新鉴定范围
  checkrangeUpdate: function checkrangeUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checkrange/update', params);
  },
  // 鉴定范围详情
  checkrangeDetail: function checkrangeDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checkrange/findone', params);
  },
  // 删除鉴定范围
  checkrangeDelete: function checkrangeDelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checkrange/delete', params);
  },
  // 结算单列表
  settlementList: function settlementList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/settlement/getlist', params);
  },
  // 结算单详情 
  findone: function findone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/settlement/findone', params);
  },
  // 结算详情列表 
  getitemlist: function getitemlist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/settlement/getitemlist', params);
  },
  // 鉴定类目列表
  getcheckcategoryname: function getcheckcategoryname() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/open/getcheckcategoryname', params);
  },
  // 品牌-类目列表
  getcheckrangeall: function getcheckrangeall() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checkrange/getcheckrangeall', params);
  },
  // 鉴定记录列表查询
  getcheckloglist: function getcheckloglist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/check/getcheckloglist', params);
  }
};
export default CheckerApi;