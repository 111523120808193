import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
export default {
  name: "PreviewPic",
  props: {
    isShowPic: {
      type: Boolean,
      default: false
    },
    previewImage: {
      type: String,
      default: ""
    },
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    slideNumber: {
      type: Number,
      default: 0
    }
  },
  watch: {
    isShowPic: function isShowPic(newVal) {
      var _this = this;
      if (newVal) {
        this.show();
        window.addEventListener('hide', function () {
          // console.log('hide event triggered...');
          _this.closeModal();
        });
      }
    }
  },
  data: function data() {
    return {
      options: {
        toolbar: true,
        navbar: false,
        initialViewIndex: 0
      }
    };
  },
  beforeDestroy: function beforeDestroy() {
    this.closeModal();
  },
  computed: {
    previewImageList: function previewImageList() {
      var imglist = [];
      if (this.previewImage.indexOf(",") == -1 && this.previewImage.indexOf("|") == -1) {
        return [this.previewImage];
      } else {
        if (this.previewImage.indexOf('|') != -1) {
          imglist = this.previewImage.split("|");
        }
        if (this.previewImage.indexOf(',') != -1) {
          imglist = this.previewImage.split(",");
        }
        return imglist.reduce(function (prev, cur, index, arr) {
          arr[index] ? cur = cur && prev.push(cur) : '';
          return prev;
        }, []);
      }
    }
  },
  methods: {
    // 关闭弹框
    closeModal: function closeModal() {
      window.removeEventListener('hide', function () {});
      this.$emit("closePreviewpic", "previewpic");
    },
    show: function show() {
      this.$viewerApi({
        options: {
          toolbar: true,
          navbar: false,
          initialViewIndex: this.slideNumber
        },
        images: this.previewImageList
      });
    }
  }
};