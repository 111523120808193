// 首页模块
import { POST, GET } from '@/utils/util';
var ConfigbannerApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000282
  // 列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/banner/page', params);
  },
  // tab
  tabcount: function tabcount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/banner/tabcount', params);
  },
  // 重新排序
  updatesort: function updatesort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/banner/updatesort', params);
  },
  // 新增
  save: function save() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/banner/save', params);
  },
  // 详情
  detail: function detail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/banner/detail', params);
  },
  // 修改
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/banner/update', params);
  },
  // 删除
  bannerdelete: function bannerdelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/banner/delete', params);
  }
};
export default ConfigbannerApi;