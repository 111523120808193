import { POST, GET } from '@/utils/util';
var saleCalendarHomeApi = {
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/calendarConfig/findList', params);
  },
  videoDetail: function videoDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/calendarConfig/queryDetail', params);
  },
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/calendarConfig/edit', params);
  },
  edit: function edit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/calendarConfig/edit', params);
  },
  del: function del() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/calendarConfig/delete', params);
  },
  //List user
  userList: function userList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/fanshood/presale/page', params);
  }
};
export default saleCalendarHomeApi;