import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.titles.length > 0 ? _c("div", {
    staticClass: "swiper_bar",
    attrs: {
      id: "swiperBar"
    }
  }, [_vm._l(_vm.titles, function (item, index) {
    return _c("div", {
      key: item.id || item.id == 0 ? item.id : item.name,
      staticClass: "cell_item",
      style: _vm.getCellStyle,
      on: {
        click: function click($event) {
          return _vm.handleClickTab(item, index);
        }
      }
    }, [_c("span", {
      staticClass: "nomal_line"
    }), _c("span", {
      staticClass: "title",
      class: _vm.getTitleClass(index)
    }, [_vm._v(_vm._s("".concat(item.name && item.name.length < 10 ? item.name : item.name && item.name.slice(0, 10) + "...")) + _vm._s(_vm.showNumb ? " (".concat(item.count || 0, ") ") : ""))]), _c("span", {
      staticClass: "line",
      class: _vm.getLineClass(index)
    })]);
  }), _vm._t("button")], 2) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };