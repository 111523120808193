var NftUnit = {
  template: "\n    <svg width=\"10\" height=\"16\" viewBox=\"0 0 10 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n      <path d=\"M5.09492 0.5V6.70151L0.643036 8.09743L5.09492 0.5Z\" fill=\"#8891AE\"/>\n      <path d=\"M5.09488 0.5V6.70151L9.54722 8.09743L5.09488 0.5Z\" fill=\"#5F658C\"/>\n      <path d=\"M5.09489 15.5V11.1889L0.625 8.73328L5.09489 15.5Z\" fill=\"#868BB1\"/>\n      <path d=\"M5.09488 15.5V11.1889L9.54722 8.73328L5.09488 15.5Z\" fill=\"#5E648D\"/>\n      <path d=\"M0.625 8.09744L5.07734 6.01257V10.2701L0.625 8.09744Z\" fill=\"#61668D\"/>\n      <path d=\"M9.54722 8.09744L5.09488 6.01257V10.2701L9.54722 8.09744Z\" fill=\"#404871\"/>\n    </svg>\n  "
};
var WethUnit = {
  template: "\n  <svg width=\"17\" height=\"17\" viewBox=\"0 0 17 17\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n    <path d=\"M8.50128 0L8.38281 0.386572V11.6298L8.50128 11.7415L13.8087 8.65828L8.50128 0Z\" fill=\"#DC1860\"/>\n    <path d=\"M8.49969 0L3.1875 8.65828L8.49969 11.7415V6.28761V0Z\" fill=\"#FF1D70\"/>\n    <path d=\"M8.49994 12.7297L8.43359 12.8089V16.8144L8.49994 17.0007L13.8121 9.64648L8.49994 12.7297Z\" fill=\"#DC1860\"/>\n    <path d=\"M8.49969 17.0007V12.7297L3.1875 9.64648L8.49969 17.0007Z\" fill=\"#FF1D70\"/>\n    <path d=\"M8.5 11.741L13.8075 8.65777L8.5 6.28711V11.741Z\" fill=\"#141414\" fill-opacity=\"0.5\"/>\n    <path d=\"M3.1875 8.65777L8.49969 11.741V6.28711L3.1875 8.65777Z\" fill=\"#393939\" fill-opacity=\"0.5\"/>\n  </svg>\n"
};
var NftIcon = {
  template: "\n    <a-icon :component=\"NftUnit\" />\n  ",
  data: function data() {
    return {
      NftUnit: NftUnit
    };
  }
};
var WethIcon = {
  template: "\n    <a-icon :component=\"WethUnit\" />\n  ",
  data: function data() {
    return {
      WethUnit: WethUnit
    };
  }
};
export default {
  name: 'Icon',
  components: {
    NftIcon: NftIcon,
    WethIcon: WethIcon
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    styleObject: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    ctype: function ctype() {
      return this.type.toLowerCase();
    }
  }
};