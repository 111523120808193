import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.error.cause.js";
import store from '@/store';
export default {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var super_admin = "admin";
    var roles = store.getters && store.getters.roles;
    if (value && value instanceof Array && value.length > 0 && roles) {
      var roleFlag = value;
      var hasRole = roles.some(function (role) {
        return super_admin === role || roleFlag.includes(role);
      });
      if (!hasRole) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("\u8BF7\u8BBE\u7F6E\u89D2\u8272\u6743\u9650\u6807\u7B7E\u503C\"");
    }
  }
};