// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var BlindBoxApi = {
  getblindSet: function getblindSet() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/accountservice/config/getconfigbyname', params);
  },
  blindSet: function blindSet() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/accountservice/config/updatebyname', params);
  },
  // 盲盒列表
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('api/dcservice/bbox/backend/list', params);
  },
  getblindData: function getblindData() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/dcservice/bbox/backend/statistic', params);
  }
};
export default BlindBoxApi;