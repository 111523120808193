// 白名单相关所有接口
import { POST, GET } from '@/utils/util';
var LaunchpadApi = {
  // 列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/launchpad/manage/list', params);
  },
  Add: function Add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/launchpad/manage/add', params);
  },
  Update: function Update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/launchpad/manage/update', params);
  },
  Delete: function Delete(id) {
    return GET("/api/dcservice/launchpad/manage/delete/".concat(id));
  },
  Upload: function Upload(params) {
    return POST('/api/dcservice/ipfs/upload/excel', params, false);
  },
  Detail: function Detail(launchpadId) {
    return GET("/api/dcservice/launchpad/manage/detail/".concat(launchpadId));
  }
};
export default LaunchpadApi;