import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var ambianceTeam = [{
  path: '/ambiance-team',
  name: '气氛组调控台',
  type: 1,
  meta: {
    title: '气氛组调控台',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/ambiance-team");
  }
}];
export default ambianceTeam;