import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.constructor.js";
/*
var myDate = new Date();
    myDate.getYear();      //获取当前年份(2位)
    myDate.getFullYear(); //获取完整的年份(4位,1970-????)
    myDate.getMonth();      //获取当前月份(0-11,0代表1月)
    myDate.getDate();      //获取当前日(1-31)
    myDate.getDay();        //获取当前星期X(0-6,0代表星期天)
    myDate.getTime();      //获取当前时间(从1970.1.1开始的毫秒数)
    myDate.getHours();      //获取当前小时数(0-23)
    myDate.getMinutes();    //获取当前分钟数(0-59)
    myDate.getSeconds();    //获取当前秒数(0-59)
    myDate.getMilliseconds(); //获取当前毫秒数(0-999)
    myDate.toLocaleDateString();    //获取当前日期
    var mytime = myDate.toLocaleTimeString();    //获取当前时间
myDate.toLocaleString();      //获取日期与时间----如果涉及到时分秒，直接使用即可。
*/

//获取当前日期
export function getCurrentDate() {
  var curr_date = new Date();
  var currentDate = "".concat(curr_date.getFullYear(), "-").concat(curr_date.getMonth() + 1, "-").concat(curr_date.getDate());
  return currentDate;
}
//获取当前时间戳
export function getCurrentDateTime() {
  var curr_date = new Date();
  var time = curr_date.getTime();
  return time;
}

//展示时间
export function showTime(dateStr) {
  dateStr = dateStr.replace(/-/g, "/"); //一般得到的时间的格式都是：yyyy-MM-dd hh24:mi:ss，所以我就用了这个做例子，是/的格式，就不用replace了。
  var end_date = new Date(dateStr); //将字符串转化为时间
  //开始时间
  var curr_date = new Date();
  var num = (curr_date - end_date) / (1000 * 3600 * 24); //求出两个时间的天数时间差
  var days = parseInt(Math.ceil(num)) - 1; //转化为整天

  switch (days //天数
  ) {
    case 0:
      //今天
      return currentDayShowTime(curr_date, end_date);
    case 1:
      //昨天
      return "昨天";
    case 2:
      //2天前
      return "两天前";
    case 3:
      //3天前
      return "三天前";
    case 4:
      //4天前
      return "四天前";
    case 5:
      //5天前
      return "5天前";
    case 6:
      //6天前
      return "6天前";
    default:
      return dateStr;
  }
}
function currentDayShowTime(curr_date, end_date) {
  var num = (curr_date - end_date) / (1000 * 3600); //求出两个时间的小时差
  var hours = parseInt(Math.ceil(num)) - 1; //转化为整小时

  if (hours >= 1) {
    return "".concat(hours, "\u5C0F\u65F6\u524D");
  } else {
    var _num = (curr_date - end_date) / (1000 * 60); //求出两个时间的分钟
    var m = parseInt(Math.ceil(_num)) - 1; //转化为分钟数
    if (m >= 1) {
      return "".concat(m, "\u5206\u949F\u524D");
    } else {
      return "刚刚";
    }
  }
}
export function currentWeekShowTime() {
  var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "星期";
  switch (new Date().getDay()) {
    case 0:
      return "".concat(prefix, "\u65E5");
    case 1:
      return "".concat(prefix, "\u4E00");
    case 2:
      return "".concat(prefix, "\u4E8C");
    case 3:
      return "".concat(prefix, "\u4E09");
    case 4:
      return "".concat(prefix, "\u56DB");
    case 5:
      return "".concat(prefix, "\u4E94");
    case 6:
      return "".concat(prefix, "\u516D");
    default:
      return "";
  }
}

/**
 *时间转换 date:dateFormat(new Date(result),'yyyy-MM-dd hh:mm'),
 * @export
 * @param {*} dateTime
 * @param {*} fmt
 * @returns
 */
export function dateFormat(dateTime, fmt) {
  var date = new Date(dateTime);
  fmt = fmt || "yyyy-MM-dd";
  var o = {
    "M+": date.getMonth() + 1,
    //月份
    "d+": date.getDate(),
    //日
    "h+": date.getHours(),
    //小时
    "m+": date.getMinutes(),
    //分
    "s+": date.getSeconds(),
    //秒
    "q+": Math.floor((date.getMonth() + 3) / 3),
    //季度
    S: date.getMilliseconds() //毫秒
  };

  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, "".concat(date.getFullYear()).substr(4 - RegExp.$1.length));
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : "00".concat(o[k]).substr("".concat(o[k]).length));
  }
  return fmt;
}

// 根据日期判断时间节点。
export function compareTime(selectedTime, currentTime) {
  if (selectedTime && currentTime) {
    var ST = new Date(dateFormat(selectedTime, "yyyy-MM-dd")).getTime();
    var CT = new Date(dateFormat(currentTime, "yyyy-MM-dd")).getTime();
    if (ST === CT) {
      return TIME_STATUS.today;
    } else if (ST < CT) {
      return TIME_STATUS.yesterday;
    } else if (ST > CT) {
      return TIME_STATUS.tomorrow;
    }
  } else {
    // 防止报错
    return TIME_STATUS.today;
  }
}
export var TIME_STATUS = {
  yesterday: "Yesterday",
  today: "Today",
  tomorrow: "Tomorrow"
};
export function formatSeconds(value) {
  var theTime = parseInt(value); // 需要转换的时间秒
  var theTime1 = 0; // 分
  var theTime2 = 0; // 小时
  var theTime3 = 0; // 天
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
      if (theTime2 > 24) {
        //大于24小时
        theTime3 = parseInt(theTime2 / 24);
        theTime2 = parseInt(theTime2 % 24);
      }
    }
  }
  var result = "";
  if (theTime > 0) {
    result = "" + parseInt(theTime) + "秒";
  }
  if (theTime1 > 0) {
    result = "" + parseInt(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result = "" + parseInt(theTime2) + "小时" + result;
  }
  if (theTime3 > 0) {
    result = "" + parseInt(theTime3) + "天" + result;
  }
  console.log(result, 1111);
  return result;
}
export function timeChange(UTCDateString) {
  if (!UTCDateString) {
    return "-";
  }
  function formatFunc(str) {
    return str > 9 ? str : "0" + str;
  }
  var date2 = new Date(UTCDateString);
  console.log("时间", date2);
  var year = date2.getFullYear();
  var mon = formatFunc(date2.getMonth() + 1);
  var day = formatFunc(date2.getDate());
  var hour = date2.getHours();
  var noon = hour >= 12 ? "PM" : "AM"; // 判断是上午还是下午
  hour = hour >= 12 ? hour - 12 : hour; // 12小时制
  hour = formatFunc(hour);
  var min = formatFunc(date2.getMinutes());
  var sec = formatFunc(date2.getSeconds());
  var dateStr = year + "-" + mon + "-" + day + " " + hour + ":" + min + ":" + sec;
  return dateStr;
}
export function formatDateToAgo(time) {
  try {
    if (process.server) {
      return '';
    }
    if (typeof time === 'string' && time.length === 10) {
      time = Number(time) * 1000;
    }
    if (typeof time === 'number' && String(time).length === 10) {
      time = time * 1000;
    }

    //获取当前时间
    var m1 = moment();
    //获取需要对比的时间
    var m2 = moment(time);
    //计算相差多少小分、时、天、月
    var minute = m1.diff(m2, 'minute');
    var hour = m1.diff(m2, 'hour');
    var day = m1.diff(m2, 'day');
    var month = m1.diff(m2, 'month');
    if (minute < 60) {
      var unit = 'min';
      return "".concat(minute || 1, " ").concat(minute > 1 ? unit + 's' : unit, " ago");
    } else if (hour < 24) {
      var _unit = 'hour';
      return "".concat(hour, " ").concat(hour > 1 ? _unit + 's' : _unit, " ago");
    } else if (month <= 0) {
      var _unit2 = 'day';
      return "".concat(day, " ").concat(day > 1 ? _unit2 + 's' : _unit2, " ago");
    } else if (month == 1) {
      var _unit3 = 'month';
      return "".concat(month, " ").concat(month > 1 ? _unit3 + 's' : _unit3, " ago");
    } else {
      return m2.format('MMM:YYYY,HH:mm');
    }
  } catch (error) {
    console.log('时间格式化错误：', error);
    return '';
  }
}