// 物流模板页面的所有接口
import { POST, GET, DELETE } from '@/utils/util';
var LogisticApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000345
  //  查询物流模板列表(买家)
  getFreightList: function getFreightList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/orderservice/expresstemplate/v3/find?lang=zh_CN', params);
  },
  //  查询物流模板列表(卖家)
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/expresstemplate/getlist', params);
  },
  // 获取列表数据详情
  getDetail: function getDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/expresstemplate/v2/find/detail', params);
  },
  //  查询仓库列表
  getlistbytype: function getlistbytype() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/useraddr/getlistbytype', params);
  },
  //  新增物流模板(卖家)
  savelogistic: function savelogistic() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/orderservice/expresstemplate/v3/save?lang=zh_CN', params);
  },
  //  新增物流模板(买家)
  saveFreight: function saveFreight() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/expresstemplate/v2/save', params);
  },
  //  更新物流模板(买家)
  updateFreight: function updateFreight() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/expresstemplate/v2/update?templateId=' + params.templateId, params);
  },
  //  查询物流模板
  findone: function findone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/orderservice/expresstemplate/v3/find/one?lang=zh_CN', params);
  },
  //  更新物流模板
  updatelogistic: function updatelogistic() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/orderservice/expresstemplate/v3/update?lang=zh_CN', params);
  },
  // 删除物流模板
  deletelogistic: function deletelogistic() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return DELETE('/api/orderservice/expresstemplate/v3/delete?lang=zh_CN', params);
  },
  //  查询所有物流模板
  alllogistic: function alllogistic() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/expresstemplate/getall?lang=zh_CN', params);
  },
  //  查询所有物流公司
  allexpress: function allexpress() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/expresscompany/getall', params);
  },
  // 获取国家
  getcountryconfig: function getcountryconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/useraddr/getcountryconfig', params);
  },
  //获取州信息
  getstate: function getstate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/useraddr/getstate', params);
  },
  //获取县信息
  getcounty: function getcounty() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/useraddr/getcounty', params);
  },
  // 新增仓库
  addwarehouse: function addwarehouse() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/useraddr/add', params);
  },
  // 编辑仓库
  updatewarehouse: function updatewarehouse() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/useraddr/update', params);
  },
  // 获取tab数量
  gettabletabnumber: function gettabletabnumber() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/expresstemplate/gettable', params);
  },
  // 获取详情
  detailbyid: function detailbyid() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/useraddr/detailbyid', params);
  },
  // 删除仓库
  delbyid: function delbyid() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/useraddr/delbyid', params);
  },
  // 新版获取国家
  getNewCountry: function getNewCountry() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/useraddr/country', params);
  },
  // 新版获取地区
  getNewAddress: function getNewAddress() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/useraddr/address', params);
  }
};
export default LogisticApi;