// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var LimitedApi = {
  // 添加限量发售
  savelimited: function savelimited() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/limitedsale/save', params);
  },
  // 限量发售详情
  getlimiteddetail: function getlimiteddetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/saleservice/limitedsale/findone', params);
  },
  // 限量发售 商品尺码详情
  getlimitedsaleitem: function getlimitedsaleitem() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/saleservice/limitedsale/getlimitedsalesize', params);
  },
  // 限量发售列表
  getlimitedlist: function getlimitedlist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/limitedsale/findpage', params);
  },
  // 更新限量发售
  updatelimited: function updatelimited() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/limitedsale/update', params);
  },
  // 删除限量发售
  deletelimited: function deletelimited() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/saleservice/limitedsale/delete', params);
  },
  // 根据邮箱查询用户信息
  getuserinfo: function getuserinfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/getuserinfo', params);
  },
  // 查询商品信息
  getproductdetails: function getproductdetails() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/product/getproductdetails', params);
  },
  // 商品详情 尺码信息
  getproductsizedetails: function getproductsizedetails() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/open/getproductsizedetails', params);
  },
  //tab
  gettab: function gettab() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/saleservice/limitedsale/gettabcount', params);
  },
  //
  //详情页商品
  getlimitedproduct: function getlimitedproduct() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/saleservice/limitedsale/getlimitedproduct', params);
  }
};
export default LimitedApi;