// 白名单相关所有接口
import { POST, GET } from '@/utils/util';
var WhitelistApi = {
  // 列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/user/whitelist/list', params);
  },
  searchUsername: function searchUsername() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/open/search/username', params);
  },
  searchUsernameV2: function searchUsernameV2() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/open/search/username/v2', params);
  },
  searchAdminByname: function searchAdminByname() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/adminuser/getadminuserinfo', params);
  },
  Add: function Add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/user/whitelist/add', params);
  },
  Delete: function Delete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/user/whitelist/delete', params);
  },
  Enable: function Enable() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/user/whitelist/enable', params);
  },
  Disable: function Disable() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/user/whitelist/disable', params);
  }
};
export default WhitelistApi;