// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var CategoryApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000051
  // 获取全部类目信息
  getCategoryAll: function getCategoryAll() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/syscategory/getcategoryall', params);
  },
  // 根据上级类目查询类目列表
  getcategorylist: function getcategorylist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syscategory/getcategorylist', params);
  },
  //类目 列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syscategory/getlist', params);
  },
  // 类目 详情 回显
  finddone: function finddone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/syscategory/findone', params);
  },
  // 类目 增
  categorySave: function categorySave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syscategory/categorysave', params);
  },
  // 类目 改
  updatecategory: function updatecategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syscategory/updatecategory', params);
  },
  // 类目 删
  delcategory: function delcategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/syscategory/delcategory', params);
  }
};
export default CategoryApi;