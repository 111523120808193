// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var BrandApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000051
  // 分页查询列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/brand/findpage', params);
  },
  // 品牌 详情
  finddone: function finddone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/brand/findone', params);
  },
  // 获取全部系统品牌
  getBrandALL: function getBrandALL() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/brand/getall', params);
  },
  // 新增品牌
  brandSave: function brandSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/brand/brandsave', params);
  },
  // 修改
  updateBrand: function updateBrand() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/brand/updatebrand', params);
  },
  // 删除品牌
  delupdateBrand: function delupdateBrand() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/brand/updateStatus', params);
  }
};
export default BrandApi;