export default {
  appName: '运营管理后台',
  admin: "管理员",
  search: "搜索",
  reset: "重置",
  submit: "提交",
  confirm: "确认",
  cancel: "取消",
  close: "关闭",
  total: "总共",
  type: "类型",
  edit: "编辑",
  delete: "删除",
  operation: "操作",
  all: "All",
  select: "选择",
  selectAll: "全选",
  batchPass: "批量通过",
  batchDelete: "批量删除",
  batchReject: "批量拒绝",
  video: "视频",
  photo: "图文",
  postID: "视频ID",
  createTime: "创建时间",
  postTitle: "视频标题",
  source: "来源",
  sourceID: "来源ID",
  creator: "创作者",
  passed: "已通过",
  pass: "通过",
  reject: "拒绝",
  rejected: "已驳回",
  userDeleted: "用户删除",
  reviewing: "待审核",
  nickname: "昵称",
  allow: "允许",
  notAllow: "不允许",
  cover: "封面图",
  tag: "标签",
  upload: "上传",
  next: "下一个",
  detail: "详情",
  fans: "粉丝量",
  userID: "用户ID",
  viewLog: "查看日志",
  logs: "日志",
  page: "页",
  goTo: "跳至",
  valid: "生效",
  batchValid: "批量生效",
  invalid: "失效",
  batchInvalid: "批量失效",
  state: "状态",
  uprocessed: "未处理",
  normal: "正常",
  adminDeleted: "管理员删除",
  remark: "备注",
  logID: "日志ID",
  to: "至",
  beginTime: "开始时间",
  overTime: "结束时间",
  keywordSearching: "关键词搜索",
  multipleRowsByEachID: "多个一行一个",
  recover: "恢复",
  reason: '理由',
  operationTip: "温馨提示",
  manage: "管理",
  date: "日期",
  yes: "是",
  no: "否",
  success: "成功",
  failed: "失败",
  leftMenu: {
    home: "首页",
    allPost: "全部作品",
    postReport: "视频举报",
    postReview: "视频审核",
    postAnnotation: "视频标注",
    allComment: "评论列表",
    contentManage: "内容管理",
    welcome: '欢迎你'
  },
  logout: '退出登录',
  copy: '复制',
  noMore: '当前为最后一条数据，不可切换！',
  nodata: '无数据',
  pleaseEnter: '请输入',
  pleaseSelect: '请选择',
  location: '位置',
  fileupload: {
    txtTips1: '上传{0}不超过{1}',
    txtBtn: '上传{0}',
    formatType: '格式:{0}',
    formatSize: '尺寸:{0}',
    formatFilesize: '大小不超过:{0}',
    typeVideo: '视频',
    typePic: '图片',
    typeFile: '文件',
    numMaximum: '最大数量:{0}'
  }
};