import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var limited = [
//限量发售
{
  path: '/limited/index',
  name: '限量发售',
  type: 2,
  meta: {
    title: '限量发售',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/limitedRelease");
  }
}, {
  path: '/limited/add',
  name: '新增',
  type: 2,
  meta: {
    title: '新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/limitedRelease/add");
  }
}, {
  path: '/limited/update',
  name: '修改',
  type: 2,
  meta: {
    title: '修改',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/limitedRelease/add");
  }
}, {
  path: '/limited/detail',
  name: '查看',
  type: 2,
  meta: {
    title: '查看',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/limitedRelease/detail");
  }
}, {
  path: '/limited/rules',
  name: '活动规则',
  type: 2,
  meta: {
    title: '活动规则',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/limitedRelease/rules");
  }
}];
export default limited;