// 首页模块
import { POST, GET } from '@/utils/util';
var ConfigCheck = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000586
  // 列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checkbasemap/findpage', params);
  },
  // 配置详情
  confDetail: function confDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checkbasemap/findone', params);
  },
  // 配置更新
  confUpdate: function confUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checkbasemap/update', params);
  },
  // 配置数量统计
  listcount: function listcount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checksettlement/listcount', params);
  },
  // 图灵系列-品牌
  getAIcategoryorBrand: function getAIcategoryorBrand() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/open/getaicategoryornrand', params);
  }
};
export default ConfigCheck;