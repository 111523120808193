// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var ChecksettlementApi = {
  // 鉴定结算列表
  getList: function getList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checksettlement/list', params);
  },
  // 鉴定结算详情
  getDetail: function getDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checksettlement/findOne', params);
  },
  // 批量鉴定信息修改
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/checksettlement/update', params);
  },
  // 统计配置
  listcount: function listcount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checksettlement/listcount', params);
  }
};
export default ChecksettlementApi;