import { isOversea as _isOversea, getLang } from '@/utils/help';
var state = {};

// 全局计算属性
var getters = {
  isOversea: function isOversea() {
    return _isOversea();
  },
  lang: function lang() {
    return getLang();
  }
};
var mutations = {};

// 异步的action,请求工具限制请求数据不能使用vuex。
var actions = {};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};