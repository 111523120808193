import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcModule = [{
  path: '/dcModule/index',
  name: 'dcModule',
  type: 2,
  meta: {
    title: '首页模块配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcModule/");
  }
}, {
  path: '/dc-video-label',
  name: 'DcVideoLabel',
  type: 2,
  meta: {
    title: '标签配置',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dcVideoLabel/index");
  }
}, {
  path: '/dc-video-label/config',
  name: 'DcVideoLabelConfig',
  type: 2,
  meta: {
    title: '管理标签',
    breadcrumbHidden: true,
    keepAlive: false // 不需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dcVideoLabel/config");
  }
}, {
  path: '/dc-video-report',
  name: 'DcVideoReport',
  type: 2,
  meta: {
    title: '视频举报',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dcVideoReport");
  }
}, {
  path: '/sub-workorder',
  name: 'SubWorkorder',
  type: 2,
  meta: {
    title: '日志',
    breadcrumbHidden: true,
    keepAlive: false // 不需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcVideoReport/SubWorkorder");
  }
}, {
  path: '/dcAppOperationsManage/index',
  name: 'dcAppOperationsManage',
  type: 2,
  meta: {
    title: 'app运营位管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcAppOperationsManage/");
  }
}, {
  path: '/dc/quiz-activity',
  name: 'QuizActivity',
  type: 2,
  meta: {
    title: '题库配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dc/quiz-activity/index");
  }
}, {
  path: '/dc/quiz-activity/award-details',
  name: 'QuizActivityAwardDetails',
  type: 2,
  meta: {
    title: '答题活动奖励发放',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dc/quiz-activity/AwardDetails");
  }
}, {
  path: '/dc/quiz-activity/question-review',
  name: 'QuizActivityQuestionReview',
  type: 2,
  meta: {
    title: '贡献题库审核',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dc/quiz-activity/QuestionReview");
  }
}, {
  path: '/dc/comment',
  name: 'DcCommentManage',
  type: 2,
  meta: {
    title: '评论管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dc/comment");
  }
}, {
  path: '/dc/data-statistics/handling',
  name: 'Handling',
  type: 2,
  meta: {
    title: '搬运量统计',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dc/dataStatistics/handling");
  }
}, {
  path: '/dc/data-statistics/quality-standard-operation',
  name: 'QualityStandardOperation',
  type: 2,
  meta: {
    title: '质量标操作统计',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dc/dataStatistics/qualityStandardOperation");
  }
}, {
  path: '/dc/data-statistics/audit-operation',
  name: 'AuditOperation',
  type: 2,
  meta: {
    title: '审核操作统计',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dc/dataStatistics/auditOperation");
  }
}, {
  path: '/dc/search-keyword',
  name: 'SearchKeyword',
  type: 2,
  meta: {
    title: '搜索推荐词',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dc/searchKeyword");
  }
}, {
  path: '/dc/withdraw',
  name: 'DcWithdraw',
  type: 2,
  meta: {
    title: '提现管理',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dc/withdraw");
  }
}, {
  path: '/dc/transfer-record',
  name: 'transferRecord',
  type: 2,
  meta: {
    title: '转移记录列表',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dc/transferRecord");
  }
}, {
  path: '/dc/recharge-record',
  name: 'rechargeRecord',
  type: 2,
  meta: {
    title: '充值记录列表',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dc/rechargeRecord");
  }
}];
export default dcModule;