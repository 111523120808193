import { POST, GET } from "../utils/util";
var cooperateConfigApi = {
  //合作方分类列表
  getCategoryList: function getCategoryList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/partner/category/list', params);
  },
  // 合作方分类删除
  deleteCategory: function deleteCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/partner/category/delete', params);
  },
  // 合作方分类配置
  saveCategory: function saveCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/partner/category/save', params);
  },
  // 合作方列表
  getPartnerList: function getPartnerList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/partner/list', params);
  },
  // 合作方删除
  deletePartner: function deletePartner() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/partner/delete', params);
  },
  // 合作方详情
  getPartnerDetail: function getPartnerDetail(partnerId) {
    return GET("/api/accountservice/partner/".concat(partnerId, "/detail"));
  },
  // 合作方配置
  savePartner: function savePartner() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/partner/save', params);
  },
  // 合作方分类名称校验
  checkPartnerCategory: function checkPartnerCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/partner/category/check', params);
  },
  // 合作方分类批量接口
  savePartnerCategory: function savePartnerCategory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/partner/category/batch/save', params);
  }
};
export default cooperateConfigApi;