import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dchood = [{
  path: "/dchood/transactionDetails/index",
  name: "transactionDetails",
  type: 2,
  meta: {
    title: "交易记录明细",
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: false,
  component: function component() {
    return import("@/views/dchood/transactionDetails/index");
  }
}, {
  path: "/dchood/roundList/index",
  name: "transactionRoundList",
  type: 2,
  meta: {
    title: "Round列表",
    breadcrumbHidden: false
  },
  hidden: true,
  component: function component() {
    return import("@/views/dchood/roundList/index");
  }
}, {
  path: "/dchood/roundDetails/index",
  name: "transactionRoundDetails",
  type: 2,
  meta: {
    title: "Round详情",
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dchood/roundDetails/index");
  }
}, {
  path: "/dchood/roundDetail/index",
  name: "roundDetail",
  type: 2,
  meta: {
    title: "Pump明细",
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dchood/roundDetail/index");
  }
}, {
  path: "/dchood/userManagement/index",
  name: "roundDetail",
  type: 2,
  meta: {
    title: "用户管理",
    breadcrumbHidden: true,
    keepAlive: true
  },
  hidden: false,
  component: function component() {
    return import("@/views/dchood/userManagement/index");
  }
}, {
  path: "/dchood/userManagement/userDetail",
  name: "userDetail",
  type: 2,
  meta: {
    title: "用户信息",
    breadcrumbHidden: true,
    keepAlive: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dchood/userManagement/userDetail.vue");
  }
}, {
  path: "/dchood/rewardManagement/index",
  name: "rewardManagement",
  type: 2,
  meta: {
    title: "交易奖励管理",
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: false,
  component: function component() {
    return import("@/views/dchood/rewardManagement/index.vue");
  }
}, {
  path: "/dchood/pointsManagement/index",
  name: "pointsManagement",
  type: 2,
  meta: {
    title: "Points管理",
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: false,
  component: function component() {
    return import("@/views/dchood/pointsManagement/index.vue");
  }
}];
export default dchood;