import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var blacklist = [{
  path: '/blacklist/index',
  name: 'blacklist',
  type: 2,
  meta: {
    title: '黑名单管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/blacklist");
  }
}];
export default blacklist;