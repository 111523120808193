import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcorder = [{
  path: '/dcorder/index',
  name: 'DC订单',
  type: 2,
  meta: {
    title: 'DC订单',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcorderManagement/index.vue");
  }
}, {
  path: '/dcorder/detail',
  name: '查看详情',
  type: 3,
  meta: {
    title: '查看详情',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dcorderManagement/detail.vue");
  }
}];
export default dcorder;