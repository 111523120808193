// 红包雨相关
import { POST, GET } from '@/utils/util';
import { get } from 'lodash';
var roundListApi = {
  // Round列表
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('api/dcservice/fanshood/pump/cycle/list', params);
  },
  // Round详情
  detail: function detail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('api/dcservice/fanshood/pump/cycle/detail', params);
  },
  // 钱包pump详情
  walletDetail: function walletDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('api/dcservice/fanshood/pump/wallet/detail', params);
  },
  //周期枚举
  enum: function _enum() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/dcservice/fanshood/cycle/open/cycle/enum', params);
  }
};
export default roundListApi;