/**
 * rootState
 */
var state = {
  activeRouterTitle: '',
  //顶部路由名称
  routePath: '' //即将进入页面时存储路由路径
};

// 全局计算属性
var getters = {
  // store.getters.getTodoById(2)
  // (state: S, getters: any, rootState: R, rootGetters: any)
  // getTodoById: (state) => (id) => {
  // return state.todos.find(todo => todo.id === id)
  // },
};
var mutations = {
  clearState: function clearState(state) {
    state.outTypes = [];
    state.outStatus = [];
    state.suppliers = [];
    state.sizes = [];
    state.brands = [];
    state.categorys = [];
    state.wareHosts = [];
    state.couponTypes = [];
    state.couponStatus = [];
    state.base.userInfo = {};
    state.base.addRoutes = [];
    state.base.routes = [];
    state.base.permissions = [];
    state.base.menuList = [];
    state.base.pagePermissions = [];
    state.base.allMenuList = [];
  },
  setRouteTitle: function setRouteTitle(state, payload) {
    state.activeRouterTitle = payload;
  },
  setRoutePath: function setRoutePath(state, payload) {
    state.routePath = payload;
  }
};

// 异步的action,请求工具限制请求数据不能使用vuex。
var actions = {
  // store.dispatch('aiTest/getAiTestData', payload)
  // { commit, getters, state, dispatch } = store
  // async getAiTestData ({ commit, state }, params) {
  //   await AiTestApi.getAiTestData ()
  // }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};