import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var Pump = [{
  path: '/pump/index',
  name: 'PUMP控制台',
  type: 2,
  meta: {
    title: 'PUMP控制台',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/pump");
  }
}, {
  path: '/pump/tasklist',
  name: 'PUMP任务列表',
  type: 2,
  meta: {
    title: 'PUMP任务列表',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/pump/tasklist.vue");
  }
}];
export default Pump;