import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var size = [{
  path: '/size/index',
  name: '尺码管理',
  type: 2,
  meta: {
    title: '尺码管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/sizeManagement/index.vue");
  }
}, {
  path: '/size/add',
  name: 'addSize',
  type: 3,
  meta: {
    title: '新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/sizeManagement/addSize.vue");
  }
}, {
  path: '/size/view',
  name: 'viewSize',
  type: 3,
  meta: {
    title: '查看',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/sizeManagement/addSize.vue");
  }
}, {
  path: '/size/edit',
  name: 'editSize',
  type: 3,
  meta: {
    title: '编辑',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/sizeManagement/addSize.vue");
  }
}];
export default size;