import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var labelManage = [{
  path: '/labelManage/index',
  name: 'labelManage',
  type: 2,
  meta: {
    title: '资讯标签管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/labelManage/index.vue");
  }
}, {
  path: '/labels',
  name: 'labels',
  type: 2,
  meta: {
    title: '用户自定义标签管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/labels/index.vue");
  }
}, {
  path: '/statisticsCBK',
  name: 'statisticsCBK',
  type: 2,
  meta: {
    title: 'CBK发放统计',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/statisticsCBK/index.vue");
  }
}, {
  path: '/statisticsInvite',
  name: 'statisticsInvite',
  type: 2,
  meta: {
    title: '邀请好友统计',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/statisticsInvite/index.vue");
  }
}, {
  path: '/statisticsInvite/record',
  name: 'statisticsInviteRecord',
  type: 2,
  meta: {
    title: '邀请好友明细表',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/statisticsInvite/record.vue");
  }
}];
export default labelManage;