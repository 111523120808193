import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import store from "@/store";
import urlReg from "@/utils/regular";
export default {
  model: {},
  props: {},
  data: function data() {
    return {
      sexSizeType: [{
        id: 0,
        name: "男款"
      }, {
        id: 1,
        name: "女款"
      }, {
        id: 2,
        name: "男女通用"
      }, {
        id: 3,
        name: "大童"
      }, {
        id: 4,
        name: "幼童"
      }, {
        id: 5,
        name: "婴童"
      }],
      certified: require("@/assets/svgs/icon_certified.svg")
    };
  },
  computed: _objectSpread(_objectSpread({
    // 路由名称
    activeRouterTitle: function activeRouterTitle() {
      return store.state.activeRouterTitle;
    },
    // path
    routePath: function routePath() {
      return store.state.routePath;
    }
  }, mapState("base", ["userInfo",
  // 用户信息
  "permissions",
  // 按钮级别权限
  "menuList",
  // 有权限菜单
  "allMenuList",
  // 所有菜单
  "pagePermissions" // 页面级别权限
  ])), {}, {
    // 过滤列表空数据，填充 ---
    filterRecordValue: function filterRecordValue() {
      return function (val) {
        if (val === 0 || val === "0") return 0;
        if (!val) return '---';
        return val || "---";
      };
    },
    // 处理不能访问的图片
    filterCoverImg: function filterCoverImg() {
      return function (val) {
        if (val.indexOf(",") == -1) {
          return val;
        } else {
          return val.split(",")[0];
        }
      };
    },
    // order index/detail
    statusTxt: function statusTxt() {
      return function (val) {
        switch (val) {
          case 0:
            return "未付款";
          case 1:
            return "待鉴定";
          case 2:
          case 3:
            return "已完成";
          case 5:
            return "待补图";
          case 4:
          case 6:
            return "已关闭";
          case 7:
            return "鉴定中";
          case 8:
            return "复审中";
          case 9:
            return "已关闭";
          default:
            return "---";
        }
      };
    },
    // order index/detail
    typeTxt: function typeTxt() {
      return function (val) {
        switch (val) {
          case 0:
            return "普通鉴定";
          case 1:
            return "快速鉴定";
          case 2:
            return "AI鉴定";
          default:
            return "---";
        }
      };
    },
    saleOrderStatus: function saleOrderStatus() {
      return function (val) {
        switch (val) {
          case 0:
            return "待付款";
          case 1:
            return "待卖家发货";
          case 2:
            return "待平台收货";
          case 3:
            return "待鉴定";
          case 4:
            return "待平台发货";
          case 5:
            return "待收货";
          case 6:
            return "已完成";
          case 7:
            return "已关闭";
          default:
            return "---";
        }
      };
    },
    sellerType: function sellerType() {
      return function (val) {
        switch (val) {
          case 0:
            return "普通用户";
          case 1:
            return "普通卖家";
          case 2:
            return "入驻商家";
          case 3:
            return "大卖家";
          default:
            return "---";
        }
      };
    },
    saleOrderType: function saleOrderType() {
      return function (val) {
        switch (val) {
          case 1:
            return "普通订单";
          case 2:
            return "限量发售订单";
          default:
            return "---";
        }
      };
    }
  }),
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // file为上传的图片，可获取图片原始宽高
    getImgWideHigh: function getImgWideHigh(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var data = e.target.result;
          var img = new Image();
          img.src = data;
          img.onload = function () {
            resolve({
              width: img.width,
              height: img.height
            });
          };
        };
        reader.readAsDataURL(file);
      });
    },
    // 判断字符类型
    CharMode: function CharMode(iN) {
      if (iN >= 48 && iN <= 57)
        //数字
        return 1;
      if (iN >= 65 && iN <= 90)
        //大写字母
        return 2;
      if (iN >= 97 && iN <= 122)
        //小写
        return 4;else return 8; //特殊字符
    },
    // 统计字符类型
    bitTotal: function bitTotal(num) {
      var modes = 0;
      for (var i = 0; i < 4; i++) {
        if (num & 1) modes++;
        num >>>= 1;
      }
      return modes;
    },
    // 校验规则添加
    validatePriceRule: function validatePriceRule(rule, value, callback) {
      if (!value) {
        callback('请输入正确的价格');
      }
      if (isNaN(value)) {
        callback('价格必须大于0且最多2位小数');
      }
      if (value <= 0) {
        callback('价格必须大于0且最多2位小数');
      }
      if (!/(^0.[0-9]{1,2}$)|(^[1-9][0-9]*(.[0-9]{1,2})?$)/.test(value)) {
        callback('价格必须大于0且最多2位小数');
      }
      callback();
    },
    // 校验规则添加
    validateGltZeroPriceRule: function validateGltZeroPriceRule(rule, value, callback) {
      if (isNaN(value)) {
        callback('价格必须大于0且最多2位小数');
      }
      if (value < 0) {
        callback('价格必须大于0且最多2位小数');
      }
      if (!/(^0(.[0-9]{1,2})?$)|(^[1-9][0-9]*(.[0-9]{1,2})?$)/.test(value)) {
        callback('价格必须大于0且最多2位小数');
      }
      callback();
    }
  }
};