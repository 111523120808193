import { POST, GET } from '@/utils/util';
var activityManageApi = {
  // 活动列表
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/attendance/activity/list', params);
  },
  // 活动详情
  detail: function detail() {
    var activityId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return GET("/api/accountservice/attendance/activity/".concat(activityId, "/detail"));
  },
  // 删除活动
  delete: function _delete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/attendance/activity/delete', params);
  },
  // 编辑|新增活动
  save: function save() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/attendance/activity/save', params);
  }
};
export default activityManageApi;