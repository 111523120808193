import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import i18n from '@/lang/index';
var welcome = [{
  path: '/welcome/index',
  name: 'welcome',
  type: 2,
  meta: {
    title: i18n.tc('welcome.pageTitle'),
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/welcomePage");
  }
}];
export default welcome;