import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 消费明细路由配置
 */

var consumptionDetail = [{
  path: '/consumptionDetail/index',
  name: 'consumptionDetail',
  type: 2,
  meta: {
    title: 'consumptionDetail',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/consumptionDetail/index.vue");
  }
}];
export default consumptionDetail;