import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var IM = [{
  path: '/im/index',
  name: '群聊列表',
  type: 2,
  meta: {
    title: '群聊列表',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM");
  }
}, {
  path: '/im/detail',
  name: '群详情',
  type: 3,
  meta: {
    title: '群详情',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/detail");
  }
}, {
  path: '/im/sign',
  name: 'AI Bot问答',
  type: 2,
  meta: {
    title: 'AI Bot问答',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/sign");
  }
}, {
  path: '/im/recommend',
  name: '群推荐-分类管理',
  type: 2,
  meta: {
    title: '群推荐-分类管理',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/recommend");
  }
}, {
  path: '/im/addRecommend',
  name: '群推荐-新建分类',
  type: 3,
  meta: {
    title: '群推荐-新建分类',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/addRecommend");
  }
}, {
  path: '/im/editRecommend',
  name: '群推荐-管理分类',
  type: 3,
  meta: {
    title: '群推荐-管理分类',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/addRecommend");
  }
}, {
  path: '/im/viewRecommend',
  name: '群推荐-查看分类',
  type: 3,
  meta: {
    title: '群推荐-查看分类',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/addRecommend");
  }
}, {
  path: '/im/aiBot',
  name: 'AI Bot问答',
  type: 2,
  meta: {
    title: 'AI Bot问答',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/aiBot");
  }
}, {
  path: '/im/reportUser',
  name: '举报管理-用户举报',
  type: 2,
  meta: {
    title: '举报管理-用户举报',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/report/user");
  }
}, {
  path: '/im/reportChannel',
  name: '举报管理-群聊举报',
  type: 2,
  meta: {
    title: '举报管理-群聊举报',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/report/channel");
  }
}, {
  path: '/im/feedBack',
  name: '反馈意见',
  type: 2,
  meta: {
    title: '反馈意见',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/feedBack/index");
  }
}, {
  path: '/im/feedBack/detail',
  name: '反馈意见详情',
  type: 2,
  meta: {
    title: '反馈意见详情',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/feedBack/detail");
  }
}, {
  path: '/im/feedBack/reply',
  name: '反馈意见回复',
  type: 2,
  meta: {
    title: '反馈意见回复',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/IM/feedBack/detail");
  }
}];
export default IM;