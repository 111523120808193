// 账号管理页面的所有接口
import {
// POST,
GET } from '@/utils/util';
var FianceAccountApi = {
  // 账户余额列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/balance/list', params);
  },
  // 账户余额下拉筛选
  findOption: function findOption() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/balance/option', params);
  },
  // 鉴定币余额列表
  checkPage: function checkPage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/check/list', params);
  },
  // 鉴定币余额下拉筛选
  checkOption: function checkOption() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/check/option', params);
  },
  // 商家结算列表
  settlementList: function settlementList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/settlement/list', params);
  },
  // 商家结算--明细列表
  detailList: function detailList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/settlement/detail/list', params);
  },
  //商家结算--查看明细列表表头数据
  tabCount: function tabCount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/settlement/detail/table/count', params);
  },
  //商家结算--明细查看头部总金额
  detailTotal: function detailTotal() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/account/settlement/detail/total', params);
  },
  //商家结算--查看明细列表表头数据
  depositList: function depositList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/business/deposit/list', params);
  }
};
export default FianceAccountApi;