// 红包雨相关
import { POST, GET } from '@/utils/util';
var RainApi = {
  // 红包雨活动列表
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/token/rain/list', params);
  },
  // 创建红包雨活动
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/token/rain', params);
  },
  // 修改红包雨活动
  update: function update(taskId) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return POST("/api/communityservice/token/rain/".concat(taskId, "/update"), params);
  },
  // 修改红包雨活动状态
  updateStatus: function updateStatus(taskId) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return POST("/api/communityservice/token/rain/".concat(taskId, "/update/status"), params);
  },
  // 红包雨活动详情
  find: function find(taskId) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return GET("/api/communityservice/token/rain/".concat(taskId), params);
  },
  // 红包雨发放记录
  logList: function logList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/token/rain/log/list", params);
  }
};
export default RainApi;