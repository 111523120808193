var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", _vm._g(_vm._b({
    staticStyle: {
      padding: "0 10px"
    },
    attrs: {
      visible: _vm.comVisible,
      width: _vm.modalWidth,
      centered: _vm.isCentered,
      maskClosable: _vm.isMaskClosable,
      closable: _vm.isClosable,
      destroyOnClose: _vm.isDestroyOnClose,
      footer: null
    }
  }, "a-modal", _vm.$attrs, false), _vm.$listeners), [_vm.title ? _c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c("span", {
    staticClass: "content"
  }, [_vm._t("form")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };