import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { importAll, getLang } from '@/utils/help';
var enLocale = importAll(require.context('@/lang/en', false, /\.js$/), '.js');
var zhLocale = importAll(require.context('@/lang/zh', false, /\.js$/), '.js');
Vue.use(VueI18n);
var messages = {
  en: _objectSpread({}, enLocale),
  zh: _objectSpread({}, zhLocale)
};
var i18n = new VueI18n({
  locale: getLang(),
  messages: messages
});
export default i18n;