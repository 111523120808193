import { POST, GET } from '@/utils/util';
var knBannerApi = {
  addAndUpdateKnBanner: function addAndUpdateKnBanner() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    /**
     * 新增和更新
     * 参数 {
     *            bannerid
     *            bannertitle
     *            imageurl
     *            linkurl
     *            effectstatus
     *            language //先不做  默认传英文吧
     *      }
     */
    return POST('/api/communityservice/kn/media/banner/save', params);
  },
  //删除
  deleteKnBanner: function deleteKnBanner() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/kn/media/banner/delete', params);
  },
  //查询列表

  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/kn/media/banner/list', params);
  },
  //详情

  detail: function detail(bannerid) {
    return GET("/api/communityservice/kn/media/banner/detail/".concat(bannerid));
  },
  // 排序
  sort: function sort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/kn/media/banner/sort', params);
  }
};
export default knBannerApi;