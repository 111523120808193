import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// 账号管理页面的所有接口
import { POST, GET, MD5, downloadPost } from '@/utils/util';
var SysAccountApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000032
  // 平台角色列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/role/findpage', params);
  },
  // 添加角色
  roleSave: function roleSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/role/save', params);
  },
  // 更新角色状态 0启用 1停用 2删除
  updatestatus: function updatestatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/role/updatestatus', params);
  },
  // 获取公司和部门信息
  getcompanyanddepartment: function getcompanyanddepartment() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/adminuser/getcompanyanddepartment', params);
  },
  // 获取角色名称
  getrolelist: function getrolelist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/role/getrolelist', params);
  },
  // 运营后台账户管理列表
  adminuserFindpage: function adminuserFindpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/adminuser/findpage', params);
  },
  // 添加运营管理账户
  adminuserSave: function adminuserSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _ref = params || {},
      password = _ref.password;
    var filterParams = _objectSpread(_objectSpread({}, params), {}, {
      password: password && MD5(password) || ''
    });
    if (!filterParams.password) delete filterParams.password;
    return POST('/api/accountservice/adminuser/save', filterParams);
  },
  // 查询运营账户详情
  adminuserDetails: function adminuserDetails() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/adminuser/details', params);
  },
  // 更新运营账户信息
  adminuserUpdate: function adminuserUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _ref2 = params || {},
      password = _ref2.password;
    var filterParams = _objectSpread(_objectSpread({}, params), {}, {
      password: password && MD5(password) || ''
    });
    if (!filterParams.password) delete filterParams.password;
    return POST('/api/accountservice/adminuser/update', filterParams);
  },
  // 更新运营账户状态
  adminuserUpdateStatus: function adminuserUpdateStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/adminuser/updatestatus', params);
  },
  // 运营账户重置密码
  adminuserUpdatePwd: function adminuserUpdatePwd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _ref3 = params || {},
      password = _ref3.password,
      oldpassword = _ref3.oldpassword;
    var filterParams = _objectSpread(_objectSpread({}, params), {}, {
      password: password && MD5(password) || '',
      oldpassword: oldpassword && MD5(oldpassword) || ''
    });
    if (!filterParams.password) delete filterParams.password;
    if (!filterParams.oldpassword) delete filterParams.oldpassword;
    return POST('/api/accountservice/adminuser/updatepwd', filterParams);
  },
  // 用户管理列表
  userFindpage: function userFindpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/findpage', params);
  },
  // 更新用户状态
  upUserFindpage: function upUserFindpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/updatestatus', params);
  },
  // 角色权限查询
  permissionsDetail: function permissionsDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/role/findone', params);
  },
  // 角色权限更新
  permissionsUpdate: function permissionsUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/role/update', params);
  },
  // 用户信息页详情
  getUserInfo: function getUserInfo(uid) {
    return GET("/api/accountservice/user/".concat(uid, "/detail"));
  },
  // 用户邀请列表
  getUserInvites: function getUserInvites() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/accountservice/user/invites", params);
  },
  // 下载
  downloadUserInvites: function downloadUserInvites() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return downloadPost(process.env.VUE_APP_DOMAIN + "//api/accountservice/user/invites/export", params);
  },
  // CBK发放状态处理
  toggleCBKGrant: function toggleCBKGrant() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/update/cbk/grant', params);
  },
  // 用户CBK余额
  getUserCBKBalance: function getUserCBKBalance(uid) {
    return GET("/api/accountservice/user/".concat(uid, "/cbk/stat"));
  },
  // CBK冻结
  freezeCBK: function freezeCBK() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userbrickslog/freeze', params);
  },
  // CBK解冻
  unfreezeCBK: function unfreezeCBK() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userbrickslog/unfreeze', params);
  },
  // 获取用户备注信息
  getUserNotes: function getUserNotes() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/notes', params);
  },
  // 用户备注添加
  addUserNotes: function addUserNotes() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/notes/add', params);
  },
  // 用户服务/平台用户/【管理后台】用户备注历史记录.md
  getUserNotesHistory: function getUserNotesHistory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/notes/history', params);
  },
  // 用户标签列表
  tagList: function tagList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/tag/list', params);
  },
  // 用户标签删除
  tagDel: function tagDel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/tag/delete', params);
  },
  // 标签新增/编辑
  tagSave: function tagSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/tag/save', params);
  },
  // 用户标签列表
  userTagList: function userTagList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/tag/list', params);
  },
  // 用户打标签
  userTagSync: function userTagSync() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/tag/add', params);
  },
  // 邀请好友统计
  userInvitesRank: function userInvitesRank() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/invites/rank', params);
  },
  // 用户操作历史记录
  userOperationHistory: function userOperationHistory() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/operation/history', params);
  },
  // CBK发放统计
  cbkGrantRank: function cbkGrantRank() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userbrickslog/grant/rank', params);
  },
  // 用户各项统计
  detailCount: function detailCount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/user/detailCount', params);
  },
  // 用户各项统计
  followerCount: function followerCount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/user/followerCount', params);
  }
};
export default SysAccountApi;