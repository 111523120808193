// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var MenuApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000069
  // 菜单列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/sysmenu/findpage', params);
  },
  // 添加菜单
  sysmenuSave: function sysmenuSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/sysmenu/save', params);
  },
  // 修改菜单
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/sysmenu/update', params);
  },
  // 菜单详情
  findone: function findone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/sysmenu/findone', params);
  },
  // 删除菜单
  updatestatus: function updatestatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/sysmenu/updatestatus', params);
  }
};
export default MenuApi;