import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var version = [{
  path: '/version/index',
  name: '版本更新',
  type: 2,
  meta: {
    title: '版本更新',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/versionManagement");
  }
}, {
  path: '/menu/index',
  name: '菜单管理',
  type: 2,
  meta: {
    title: '菜单管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/menuManagement");
  }
}];
export default version;