// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var BusinessauditApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000647
  // 查询列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/business/audit/list', params);
  },
  // 商家审核状态下拉
  selectList: function selectList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/business/audit/select', params);
  },
  // 商家审核-结果及邮件发送
  resultEdit: function resultEdit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/phpservice/admin/business/audit/resultEdit', params);
  }
};
export default BusinessauditApi;