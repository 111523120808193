import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var configCheck = [
//鉴定配置
{
  path: '/configCheck/index',
  name: '鉴定配置',
  type: 3,
  meta: {
    title: '鉴定配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/configCheck/index.vue");
  }
}, {
  path: '/configCheck/edit',
  name: '编辑',
  type: 3,
  meta: {
    title: '编辑',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/configCheck/components/checkAdd.vue");
  }
}];
export default configCheck;