import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var newsletter = [
// DC-NewsLetter管理
{
  path: '/newsletter/index',
  name: 'newsletter',
  type: 2,
  meta: {
    title: '新闻文章配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/newsletter/index.vue");
  }
}];
export default newsletter;