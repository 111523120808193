import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcMark = [{
  path: '/dcMark/index',
  name: 'dcMark',
  type: 2,
  meta: {
    title: '视频标注',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dcMark/");
  }
}];
export default dcMark;