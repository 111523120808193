import _slicedToArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import { uploadImg, uploadLaunchpadImg } from "@/api/common";
import Mixin from "@/utils/mixin";
import { ByteConvert } from "@/utils/help";
function getBase64(img, callback) {
  var reader = new FileReader();
  reader.addEventListener("load", function () {
    return callback(reader.result.split(",")[1]);
  });
  reader.readAsDataURL(img);
}
export default {
  props: {
    /**
     * 类名 classname 用于重置样式
     */
    classname: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * 接受上传的文件类型
     */
    accept: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * 上传列表的内建样式，支持三种基本样式 text, picture 和 picture-card
     */
    listType: {
      type: String,
      required: false,
      default: "text"
    },
    /**
     * 是否展示 uploadList, 可设为一个对象，用于单独设定 showPreviewIcon 和 showRemoveIcon
     */
    showUploadList: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * 接受一个数组，用于上传列表渲染
     */
    value: {
      type: [Array, String],
      required: false,
      default: function _default() {
        return [];
      }
    },
    /**
     * 最大上传数量
     */
    max: {
      type: Number,
      required: false,
      default: 1
    },
    /**
     * 如果value 为String，并用符号分割，可以用此属性做处理。
     */
    symbol: {
      type: String,
      required: false,
      default: undefined
    },
    // 禁止上传
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // 限制上传图片大小
    memorySize: {
      type: String | Number,
      default: function _default() {
        return '';
      }
    },
    // 图片像素比例
    imgProportion: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否展示提示
    showTips: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    // 是否展示上传按钮
    showUpIcon: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    force: {
      // false时不校验图片尺寸
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    equalSides: {
      // 是否要求等边
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    from: {
      // 图片来源,lauchpad时调用launchpad那边的接口
      type: String,
      default: 'normal'
    }
  },
  data: function data() {
    return {
      fileList: []
    };
  },
  mixins: [Mixin],
  computed: {
    imgSize: function imgSize() {
      return this.memorySize && this.memorySize.split('kb')[0];
    }
  },
  mounted: function mounted() {
    this.init();
  },
  watch: {
    value: function value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.init();
      }
    }
  },
  methods: {
    ByteConvert: ByteConvert,
    previewImg: function previewImg(file) {
      this.$emit("previewImg", file.url);
    },
    init: function init() {
      var value = this.value,
        symbol = this.symbol;
      // 处理接受数据和分发
      if (Array.isArray(value)) {
        this.initFileList(value);
      } else if (typeof value === "string") {
        if (symbol) {
          this.initFileList(value.split(symbol));
        } else if (value.indexOf(",") > -1) {
          this.initFileList(value.split(","));
        } else {
          // value 是string类型，没有Symbol处理时，为单张图片
          this.fileList = [];
          if (value) {
            this.initFileList([value]);
          }
        }
      }
    },
    initFileList: function initFileList(arr) {
      var _this = this;
      arr.forEach(function (item, index) {
        var temp = {
          uid: index,
          status: "done",
          name: item,
          url: item
        };
        _this.fileList.push(temp);
      });
    },
    beforeUpload: function beforeUpload(file) {
      var _this2 = this;
      //类型
      if (this.accept.indexOf(file.type) == -1) {
        this.toast("请上传正确的类型", "error");
        return false;
      }
      var IsPic = file.type.indexOf("image/") != -1;
      if (!IsPic) {
        this.toast("请上传图片", "error");
        return false;
      }
      if (this.fileList.length >= this.max) {
        this.toast("你已达到最大上传数量", "error");
        return false;
      }
      if (this.imgSize && file.size / 1024 > this.imgSize) {
        // 判断上传图片是否超出大小
        this.toast("\u5F53\u524D\u56FE\u7247".concat(this.percentNum(file.size, 1024), "kb,\u8D85\u8FC7\u6700\u5927\u9650\u5236").concat(this.imgSize, "kb"), "error");
        return false;
      }
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var data = e.target.result;
          var img = new Image();
          img.src = data;
          img.onload = function () {
            console.log('imgimgimg', img.width, img.height);
            var width = img.width,
              height = img.height; //当前图片宽高
            var _this2$imgProportion = _slicedToArray(_this2.imgProportion, 2),
              targetWidth = _this2$imgProportion[0],
              targetHeight = _this2$imgProportion[1]; //目标宽高
            if (_this2.equalSides && width != height) {
              _this2.toast("\u5F53\u524D\u56FE\u7247\u957F\u4E3A".concat(width, ",\u5BBD\u4E3A").concat(height, "\uFF0C\u5FC5\u987B\u4E3A\u6B63\u65B9\u5F62"), "error");
              return false;
            }
            if (!_this2.force || !_this2.imgProportion.length) {
              _this2.fileList.push(file);
              resolve();
              return;
            }
            if (targetWidth != "" && targetHeight != "") {
              if (width != targetWidth || height != targetHeight) {
                _this2.toast("\u5F53\u524D\u56FE\u7247\u957F\u4E3A".concat(width, ",\u5BBD\u4E3A").concat(height, "\uFF0C\u8BF7\u4E0A\u4F20\u957F\u4E3A").concat(targetWidth, "\uFF0C\u5BBD\u4E3A").concat(targetHeight, "\u7684\u56FE\u7247"), "error");
                return false;
              }
            }
            if (targetWidth == "") {
              if (height != targetHeight) {
                _this2.toast("\u5F53\u524D\u56FE\u7247\u957F\u4E3A".concat(width, ",\u5BBD\u4E3A").concat(height, "\uFF0C\u8BF7\u4E0A\u4F20\u957F\u4E3A\u4E0D\u9650\uFF0C\u5BBD\u4E3A").concat(targetHeight, "\u7684\u56FE\u7247"), "error");
                return false;
              }
            }
            if (targetHeight == "") {
              if (width != targetWidth) {
                _this2.toast("\u5F53\u524D\u56FE\u7247\u957F\u4E3A".concat(width, ",\u5BBD\u4E3A").concat(height, "\uFF0C\u8BF7\u4E0A\u4F20\u957F\u4E3A").concat(targetWidth, "\uFF0C\u5BBD\u4E3A\u4E0D\u9650\u7684\u56FE\u7247"), "error");
                return false;
              }
            }
            file.status = "uploading";
            console.log('this.fileList', _this2.fileList);
            _this2.fileList.push(file);
            resolve();
          };
        };
        reader.readAsDataURL(file);
      });
      // 上传之前
    },
    percentNum: function percentNum(denominator, molecular) {
      return parseFloat((denominator / molecular).toFixed(1)); //小数点后两位百分比
    },
    handleChange: function handleChange(info) {
      // 上传状态修改回调
      console.log('info', info);
      if (info.file.status === "removed") {
        this.fileList = info.fileList;
        this.deliveryData();
      }
      if (info.file.status === "done") {
        this.deliveryData();
      } else if (info.file.status === "error") {
        window.console.error("上传失败");
      }
    },
    deliveryData: function deliveryData() {
      var imgList = [];
      this.fileList.forEach(function (item) {
        imgList.push(item.url);
      });
      this.fileList = [];
      /**
       * 如果按字符串处理 则按字符串返回
       */
      if (this.symbol && this.max > 1) {
        imgList = imgList.join(this.symbol);
      }

      /**
       * 返回一个组数
       */
      if (this.max === 1) {
        this.$emit("change", imgList[0]);
        // 一张图片的时候 添加双向绑定V-model
        this.$emit("input", imgList[0]);
      } else {
        this.$emit("input", imgList);
        this.$emit("change", imgList);
      }
    },
    handleUpload: function handleUpload(data) {
      var _data$file = data.file,
        uid = _data$file.uid,
        name = _data$file.name,
        status = _data$file.status;
      var _ = this;
      var temp = {
        uid: uid,
        name: name,
        status: status
      };
      // 图片转base64
      var formData = new FormData();
      formData.append("file", data.file);
      // 上传
      var method = this.from == 'launchpad' ? uploadLaunchpadImg : uploadImg;
      method(formData).then(function (res) {
        _.toast("上传成功", "success");
        temp.status = "done";
        temp.url = res;
        _.fileList.splice(_.fileList.length - 1, 1, temp);
        _.handleChange({
          file: temp
        });
      }).catch(function () {
        _.toast("上传失败", "error");
        temp.status = "error";
        _.fileList.splice(_.fileList.length - 1, 1);
        _.handleChange({
          file: temp
        });
      });
    }
  }
};