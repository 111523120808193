// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var groupPersonalManagement = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000065
  // 版本更新管理列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/channelgrade/user/searchlist', params);
  },
  // 版本信息 详情
  findone: function findone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/versionconfig/findone', params);
  },
  // 版本信息状态更新
  updateStatus: function updateStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/versionconfig/updatestatus', params);
  },
  //编辑
  edit: function edit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/channelgrade/user/edit', params);
  },
  // 添加个人更新信息
  personAdd: function personAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/channelgrade/user/add', params);
  },
  groupDelete: function groupDelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/channelgrade/user/delete', params);
  }
};
export default groupPersonalManagement;