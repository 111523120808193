import { POST, GET } from "../utils/util";
var DashboardApi = {
  //CBK发放分日总计
  brickLogCountByTimeAndType: function brickLogCountByTimeAndType() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/brickservice/cbk/brickLogCountByTimeAndType', params);
  },
  //获取CBK渠道接口
  getEventTypeList: function getEventTypeList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/brickservice/cbk/getEventTypeList', params);
  },
  //统计CBK有效发放
  getCbkCount: function getCbkCount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/brickservice/cbk/cbkCount', params);
  },
  //统计全平台CBK
  getBrickCount: function getBrickCount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/brickservice/cbk/platformAllBrickCount', params);
  }

  // //全平台发放CBK含冻结
  // platformAllBrickNum: (params = {}) => {
  //     return GET('/api/brickservice/cbk/platformAllBrickNum', params)
  // },
  // //全平台可以CBK不含冻结
  // platformAvailableNum: (params = {}) => {
  //     return GET('/api/brickservice/cbk/platformAvailableNum', params)
  // },
  // //全平台冻结CBK
  // platformFreezeNum: (params = {}) => {
  //     return GET('/api/brickservice/cbk/platformFreezeNum', params)
  // },
  // //最近7天发放CBK
  // sevenDaysCbk: (params = {}) => {
  //     return GET('/api/brickservice/cbk/sevenDaysCbk', params)
  // },
  // //最近30天发放CBK
  // thirtyDaysCbk: (params = {}) => {
  //     return GET('/api/brickservice/cbk/thirtyDaysCbk', params)
  // },
  // //今日发放CBK
  // todayCbk: (params = {}) => {
  //     return GET('/api/brickservice/cbk/todayCbk', params)
  // },
  // //昨日发放CBK
  // yesterdayCbk: (params = {}) => {
  //     return GET('/api/brickservice/cbk/yesterdayCbk', params)
  // },
};

export default DashboardApi;