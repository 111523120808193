import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var launchpad = [{
  path: '/launchpad/index',
  name: 'launchpad',
  type: 2,
  meta: {
    title: 'Launchpad',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/launchpad/index.vue");
  }
}, {
  path: '/launchpad/add',
  name: 'addLaunchpad',
  type: 3,
  meta: {
    title: '新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/launchpad/add.vue");
  }
}, {
  path: '/launchpad/edit',
  name: 'editLaunchpad',
  type: 3,
  meta: {
    title: '编辑',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/launchpad/add.vue");
  }
}, {
  path: '/launchpad/view',
  name: 'viewLaunchpad',
  type: 3,
  meta: {
    title: '查看',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/launchpad/add.vue");
  }
}];
export default launchpad;