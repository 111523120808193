import { POST, GET } from '@/utils/util';
var tradePreRecommendApi = {
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/fanshood/calendar/recommend/list', params);
  },
  videoDetail: function videoDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/video/findone', params);
  },
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/fanshood/calendar/recommend/save', params);
  },
  edit: function edit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/fanshood/calendar/recommend/save', params);
  },
  del: function del() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/fanshood/calendar/recommend/delete', params);
  },
  //List user
  userList: function userList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/fanshood/presale/list', params);
  }
};
export default tradePreRecommendApi;