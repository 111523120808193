import Api from "./login/common";
import OrderApi from "./orderApi";
import CheckerApi from "./checkerApi";
import BannerApi from "./bannerApi";
import VideoApi from "./videoApi";
import SysAccountApi from "./sysAccountApi";
import VersionApi from "./versionApi";
import MenuApi from "./menuApi";
import BrandApi from "./brandApi";
import CategoryApi from "./categoryApi";
import HomeApi from "./homepageApi";
import SizeApi from "./sizeApi";
import SeriesApi from "./seriesApi";
import CommodityApi from "./commodity";
import LimitedApi from "./limitedApi";
import LogisticApi from "./logisticApi";
import YoumaylikeApi from "./youmaylikeApi";
import HotWordApi from "./hotword";
import saleOrderApi from "./saleOrder";
import ConfigbannerApi from "./configbanner";
import BusinessApi from "./business";
import BusinessauditApi from "./businessauditApi";
import ConfigurationApi from "./generalconfiguration";
import FianceAccountApi from "./fianceAccount";
import AfterSaleOrderApi from "./afterSaleOrder";
import SplashApi from "./splashApi";
import IdentificationCurrencyApi from "./identificationCurrency";
import ConfigCheck from "./configCheck";
import ChecksettlementApi from "./checksettlement";
import MerchantsLevelApi from "./merchantslevel";
import AutoBiddingApi from "./autoBidding";
import AppConfigApi from "./appConfig";
import LotteryApi from "./lotteryApi";
import AwardsApi from "./awardsApi";
import DcListApi from "./dcList";
import WhitelistApi from "./whitelist";
import DcOrderApi from "./dcOrder";
import LaunchpadApi from "./launchpad";
import DcBannerApi from "./dcBanner";
import DcModuleApi from "./dcModule";
import DcMediaApi from "./dcMedia";
import CategoryManageApi from "./categoryManage";
import DcVideoApi from "./dcVideo";
import DcVideoTagApi from "./dcVideoTag";
import bricksconfigApi from "./bricksconfigApi";
import DcFeaturesApi from "./dcFeatures";
import DcLogApi from "./dclog";
import DcToolApi from "./dcTool";
import CourseManageApi from "./courseManage";
import BlindBoxApi from "./blindBoxApi";
import AuthenticationApi from "./authentication";
import RainApi from "./rainApi";
import NewsLetterApi from "./newsletter";
import UserequipmentApi from "./userequipment";
import BlacklistApi from "./blacklist";
import Topic from "./topic";
import BcManageApi from "./bcManageApi";
import IMApi from "./IM";
import aiVideoApi from "./aiVideo";
import squareApi from "./square";
import DashboardApi from "./dashboard";
import contentStatisticsApi from "./contentStatisticsApi";
import liveTagApi from "./liveTagApi";
import greetingApi from "./greetingApi";
import cooperateConfigApi from "./cooperateConfigApi";
import salecalendarApi from "./salecalendarApi";
import activityManageApi from "./activityManage";
// 群管理接口
import groupManagementApi from "./groupManagement";
import groupPersonalManagementApi from "./groupPersonalManagement";
import AmbianceTeamApi from "./ambianceTeam";
import KnBannerApi from "./knBanner";
import tradePreRecommendApi from "./tradePreRecommend";
import DcHoodManageApi from './dchoodmanage';
import tradingGroup from "./tradingGroup";
import roundListApi from './roundListApi';
import effectPowerApi from './effectPower';
import saleCalendarHomeApi from './saleCalendarHome';
import PumpApi from './pump';
// 充值记录
import RechargeApi from "./rechargeApi";
// 消费明细
import ConsumptionApi from "./consumptionApi";
// 收益明细
import RevenueApi from "./revenueApi";
// {{apiImport}}

// 引入并导出所API
export { Api, OrderApi, CheckerApi, BannerApi, VideoApi, SysAccountApi, VersionApi, MenuApi, BrandApi, CategoryApi, HomeApi, SizeApi, SeriesApi, CommodityApi, LimitedApi, LogisticApi, YoumaylikeApi, HotWordApi, saleOrderApi, ConfigbannerApi, BusinessApi, SplashApi, ConfigurationApi, AfterSaleOrderApi, IdentificationCurrencyApi, FianceAccountApi, ConfigCheck, ChecksettlementApi, MerchantsLevelApi, BusinessauditApi, AutoBiddingApi, AppConfigApi, LotteryApi, AwardsApi, DcListApi, WhitelistApi, DcOrderApi, LaunchpadApi, DcBannerApi, DcModuleApi, DcMediaApi, CategoryManageApi, DcVideoApi, DcVideoTagApi, bricksconfigApi, DcFeaturesApi, DcLogApi, DcToolApi, CourseManageApi, BlindBoxApi, AuthenticationApi, RainApi, NewsLetterApi, UserequipmentApi, BlacklistApi, Topic, BcManageApi, IMApi, aiVideoApi, squareApi, DashboardApi, contentStatisticsApi, liveTagApi, greetingApi, activityManageApi, groupManagementApi, groupPersonalManagementApi, AmbianceTeamApi, cooperateConfigApi, KnBannerApi, salecalendarApi, tradePreRecommendApi, DcHoodManageApi, tradingGroup, roundListApi, effectPowerApi, saleCalendarHomeApi, PumpApi, RechargeApi, ConsumptionApi, RevenueApi
// {{apiExport}}
};