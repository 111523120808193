import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var tradePreRecommend = [{
  path: '/tradeprerecommend',
  name: 'DC-Hood交易模块',
  type: 2,
  meta: {
    title: '交易首页预发推荐',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/tradePreRecommend");
  }
}];
export default tradePreRecommend;