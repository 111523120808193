// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var SplashApi = {
  // 闪屏列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/splash/getall', params);
  },
  // 删除闪屏列表
  splashDelete: function splashDelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/splash/delete', params);
  },
  // 新增
  splashSave: function splashSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/splash/save', params);
  },
  // 更新
  splashUpdate: function splashUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/splash/update', params);
  },
  // 排序
  updatesort: function updatesort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/splash/updatesort', params);
  },
  //详情
  splashDetail: function splashDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/splash/detail', params);
  }
};
export default SplashApi;