// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var BannerApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000051
  // banner列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/banner/findpage', params);
  },
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000055
  // 删除 banner
  bannerDelete: function bannerDelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/banner/delete', params);
  },
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000053
  // 添加 banner
  bannerSave: function bannerSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/banner/save', params);
  },
  // 更新 banner
  bannerUpdate: function bannerUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/banner/update', params);
  },
  // 结束 banner
  endbanner: function endbanner() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/banner/endbanner', params);
  },
  // banner 详情
  bannerDetail: function bannerDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/banner/findone', params);
  },
  // banner 排序
  updatesort: function updatesort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/banner/updatesort', params);
  },
  // banner tab数量
  gettabcount: function gettabcount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/banner/gettabcount', params);
  }
};
export default BannerApi;