import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var depositMoney = [
//通用配置
{
  path: '/depositMoney/index',
  name: '保证金',
  type: 1,
  meta: {
    title: '保证金',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/depositMoney");
  }
}];
export default depositMoney;