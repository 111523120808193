var state = {
  // 标题类型
  types: [{
    id: 0,
    name: '全部',
    title: '',
    fixed: true
  }, {
    id: 1,
    name: '英文',
    title: '英文',
    fixed: false
  }, {
    id: 2,
    name: '繁体中文',
    title: '繁体中文',
    fixed: false
  }
  //   {id: 3, name: 'Launchpad类配置', title: 'Launchpad类', fixed: false},
  ],

  language: [{
    name: '英文',
    id: "0"
  }, {
    name: '繁体中文',
    id: "1"
  }]
};

// 全局计算属性
var getters = {};
var mutations = {};

// 异步的action,请求工具限制请求数据不能使用vuex。
var actions = {};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};