var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "custom-dialog"
  }, [_c("div", {
    staticClass: "dialog-detail"
  }, [_c("div", {
    staticClass: "top-view"
  }, [_vm.title ? _c("span", {
    staticClass: "title",
    domProps: {
      textContent: _vm._s(_vm.title)
    }
  }) : [_vm.message ? _c("span", {
    staticClass: "message",
    domProps: {
      textContent: _vm._s(_vm.message)
    }
  }) : _vm._e()]], 2), _vm.single ? _c("div", {
    staticClass: "bottom-view-single"
  }, [_c("span", {
    staticClass: "left",
    domProps: {
      textContent: _vm._s(_vm.cancelTitle)
    },
    on: {
      click: _vm.cancel
    }
  })]) : _c("div", {
    staticClass: "bottom-view"
  }, [_c("span", {
    staticClass: "left",
    domProps: {
      textContent: _vm._s(_vm.cancelTitle)
    },
    on: {
      click: _vm.cancelClick
    }
  }), _c("span", {
    staticClass: "right",
    class: _vm.configBgType == "nomal" ? "nomal" : "warning",
    domProps: {
      textContent: _vm._s(_vm.configTitle)
    },
    on: {
      click: _vm.okClick
    }
  })])])]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };