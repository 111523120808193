import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcVideoTag = [{
  path: '/dcVideoTag/index',
  name: 'dcVideoTag',
  type: 2,
  meta: {
    title: 'DC-视频管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dcVideoTag/");
  }
}];
export default dcVideoTag;