var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    staticStyle: {
      padding: "0 10px"
    },
    attrs: {
      visible: _vm.comVisible,
      width: _vm.modalWidth,
      centered: _vm.isCentered,
      maskClosable: _vm.isMaskClosable,
      closable: _vm.isClosable,
      destroyOnClose: _vm.isDestroyOnClose,
      footer: null
    }
  }, [_vm.title ? _c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.tipsDesc ? _c("span", {
    staticClass: "tips_desc",
    domProps: {
      innerHTML: _vm._s(_vm.tipsDesc)
    }
  }) : _vm._e(), _c("div", {
    staticClass: "constainer",
    staticStyle: {
      "text-align": "center",
      display: "flex",
      "flex-direction": "row",
      "justify-content": "center"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#f5f5f5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticClass: "btn btn_r",
    class: _vm.isDelete ? "del" : "",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };