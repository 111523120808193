import { POST, GET, downloadPost } from '@/utils/util';

// 可疑行为监测管理
var UserequipmentApi = {
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/userequipment/manage/findpage', params);
  },
  // 导出
  export: function _export() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    downloadPost(process.env.VUE_APP_DOMAIN + '/api/accountservice/userequipment/manage/export', params);
  }
};
export default UserequipmentApi;