import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var rain = [
// 红包雨活动相关
{
  path: '/rain/index',
  name: 'rain',
  type: 2,
  meta: {
    title: '项目方视频管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/rain/index.vue");
  }
}, {
  path: '/rain/records',
  name: 'rainRecords',
  type: 2,
  meta: {
    title: '红包雨奖励发放记录',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/rain/records.vue");
  }
}];
export default rain;