import "core-js/modules/es.number.constructor.js";
export default {
  name: "CustomTab",
  data: function data() {
    return {};
  },
  props: {
    // tab数组
    titles: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 选中的元素，默认为:index=0
    defaultIndex: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    // 点击事件
    handleClick: {
      type: Function,
      default: function _default() {}
    },
    // 是否展示数量,加上属性 count
    showNumb: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  computed: {
    // 当前选中的tab
    activeIndex: {
      get: function get() {
        return this.defaultIndex;
      },
      set: function set() {}
    },
    getCellStyle: function getCellStyle() {
      return {
        whiteSpace: "nowrap"
      };
    },
    getTitleClass: function getTitleClass() {
      var _this = this;
      return function (index) {
        return index === _this.activeIndex ? "active_title" : "nomal_title";
      };
    },
    getLineClass: function getLineClass(val) {
      var _this2 = this;
      return function (index) {
        return index === _this2.activeIndex ? "active_line" : "nomal_line";
      };
    }
  },
  watch: {
    defaultIndex: function defaultIndex(newValue, oldValue) {}
  },
  methods: {
    handleClickTab: function handleClickTab(item, index) {
      if (this.activeIndex != index) {
        this.activeIndex = index;
        this.handleClick(item, index);
      }
    }
  }
};