import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var financeCenter = [{
  path: '/MerchantSettlement/index',
  name: '商家结算',
  type: 2,
  meta: {
    title: '商家结算',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/MerchantSettlement/index");
  }
}, {
  path: '/MerchantSettlement/getInfo',
  name: '查看明细',
  type: 2,
  meta: {
    title: '查看明细',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/MerchantSettlement/getInfo");
  }
}, {
  path: '/AccountBalance/index',
  name: '账户余额',
  type: 2,
  meta: {
    title: '账户余额',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/AccountBalance/index");
  }
}, {
  path: '/appraisalCurrencyBalance/index',
  name: '鉴定币余额',
  type: 2,
  meta: {
    title: '鉴定币余额',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/appraisalCurrencyBalance/index");
  }
}];
export default financeCenter;