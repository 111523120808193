import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var configuration = [
//通用配置
{
  path: '/configuration/index',
  name: '规则配置',
  type: 1,
  meta: {
    title: '规则配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/generalconfiguration");
  }
}];
export default configuration;