import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var activityManage = [
// DC-活动管理
{
  path: '/activityManage/sign',
  name: '签到活动',
  type: 2,
  meta: {
    title: '签到活动管理',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/activityManage/sign.vue");
  }
}];
export default activityManage;