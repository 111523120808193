import "D:\\projects\\operation-management-background\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\projects\\operation-management-background\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\projects\\operation-management-background\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\projects\\operation-management-background\\node_modules\\core-js\\modules\\es.promise.finally.js";
var _this = this;
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import Vue from 'vue';
import App from './App.vue';
import store from '@/store';
import './utils/component-use';
import { GlobalFunc } from './utils/toastMixin';
import 'ant-design-vue/dist/antd.css';
import './assets/css/app.scss';
// 设置路由勾子
import './config/interceptors/router';
import router from '@/plugins/router';
// 自定义绑定勾子
import './directive';
// 全局组件
import Pagination from "@/components/Pagination"; //分页组件
import FormItems from '@/components/formItems'; // form表单
import PreviewPic from "@/components/PreviewPic"; // 图片预览
import DTab from '@/components/DTab'; // 顶部tab切换
import DPopup from '@/components/DPopup'; // 顶部tab切换
import DelUpdate from '@/components/DelUpdate';
import { FormModel, TreeSelect } from 'ant-design-vue';
import DragModal from '@/components/DragModal';
import VueDragResize from "vue-drag-resize";
import * as filters from '@/utils/filters.js';
import clickoutside from '@/utils/clickoutside';
import 'viewerjs/dist/viewer.css';
import Vuer from 'v-viewer';
Vue.use(Vuer, {
  name: 'viewer'
});
import i18n from './lang/index';
window.i18n = i18n;
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.component('vue-drag-resize', VueDragResize);
Vue.component('DragModal', DragModal);
import moment from 'moment';
Vue.use(FormModel);
Vue.use(TreeSelect);
// 全局函数
import { objToArray, sortByKey, getBase64, openOutLink, filterInvalidObj, numFormat } from './utils/help';
Vue.prototype.objToArray = objToArray;
Vue.prototype.getBase64 = getBase64;
Vue.prototype.openOutLink = openOutLink;
Vue.prototype.filterInvalidObj = filterInvalidObj;
Vue.prototype.numFormat = numFormat;
Vue.prototype.sortByKey = sortByKey;
window.GLOBAL.vbus = new Vue();
Vue.config.productionTip = false;
Vue.prototype.$isMobile = /android/g.test(navigator.userAgent.toLowerCase()) || /iphone/g.test(navigator.userAgent.toLowerCase());
import VueClipboard from 'vue-clipboard2'; //复制
Vue.use(VueClipboard);

// 全局组件挂载
Vue.component('Pagination', Pagination);
Vue.component('FormItems', FormItems);
Vue.component('PreviewPic', PreviewPic);
Vue.component('DTab', DTab);
Vue.component('DPopup', DPopup);
Vue.component('DelUpdate', DelUpdate);
Vue.filter('numberFixedAfterPoint', function (value) {
  if (!value) return '-';
  var _num = value.toFixed(3);
  return _num.substr(0, _num.length - 1);
});
Vue.filter('priceBeautify', function (value) {
  if (!value) return 0;
  var temVal = value.toString().indexOf('.') !== -1 ? value.toLocaleString() : value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  return temVal;
});
Vue.directive('preventReClick', {
  inserted: function inserted(el, binding) {
    el.addEventListener('click', function () {
      if (!el.disabled) {
        el.setAttribute('disabled', 'true');
        el.disabled = true;
        setTimeout(function () {
          el.setAttribute('disabled', 'false');
          el.removeAttribute('disabled');
          el.disabled = false;
        }, binding.value || 2000);
      }
    });
  }
});
Vue.directive('el-hover', {
  bind: function bind(el, binding) {
    var elData = binding.value;
    el.onmouseover = function () {
      if (elData && elData.callback && typeof elData.callback === 'function') {
        elData.callback(elData.val);
      }
      el.classList.add("active");
    };
    el.onmouseout = function () {
      if (elData && elData.callback && typeof elData.callback === 'function') {
        elData.callback('close');
      }
      el.classList.remove("active");
    };
    el.onclick = function () {};
  }
});
Vue.directive('clickoutside', clickoutside);
Vue.mixin(GlobalFunc);
new Vue({
  data: {
    $form: function $form() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        name: 'compontForm'
      };
      return _this.$form.createForm(_this, options);
    }
  },
  router: router,
  store: store,
  i18n: i18n,
  moment: moment,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');