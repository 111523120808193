import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 收益明细路由配置
 */

var revenueDetail = [{
  path: '/revenueDetail/index',
  name: 'revenueDetail',
  type: 2,
  meta: {
    title: 'revenueDetail',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/revenueDetail/index.vue");
  }
}];
export default revenueDetail;