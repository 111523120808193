// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var YoumaylikeApi = {
  // 新增
  addyoumaylike: function addyoumaylike() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/guesslike/guesslikesave', params);
  },
  // 删除
  delyoumaylike: function delyoumaylike() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/guesslike/delguesslike', params);
  },
  // 查询所有数据
  getlist: function getlist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/guesslike/getall', params);
  },
  // 排序或新增
  sortyoumaylike: function sortyoumaylike() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/guesslike/updateoradd', params);
  },
  // 导入文件
  fileupload: function fileupload() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/guesslike/fileUpload', params);
  },
  // 查询商品信息
  getproductdetails: function getproductdetails() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/product/getproductdetails', params);
  }
};
export default YoumaylikeApi;