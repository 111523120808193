// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var groupManagementApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000065
  // 版本更新管理列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/channelgrade/backend/searchlist', params);
  },
  // 版本信息 详情
  findone: function findone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/versionconfig/findone', params);
  },
  // 版本信息状态更新
  updateStatus: function updateStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/versionconfig/updatestatus', params);
  },
  edit: function edit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/channelgrade/backend/edit', params);
  },
  // 添加群更新信息
  personAdd: function personAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/channelgrade/backend/add', params);
  },
  // 获取群等级相应的配置
  groupLevelConfig: function groupLevelConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/imchannel/create/priceallocation', params);
  },
  //修改群等级相应的配置
  groupLevelConfigEdit: function groupLevelConfigEdit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/imchannel/config', params);
  },
  // 删除群
  groupDelete: function groupDelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/channelgrade/backend/delete', params);
  }
};
export default groupManagementApi;