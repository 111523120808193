import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcHotTopic = [{
  path: '/dcHotTopic/index',
  name: 'dcHotTopic',
  type: 2,
  meta: {
    title: '热点榜单配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dcHotTopic/");
  }
}];
export default dcHotTopic;