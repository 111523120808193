export default {
  name: "CustomDialog",
  methods: {
    cancelClick: function cancelClick() {
      this.visible = false;
      this.cancel();
    },
    okClick: function okClick() {
      this.visible = false;
      this.ok(this.inputValue);
    }
  }
};