var state = {
  // 标题类型
  types: [{
    id: 0,
    name: '运营位配置',
    title: '',
    fixed: true
  }, {
    id: 1,
    name: '通屏类配置',
    title: '通屏类',
    fixed: false
  }, {
    id: 2,
    name: 'Slogan类配置',
    title: 'Slogan类',
    fixed: false
  }, {
    id: 3,
    name: 'Launchpad类配置',
    title: 'Launchpad类',
    fixed: false
  }]
};

// 全局计算属性
var getters = {};
var mutations = {};

// 异步的action,请求工具限制请求数据不能使用vuex。
var actions = {};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};