// @/directives/index.js
import Clipboard from "clipboard";
import { message } from "ant-design-vue";
message.config({
  maxCount: 1
});
function copyText(el) {
  var clipboard = new Clipboard(el);
  clipboard.on("success", function () {
    message.success("复制成功！");
  });
  clipboard.on("error", function () {
    message.error("复制失败！");
  });
}
export default {
  inserted: function inserted(el, binding) {
    // 配置 Clipboard 属性
    el.setAttribute("data-clipboard-text", binding.value);
    // 解决第一次复制无法成功问题
    el.onmouseenter = function () {
      return copyText(el);
    };
    // 进行复制
    el.onclick = function () {
      return copyText(el);
    };
  }
};