import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var saleCalendarHome = [{
  path: '/saleCalendarHome/index',
  name: 'saleCalendarHome',
  type: 2,
  meta: {
    title: 'saleCalendarHome',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/saleCalendarHome/index.vue");
  }
}];
export default saleCalendarHome;