import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var bcModule = [{
  path: '/bc/taskManage/index',
  name: 'TaskManage',
  type: 2,
  meta: {
    title: '广告任务审核',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/bc/taskManage/index.vue");
  }
}, {
  path: '/bc/taskManage/taskDetails',
  name: 'taskDetails',
  type: 2,
  meta: {
    title: 'Task Details',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/bc/taskManage/taskDetails.vue");
  }
}, {
  path: '/bc/manuscriptManage',
  name: 'ManuscriptManage',
  type: 2,
  meta: {
    title: '任务投稿视频审核',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/bc/manuscriptManage");
  }
}, {
  path: '/bc/evaluateManage',
  name: 'EvaluateManage',
  type: 2,
  meta: {
    title: '创作者评分列表',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/bc/evaluateManage");
  }
}, {
  path: '/bc/orderManage',
  name: 'OrderManage',
  type: 2,
  meta: {
    title: '数据服务支付记录',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/bc/orderManage");
  }
}];
export default bcModule;