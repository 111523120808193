import vue from 'vue';

// 这里就是我们刚刚创建的那个静态组件
import customDialog from './customDialog.vue';
var DialogConstructor = vue.extend(customDialog);

// 定义弹出组件的函数 接收2个参数, 要显示的文本 和 显示时间, configType,确定按钮背景颜色
var showDialog = function showDialog() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$title = _ref.title,
    title = _ref$title === void 0 ? '' : _ref$title,
    _ref$message = _ref.message,
    message = _ref$message === void 0 ? '' : _ref$message,
    _ref$cancelTitle = _ref.cancelTitle,
    cancelTitle = _ref$cancelTitle === void 0 ? '取消' : _ref$cancelTitle,
    _ref$configTitle = _ref.configTitle,
    configTitle = _ref$configTitle === void 0 ? '确定' : _ref$configTitle,
    _ref$cancel = _ref.cancel,
    cancel = _ref$cancel === void 0 ? function () {} : _ref$cancel,
    _ref$ok = _ref.ok,
    ok = _ref$ok === void 0 ? function () {} : _ref$ok,
    _ref$single = _ref.single,
    single = _ref$single === void 0 ? false : _ref$single,
    _ref$autoFocus = _ref.autoFocus,
    autoFocus = _ref$autoFocus === void 0 ? true : _ref$autoFocus,
    _ref$configBgType = _ref.configBgType,
    configBgType = _ref$configBgType === void 0 ? 'nomal' : _ref$configBgType;
  var dialogDom = new DialogConstructor({
    el: document.createElement('div'),
    data: function data() {
      return {
        title: title,
        message: message,
        visible: true,
        cancelTitle: cancelTitle,
        configTitle: configTitle,
        configBgType: configBgType,
        single: single,
        autoFocus: autoFocus,
        cancel: cancel,
        ok: ok
      };
    }
  });
  document.body.appendChild(dialogDom.$el);
};

// 注册为全局组件的函数
function registryDialog() {
  // 将组件注册到 vue 的 原型链里去,
  // 这样就可以在所有 vue 的实例里面使用 this.$dialog()
  vue.prototype.$customDialog = showDialog;
}
export default registryDialog;