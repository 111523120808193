import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import moment from "moment";
function formatDateToAgo(time) {
  try {
    if (process.server) {
      return "";
    }
    if (typeof time === "string" && time.length === 10) {
      time = Number(time) * 1000;
    }
    if (typeof time === "number" && String(time).length === 10) {
      time = time * 1000;
    }

    //获取当前时间
    var m1 = moment();
    //获取需要对比的时间
    var m2 = moment(time);
    //计算相差多少小分、时、天、月
    var minute = m1.diff(m2, "minute");
    var hour = m1.diff(m2, "hour");
    var day = m1.diff(m2, "day");
    var month = m1.diff(m2, "month");
    if (minute < 60) {
      var unit = "min";
      return "".concat(minute || 1, " ").concat(minute > 1 ? unit + "s" : unit, " ago");
    } else if (hour < 24) {
      var _unit = "hour";
      return "".concat(hour, " ").concat(hour > 1 ? _unit + "s" : _unit, " ago");
    } else if (month <= 0) {
      var _unit2 = "day";
      return "".concat(day, " ").concat(day > 1 ? _unit2 + "s" : _unit2, " ago");
    } else if (month == 1) {
      var _unit3 = "month";
      return "".concat(month, " ").concat(month > 1 ? _unit3 + "s" : _unit3, " ago");
    } else {
      return m2.format("MMM:YYYY,HH:mm");
    }
  } catch (error) {
    console.log("时间格式化错误：", error);
    return "";
  }
}
export { formatDateToAgo };