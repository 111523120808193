//发售日历相关接口
import { POST, GET } from "@/utils/util";
var salecalendarApi = {
  // 列表
  launchList: function launchList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/fanshood/whiteList/launchList", params);
  },
  querySaleDetail: function querySaleDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/fanshood/whiteList/queryDetail?id=" + params.id, params);
  },
  updatePlatformSelection: function updatePlatformSelection() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/fanshood/whiteList/updatePlatformSelection", params);
  },
  queryWhiteListDetail: function queryWhiteListDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/fanshood/whiteList/queryWhiteListDetail?id=" + params.id, params);
  },
  launchAuditList: function launchAuditList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/fanshood/whiteList/launchAuditList", params);
  },
  //审核
  launchAudit: function launchAudit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/fanshood/whiteList/launchAudit", params);
  },
  //审核详情
  queryAuditDetail: function queryAuditDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/fanshood/whiteList/queryAuditDetail?id=" + params.id, params);
  },
  //获取邀请码列表
  getQualificationCodeListByUid: function getQualificationCodeListByUid() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/dcservice/fanshood/whiteList/getQualificationCodeListByUid", params);
  },
  //生成邀请码
  generateQualificationCode: function generateQualificationCode() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/fanshood/whiteList/generateQualificationCode", params);
  },
  //更新控制后台的参数
  updateLaunchConsole: function updateLaunchConsole() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/fanshood/whiteList/updateDisplayConfig", params);
  }
};
export default salecalendarApi;