import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import props from "./props.js";
var mouseDownX = 0;
var mouseDownY = 0;
var deltaX = 0;
var deltaY = 0;
var sumX = 0;
var sumY = 0;
var header = null;
var contain = null;
var modalContent = null;
var onmousedown = false;
export default {
  name: "DragModal",
  mixins: [props],
  props: {
    // 容器的类名
    modalClass: {
      type: String,
      default: function _default() {
        return "modal-box";
      }
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    title: {
      type: String,
      default: function _default() {
        return undefined;
      }
    },
    width: {
      // type: Number,
      default: function _default() {
        return "70%";
      }
    },
    footer: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    simpleClass: function simpleClass() {
      return Math.random().toString(36).substring(2);
    }
  },
  watch: {
    visible: function visible() {
      var _this = this;
      this.$nextTick(function () {
        _this.initialEvent(_this.visible);
      });
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.initialEvent(_this2.visible);
    });
  },
  created: function created() {},
  beforeDestroy: function beforeDestroy() {
    this.removeMove();
    window.removeEventListener("mouseup", this.removeUp, false);
  },
  methods: {
    handleOk: function handleOk(e) {
      this.resetNum();
      this.$emit("ok", e);
    },
    handleCancel: function handleCancel(e) {
      this.resetNum();
      this.$emit("cancel", e);
    },
    resetNum: function resetNum() {
      mouseDownX = 0;
      mouseDownY = 0;
      deltaX = 0;
      deltaY = 0;
      sumX = 0;
      sumY = 0;
    },
    handleMove: function handleMove(event) {
      var delta1X = event.pageX - mouseDownX;
      var delta1Y = event.pageY - mouseDownY;
      deltaX = delta1X;
      deltaY = delta1Y;
      // console.log('delta1X:' + delta1X, 'sumX:' + sumX, 'delta1Y:' + delta1Y, 'sumY:' + sumY)
      modalContent.style.transform = "translate(".concat(delta1X + sumX, "px, ").concat(delta1Y + sumY, "px)");
    },
    initialEvent: function initialEvent(visible) {
      var _this3 = this;
      if (visible) {
        setTimeout(function () {
          window.removeEventListener("mouseup", _this3.removeUp, false);
          contain = document.getElementsByClassName(_this3.simpleClass)[0];
          header = contain.getElementsByClassName("ant-modal-header")[0];
          modalContent = contain.getElementsByClassName("ant-modal-content")[0];
          modalContent.style.left = 0;
          modalContent.style.transform = "translate(0px,0px)";
          header.style.cursor = "all-scroll";

          // contain.onmousedown = (e) => {
          header.onmousedown = function (e) {
            onmousedown = true;
            mouseDownX = e.pageX;
            mouseDownY = e.pageY;
            document.body.onselectstart = function () {
              return false;
            };
            window.addEventListener("mousemove", _this3.handleMove, false);
          };
          window.addEventListener("mouseup", _this3.removeUp, false);
        }, 0);
      }
    },
    removeMove: function removeMove() {
      window.removeEventListener("mousemove", this.handleMove, false);
    },
    removeUp: function removeUp(e) {
      // console.log('removeUp')
      document.body.onselectstart = function () {
        return true;
      };
      if (onmousedown && !(e.pageX === mouseDownX && e.pageY === mouseDownY)) {
        onmousedown = false;
        sumX = sumX + deltaX;
        sumY = sumY + deltaY;
        // console.log('sumX:' + sumX, 'sumY:' + sumY)
      }

      this.removeMove();
      // this.checkMove()
    }
  }
};