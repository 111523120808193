// 首页模块配置相关
import { POST, GET } from '@/utils/util';
var DcVideoTagApi = {
  // http://13.214.186.200:8888/docs/api-docs/-/blob/lzm/DC%E7%A4%BE%E5%8C%BA%E5%92%8C%E6%96%B0%E9%97%BB/%E7%A4%BE%E5%8C%BAAPP%E6%8E%A5%E5%8F%A3/%E7%AE%A1%E7%90%86%E5%90%8E%E5%8F%B0
  // DC视频管理相关接口
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/label/save', params);
  },
  // 编辑/删除
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/label/update', params);
  },
  findone: function findone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/label/findone', params);
  },
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/label/list', params);
  }
};
export default DcVideoTagApi;