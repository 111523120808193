// import getters from '../store/getters';
export default {
  name: "PreviewMedia",
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    previewSource: {
      type: String,
      default: ""
    },
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    mediaType: {
      type: String,
      default: "audio" // video
    },

    modalWidth: {
      type: String,
      default: function _default() {
        return "600px";
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    // 关闭弹框
    closeModal: function closeModal() {
      this.$emit("closePreview");
    }
  }
};