import _construct from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/construct.js";
import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _typeof from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.btoa.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.slice.js";
/*
 * utils.js
 * 工具类js
 */

import axios from "axios";
import * as ENUM from "./enum";
import store from "../store";
import { hexMD5 as MD5 } from "./md5";
import { signData } from './sign';

// import {
//     Spin
// } from 'ant-design-vue'

var _localStorage = window.localStorage;
var userkey = "overseasOperationsUser";
var os = 'admin';
var headers = {
  os: os,
  "Content-Type": "application/json;charset=utf-8"
};
// console.log("process.env.NODE_ENV,", process.env.NODE_ENV);
var service = axios.create({
  headers: headers,
  baseURL: process.env.VUE_APP_DOMAIN,
  // baseURL: `http://3.1.245.229:9711`,
  timeout: 150000
});
service.interceptors.response.use(function (res) {
  var response = null;
  var resCode = null;
  var data = res.data,
    status = res.status,
    config = res.config;
  var _config$responseType = config.responseType,
    responseType = _config$responseType === void 0 ? "json" : _config$responseType,
    _config$showmessage = config.showmessage,
    showmessage = _config$showmessage === void 0 ? true : _config$showmessage;
  if (status === 200) {
    switch (responseType) {
      case "blob":
        // console.log('blob',res)
        if (res.data && data instanceof Blob) {
          response = res.data;
        }
        break;
      case "json":
        if (data && data.code && data.code === ENUM.StatusCode.success) {
          // data有值。
          // console.log('接口返回成功', data)
          if (data.data || data.data === 0) {
            response = data.data;
          } else {
            // 这是为了请求成功没有返回值设置默认的data
            response = true;
          }
          if (response && parseInt(data.expiretime) > 0) {
            var key = res.config.url.replace(res.config.baseURL, "") + (res.config.cacheKey || "");
            if (key.indexOf("/") !== 0) key = "/" + key;
            setCache(key, data.expiretime, response);
          }
        } else {
          if (data.code == ENUM.StatusCode.tokenOver) {
            // console.log('token过期', data)
            localStorage.removeItem("overseasOperationsUser");
            var origin = window.location.origin;
            window.location.href = origin + "/login";
            return;
          }
          // 已冻结
          if (data.code == ENUM.StatusCode.freeze) {
            return data;
          }
          if (showmessage) window.GLOBAL.vbus.$emit("message", "error", data.msg || "网络异常");

          // 需要返回错误码前端判断使用比如弹窗之类的
          var error = {
            code: data.code,
            msg: data.msg || ""
          };
          console.log("error-->>>", error);
          return Promise.reject(error);
        }
        break;
      default:
        break;
    }
  } else {
    window.GLOBAL.vbus.$emit("message", "error", res.data && res.data.msg || "Network error");
    throw "common Request failed, " + JSON.stringify(res);
  }
  return response;
}, function (error) {
  if (error.response) {
    if (error.response.status == 401 || error.response.status == 403) {
      // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
      _localStorage.removeItem(userkey);
      store.commit("clearState");
      store.commit("base/clearState");
      window.GLOBAL.vbus.$emit("router", {
        path: "/login"
      });
    }
    if (error.code == 1511445) {
      _localStorage.removeItem(userkey);
      store.commit("clearState");
      store.commit("base/clearState");
      setTimeout(function () {
        window.location.reload();
      }, 16);
    }
  }
  throw error;
});
service.interceptors.request.use(function (config) {
  var _ref = JSON.parse(_localStorage.getItem(userkey)) || {},
    token = _ref.token;
  config.headers.Authorization = token;
  var ts = Date.now();
  // config.headers.ts = ts;
  config.headers.sign = signData("".concat(os, "&").concat(ts, "&"));
  if (config.inheader) {
    var curragent = store.getters.agentId;
    config.headers.agent = curragent || "";
  }
  if (config.headerConfig) {
    Object.assign(config.headers, config.headerConfig);
  }
  return config;
}, function (error) {
  Promise.reject(error);
});

// 过滤下参数
var filterNullParams = function filterNullParams(params) {
  if (_typeof(params) == 'object') {
    Object.keys(params).filter(function (key) {
      return (params[key] === '' || params[key] === undefined || params[key] === null) && delete params[key];
    });
  }
  return params;
};
function GET(url, params, showmessage, headerConfig) {
  params = filterNullParams(params);
  return service({
    url: url,
    method: "get",
    params: params,
    showmessage: showmessage,
    headerConfig: headerConfig
  });
}
function POST(url, data) {
  var showmessage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var responseType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "json";
  var inheader = arguments.length > 4 ? arguments[4] : undefined;
  data = filterNullParams(data);
  return service({
    url: url,
    method: "post",
    data: data,
    responseType: responseType,
    inheader: inheader,
    showmessage: showmessage
  });
}
function POSTWithConfig(url, data) {
  var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  data = filterNullParams(data);
  return service(_objectSpread({
    url: url,
    method: "post",
    data: data
  }, config));
}
function DELETE(url, params, showmessage) {
  params = filterNullParams(params);
  return service({
    url: url,
    method: "delete",
    params: params,
    showmessage: showmessage
  });
}
function setCache(key, expire, data) {
  var timespan = parseInt(expire) * 1000;
  if (timespan > Date.now()) Storage.set(window.btoa(key), {
    expire: expire,
    data: data
  });
}

// 将base64转为Unicode规则编码
function base64toBlob(base64, type) {
  var bstr = atob(base64, type);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n); // 转换编码后才可以使用charCodeAt 找到Unicode编码
  }

  return new Blob([u8arr], {
    type: type
  });
}

// 下载静态文件
function downLoadFile(url, filename) {
  var a = document.createElement("a");
  if (typeof a.download === "undefined") {
    window.location = url;
  } else {
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); // 下载完成移除元素
    window.URL.revokeObjectURL(url); // 释放掉blo
  }
}

// 下载动态文件
function download(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  console.log("params", params);
  params = filterNullParams(params);
  var _ref2 = JSON.parse(_localStorage.getItem(userkey)) || {},
    token = _ref2.token;
  var ts = Date.now();
  axios.get(url, {
    headers: {
      os: os,
      "Content-Type": "application/x-download'",
      Authorization: token,
      // ts: ts,
      sign: signData("".concat(os, "&").concat(ts, "&"))
    },
    params: params,
    responseType: "blob"
  }).then(function (response) {
    var blob = new Blob([response.data], {
      type: "application/vnd.ms-excel"
    });
    var filename = MD5(Date.now().toString()) + ".csv";
    if (window.navigator.msSaveOrOpenBlob) {
      // 兼容IE10
      window.navigator.msSaveBlob(blob, filename);
    } else {
      //  兼容chrome/firefox
      var aTag = document.createElement("a");
      aTag.download = filename;
      aTag.href = window.URL.createObjectURL(blob);
      aTag.click();
      URL.revokeObjectURL(aTag.href);
    }
  }).catch(function () {
    //如果不能正常下载给出对应提示
    this.$message({
      type: "error",
      message: "文件下载失败"
    });
  });
}
function getResponseHeadersFilename(headers) {
  try {
    var disposition = headers['content-disposition'].split(';');
    var reg = /^filename=.*$/;
    var target = disposition.find(function (item) {
      return reg.test(item);
    });
    return target ? target.replace('filename=', '') : '';
  } catch (err) {
    return '';
  }
}

// 下载动态文件
function downloadPost(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  data = filterNullParams(data);
  var _ref3 = JSON.parse(_localStorage.getItem(userkey)) || {},
    token = _ref3.token;
  var ts = Date.now();
  return new Promise(function (resolve, reject) {
    axios.post(url, data, {
      headers: {
        os: "admin",
        "Content-Type": "application/json",
        Authorization: token,
        // ts: ts,
        sign: signData("".concat(os, "&").concat(ts, "&"))
      },
      responseType: "blob"
    }).then(function (response) {
      var headers = response.headers,
        data = response.data;
      var blob = new Blob([data], {
        type: "application/vnd.ms-excel"
      });
      var filename = getResponseHeadersFilename(headers) || MD5(Date.now().toString()) + ".csv";
      if (window.navigator.msSaveOrOpenBlob) {
        // 兼容IE10
        window.navigator.msSaveBlob(blob, filename);
      } else {
        //  兼容chrome/firefox
        var aTag = document.createElement("a");
        aTag.download = filename;
        aTag.href = window.URL.createObjectURL(blob);
        aTag.click();
        URL.revokeObjectURL(aTag.href);
      }
      resolve();
    }).catch(function (err) {
      //如果不能正常下载给出对应提示
      this.$message({
        type: "error",
        message: "文件下载失败"
      });
      reject(err);
    });
  });
}
var getUrlParam = function getUrlParam(paraName) {
  var url = window.document.location.toString();
  var arrObj = url.split("?");
  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split("&");
    var arr;
    for (var i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");
      if (arr != null && arr[0] === paraName) {
        return arr[1];
      }
    }
    return "";
  } else {
    return "";
  }
};
/* 时间处理工具 begin */

function _getDate(datetime) {
  if (typeof datetime == "string") {
    var arr = datetime.split(/[- : \/ T]/);
    if (arr[0].length === 4) arr[1] = arr[1] - 1;
    datetime = _construct(Date, _toConsumableArray(arr));
  }
  return datetime;
}

/**
 * 时间格式化
 * @param {String} datetime 要格式化的时间字符串
 * @param {String?} format 时间的格式(注意格式中对应的字母),默认为'yyyy-MM-dd HH:mm:ss' 全角ｍ表示英文月份
 */
var DateFormat = function DateFormat(datetime) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "yyyy-MM-dd";
  var date = datetime,
    month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  try {
    date = _getDate(date);
    var o = {
      "ｍ+": month[date.getMonth()],
      "M+": date.getMonth() + 1,
      //月份
      "d+": date.getDate(),
      //日
      // "H+": date.getHours(), //小时
      // "m+": date.getMinutes(), //分
      // "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3),
      //季度
      S: date.getMilliseconds() //毫秒
    };

    if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o) if (new RegExp("(" + k + ")").test(format)) format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    return format;
  } catch (e) {
    return datetime;
  }
};
var DateRelative = function DateRelative(daynum) {
  var date1 = new Date();
  // time1=date1.getFullYear()+"-"+(date1.getMonth()+1)+"-"+date1.getDate();//time1表示当前时间
  var date2 = new Date(date1);
  date2.setDate(date1.getDate() + daynum);
  var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
  return DateFormat(time2);
};

/*
 * 时间差(用于倒计时)
 * type:String,可无,结果类型,默认为'H',可选值:'d'天,'H'时,'m'分,'s'秒
 * source:String|Object(Date),可无,源时间,默认为当前时间
 * destination:String|Object(Date),可无,目标时间,默认为当前时间
 * source与destination 必须同时有一个有值
 * 返回值null|{s:秒,m:分,H:时,d:天}(若为负值则表示时间指向反了)
 */
function DateDiff() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref4$type = _ref4.type,
    type = _ref4$type === void 0 ? "H" : _ref4$type,
    _ref4$source = _ref4.source,
    source = _ref4$source === void 0 ? new Date() : _ref4$source,
    _ref4$destination = _ref4.destination,
    destination = _ref4$destination === void 0 ? new Date() : _ref4$destination;
  var date = source;
  var ts = 0;
  try {
    date = _getDate(date);
    destination = _getDate(destination);
    ts = destination.getTime() - date.getTime(); // Math.abs(destination.getTime() - date.getTime());
    var o = {
      s: Math.floor(ts / 1000),
      m: Math.floor(ts / 60000),
      // 1000*60
      H: Math.floor(ts / 3600000),
      // 1000*60*60
      d: Math.floor(ts / 86400000) // 1000*60*60*24
    };

    switch (type) {
      case "H":
      default:
        return {
          s: o.s % 60,
          m: o.m % 60,
          H: o.H,
          d: 0
        };
      case "s":
        return {
          s: o.s,
          m: 0,
          H: 0,
          d: 0
        };
      case "m":
        return {
          s: o.s % 60,
          m: o.m,
          H: 0,
          d: 0
        };
      case "d":
        return {
          s: o.s % 60,
          m: o.m % 60,
          H: o.H % 24,
          d: o.d
        };
    }
  } catch (e) {
    return null;
  }
}
var numberChinese = function numberChinese(number) {
  var units = "个十百千万@#%亿^&~",
    chars = "零一二三四五六七八九";
  var a = (number + "").split(""),
    s = [];
  if (a.length > 12) {
    throw new Error("too big");
  } else {
    for (var i = 0, j = a.length - 1; i <= j; i++) {
      if (j == 1 || j == 5 || j == 9) {
        //两位数 处理特殊的 1*
        if (i == 0) {
          if (a[i] != "1") s.push(chars.charAt(a[i]));
        } else {
          s.push(chars.charAt(a[i]));
        }
      } else {
        s.push(chars.charAt(a[i]));
      }
      if (i != j) {
        s.push(units.charAt(j - i));
      }
    }
  }
  //return s;
  return s.join("").replace(/零([十百千万亿@#%^&~])/g, function (m, d, b) {
    //优先处理 零百 零千 等
    b = units.indexOf(d);
    if (b != -1) {
      if (d == "亿") return d;
      if (d == "万") return d;
      if (a[j - b] == "0") return "零";
    }
    return "";
  }).replace(/零+/g, "零").replace(/零([万亿])/g, function (m, b) {
    // 零百 零千处理后 可能出现 零零相连的 再处理结尾为零的
    return b;
  }).replace(/亿[万千百]/g, "亿").replace(/[零]$/, "").replace(/[@#%^&~]/g, function (m) {
    return {
      "@": "十",
      "#": "百",
      "%": "千",
      "^": "十",
      "&": "百",
      "~": "千"
    }[m];
  }).replace(/([亿万])([一-九])/g, function (m, d, b, c) {
    c = units.indexOf(d);
    if (c != -1) {
      if (a[j - c] == "0") return d + "零" + b;
    }
    return m;
  });
};
var formatRequestParams = function formatRequestParams(params) {
  var toArrayKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var timeoption = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    key: "",
    startkey: "",
    endkey: ""
  };
  return Object.keys(params).reduce(function (prev, key) {
    var isString = typeof params[key] == "string";
    var value = isString ? params[key].trim() : params[key];
    if (Array.isArray(value) && value.length && timeoption.key == key) {
      timeoption.startkey && (prev[timeoption.startkey] = value[0]);
      timeoption.endkey && (prev[timeoption.endkey] = value[1]);
    } else if (toArrayKeys.includes(key) && typeof value == "string" && value.length) {
      var vals = value.split(/\n/);
      prev[key] = vals;
    } else if (value !== "" && value !== undefined && value !== null) {
      prev[key] = value;
    }
    return prev;
  }, {});
};
/**
* 格式化日期
* @param {String/Number/Date}   date 日期
* @param {String}        format  期望输出的日期格式
*/
var dateFormat = function dateFormat(date, format) {
  if (!date) return '';
  format = format || 'Y-m-d';
  var _date = new Date(date);
  var year = _date.getFullYear() + '',
    month = _date.getMonth() + 1 + '',
    day = _date.getDate() + '',
    weekDay = _date.getDay() + '',
    hours = _date.getHours() + '',
    minutes = _date.getMinutes() + '',
    seconds = _date.getSeconds() + '';
  var _ref5 = [_date.toUTCString().substr(8, 3), _date.toUTCString().substr(0, 3)],
    monthEn = _ref5[0],
    weekEn = _ref5[1];
  var weekDay_ISO8601 = weekDay === '0' ? '7' : weekDay;
  return format.replace(/Y/g, year) //1970
  .replace(/y/g, year.slice(-2)) //70
  .replace(/m/g, ('0' + month).slice(-2)) //09
  .replace(/n/g, month) //9
  .replace(/M/g, monthEn) //Sep
  // .replace(/F/g, monthsMap[monthEn].en) //September
  // .replace(/\_F/g, monthsMap[monthEn].cn) //九
  .replace(/j/g, day) //9
  .replace(/d/g, ('0' + day).slice(-2)) //09
  .replace(/D/g, weekEn) //Sun
  // .replace(/l/g, weeksMap[weekEn].en) //Sunday
  // .replace(/_l/g, weeksMap[weekEn].cn) //日
  .replace(/w/g, weekDay) //0
  .replace(/N/g, weekDay_ISO8601) //7
  .replace(/H/g, ('0' + hours).slice(-2)) //06
  .replace(/G/g, hours) //6
  .replace(/i/g, ('0' + minutes).slice(-2)) //06
  .replace(/s/g, ('0' + seconds).slice(-2)); //06
};

export { service, POST, GET, DELETE, getUrlParam, MD5, ENUM, base64toBlob, downLoadFile, DateFormat, DateRelative, DateDiff, numberChinese, download, downloadPost,
// baseURL,
POSTWithConfig, formatRequestParams, dateFormat };