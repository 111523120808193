//交易团
import { POST, GET } from "@/utils/util";
var TradingGroup = {
  // 交易团审核列表
  queryAuditList: function queryAuditList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/tradeGroup/queryAuditList", params);
  },
  //审核详情
  queryAuditDetail: function queryAuditDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/tradeGroup/queryTradeGroupAuditDetail?id=".concat(params.id));
  },
  //审核
  audit: function audit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/tradeGroup/tradeGroupAudit", params);
  },
  // 交易团管理列表
  queryList: function queryList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/tradeGroup/queryList", params);
  },
  // 交易团详情
  queryDetail: function queryDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/tradeGroup/queryTradeGroupDetail?id=".concat(params.id));
  },
  //解散群
  dissolveGroup: function dissolveGroup() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/tradeGroup/dissolve?id=".concat(params.id), params);
  },
  // 返拥记录列表
  queryTradeGroupRebateList: function queryTradeGroupRebateList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/tradeGroup/queryTradeGroupRebateList", params);
  },
  //返佣详情
  queryTradeGroupRebateDetail: function queryTradeGroupRebateDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    // return POST(`/api/dcservice/tradeGroup/queryTradeGroupRebateDetail`, params);
    return POST("/api/dcservice/tradeGroup/queryTradeGroupRebateDetail?id=".concat(params.id));
  },
  //交易团名审核列表
  queryAuditNameList: function queryAuditNameList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/tradeGroup/queryAuditNameList", params);
  },
  //审核详情
  tradeGroupNameAudit: function tradeGroupNameAudit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/dcservice/tradeGroup/tradeGroupNameAudit", params);
  }
};
export default TradingGroup;