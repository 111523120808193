import { POST, GET } from '@/utils/util';
var DcMediaApi = {
  saveNewsConfig: function saveNewsConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/newsconfig/save', params);
  },
  updateNewsConfig: function updateNewsConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/newsconfig/update', params);
  },
  newsConfigList: function newsConfigList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/newsconfig/list', params);
  },
  newsConfigFindOne: function newsConfigFindOne() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/newsconfig/findone', params);
  }
};
export default DcMediaApi;