import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var business = [{
  path: '/business/index',
  name: '商家管理',
  type: 2,
  meta: {
    title: '商家管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/business");
  }
}, {
  path: '/business/getSale',
  name: '在售商品',
  type: 2,
  meta: {
    title: '在售商品',
    breadcrumbHidden: true
    // keepAlive: true  // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/business/getSale");
  }
}, {
  path: '/business/deposit',
  name: '保证金',
  type: 2,
  meta: {
    title: '保证金',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/business/deposit");
  }
}, {
  path: '/business/detail',
  name: '查看详情',
  type: 2,
  meta: {
    title: '查看详情',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/business/detail");
  }
}];
export default business;