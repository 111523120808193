var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.ctype == "eth" ? _c("nft-icon", {
    style: _vm.styleObject
  }) : _vm.ctype == "weth" ? _c("weth-icon", {
    style: _vm.styleObject
  }) : _c("nft-icon", {
    style: _vm.styleObject
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };