import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var square = [{
  path: '/square/content',
  name: 'squareContent',
  type: 2,
  meta: {
    title: '内容管理表',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/square/content");
  }
}, {
  path: '/square/commentManagement',
  name: 'squareCommentManagement',
  type: 2,
  meta: {
    title: '评论管理表',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/square/commentManagement");
  }
}, {
  path: '/square/userReports',
  name: 'squareUserReports',
  type: 2,
  meta: {
    title: '用户举报表',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/square/userReports");
  }
}, {
  path: '/square/partnerSync',
  name: 'squarePartnerSync',
  type: 2,
  meta: {
    title: '第三方同步表',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/square/partnerSync");
  }
}];
export default square;