// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var PumpApi = {
  // DC交易服务/pump/【管理后台】pump控制台列表.md
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/fanshood/pumpcycle/list', params);
  },
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/fanshood/pumpcycle/add', params);
  },
  edit: function edit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/fanshood/pumpcycle/edit', params);
  },
  // DC交易服务/pump/【管理后台】Round时间段.md
  cycleList: function cycleList() {
    return GET('/api/dcservice/fanshood/cycle/list');
  },
  // DC交易服务/pump/【管理后台】pump卡片详情.md
  detail: function detail(cycle) {
    return GET("/api/dcservice/fanshood/cycle/open/detail/".concat(cycle));
  },
  // DC交易服务/pump/【管理后台】更新pump卡片展示状态.md
  updateStatus: function updateStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/fanshood/cycle/update/status', params);
  },
  // DC交易服务/pump/pump周期.md
  pumpCycle: function pumpCycle() {
    return GET('/api/dcservice/fanshood/home/open/pump/cycle');
  },
  // DC交易服务/pump/【管理后台】删除pump.md
  delete: function _delete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/fanshood/pumpcycle/delete', params);
  },
  // DC交易服务/pump/【管理后台】pump任务列表.md
  tasklist: function tasklist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/fanshood/pumpcycle/page', params);
  },
  // DC交易服务/pump/pump钱包地址.md
  openOfficeWallet: function openOfficeWallet() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/fanshood/pump/open/wallet', params);
  },
  // DC交易服务/pump/【管理后台】pump打款结果通知.md
  updatePumpPayStatus: function updatePumpPayStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/fanshood/pumpcycle/updatePumpPayStatus', params);
  }
};
export default PumpApi;