import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var aiVideo = [{
  path: '/aiVideo',
  name: 'AI视频消费记录',
  type: 2,
  meta: {
    title: 'AI视频消费记录',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/aiVideo/index");
  }
}];
export default aiVideo;