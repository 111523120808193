import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var logistics = [{
  path: '/logistics/index',
  name: '物流模版',
  type: 2,
  meta: {
    title: '物流模版',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/logisticsManagement");
  }
},
// 卖家
{
  path: '/logistics/addSeller',
  name: '新增物流模版',
  type: 2,
  meta: {
    title: '新增物流模版',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/logisticsManagement/addlogistics");
  }
},
// 买家
// {
//     path: '/logistics/addBuyer',
//     name: '新增物流模版',
//     type: 2,
//     meta: {
//         title: '新增物流模版',
//         breadcrumbHidden: true,
//     },
//     hidden: true,
//     component: () => import(`@/views/logisticsManagement/addBuyerFeight`)
// },
{
  path: '/logistics/edit',
  name: '编辑物流模版',
  type: 2,
  meta: {
    title: '编辑物流模版',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/logisticsManagement/addlogistics");
  }
}, {
  path: '/logistics/view',
  name: '查看物流模版',
  type: 2,
  meta: {
    title: '查看物流模版',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/logisticsManagement/addlogistics");
  }
}

// {
//     path: '/logistics/editBuyer',
//     name: '编辑物流模版',
//     type: 2,
//     meta: {
//         title: '编辑物流模版',
//         breadcrumbHidden: true,
//     },
//     hidden: true,
//     component: () => import(`@/views/logisticsManagement/addBuyerFeight`)
// },
];

export default logistics;