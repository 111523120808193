import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var hotWord = [{
  path: '/hotWord/index',
  name: '热搜词配置',
  type: 2,
  meta: {
    title: '热搜词配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/hotWord");
  }
}, {
  path: '/hotWord/addWord',
  name: '新增',
  type: 2,
  meta: {
    title: '新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/hotWord/addWord");
  }
}, {
  path: '/hotWord/editWord',
  name: '配置',
  type: 2,
  meta: {
    title: '配置',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/hotWord/editWord");
  }
}, {
  path: '/hotWord/addlist',
  name: '新增商品',
  type: 2,
  meta: {
    title: '新增商品',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/hotWord/addlist");
  }
}];
export default hotWord;