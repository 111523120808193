var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "_pagination"
  }, [_c("div", [_vm.lang == "zh" ? _c("span", [_vm._v("共 "), _c("em", [_vm._v(_vm._s(_vm.total))]), _vm._v("条记录 ")]) : _c("span", [_vm._v("Total "), _c("em", [_vm._v(_vm._s(_vm.total))])])]), _c("a-pagination", _vm._b({
    attrs: {
      showSizeChanger: _vm.showSizeChanger,
      showQuickJumper: _vm.showQuickJumper,
      pageSize: _vm.currentSize,
      pageSizeOptions: _vm.pageSizeOptions,
      total: _vm.total,
      size: _vm.tableSize
    },
    on: {
      "update:pageSize": function updatePageSize($event) {
        _vm.currentSize = $event;
      },
      "update:page-size": function updatePageSize($event) {
        _vm.currentSize = $event;
      },
      change: _vm.onPageSizeChange,
      showSizeChange: _vm.onShowSizeChange
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  }, "a-pagination", _vm.$attrs, false))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };