import "core-js/modules/es.number.constructor.js";
import { mapState, mapMutations } from "vuex";
import UploadImg from "@/components/UploadImg";
import lodash from "lodash"; //导入loadsh插件
var moment = require("moment");
export default {
  components: {
    UploadImg: UploadImg
  },
  name: "V-formItems",
  props: {
    supplierCoverageList: {
      type: Array
    },
    visible: {
      type: Boolean,
      default: true
    },
    searchKeys: {
      type: Array,
      required: true
    },
    getDataWithKey: {
      type: [Function, Array, Object],
      default: function _default() {}
    },
    onDateChange: {
      type: Function,
      default: function _default() {}
    },
    calendarChange: {
      type: Function,
      default: function _default() {}
    },
    onAddressChange: {
      type: Function,
      default: function _default() {}
    },
    onExpressChange: {
      type: Function,
      default: function _default() {}
    },
    disabledDate: {
      type: Function,
      default: function _default() {}
    },
    isInModal: {
      type: Boolean,
      default: false
    },
    inputChange: {
      type: Function,
      default: function _default() {}
    },
    help: {
      type: String
    },
    onBlur: {
      type: Function,
      default: function _default() {}
    },
    onSelectChange: {
      type: Function,
      default: function _default() {}
    },
    onSearch: {
      type: Function,
      default: function _default() {}
    },
    isPlatform: {
      type: Boolean,
      default: false
    },
    groupChange: {
      type: Function,
      default: function _default() {}
    },
    previewImg: {
      type: Function,
      default: function _default() {}
    },
    childSelectChange: {
      type: Function,
      default: function _default() {}
    },
    paymethodType: {
      type: Number,
      default: 0
    },
    outType: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    moment: moment
  }
};