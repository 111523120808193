import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var user = [{
  path: '/user/index',
  name: '用户管理',
  type: 2,
  meta: {
    title: '用户管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/userManagement");
  }
}, {
  path: '/user/detail',
  name: 'UserDetail',
  type: 2,
  meta: {
    title: '用户信息',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/userManagement/detail");
  }
}];
export default user;