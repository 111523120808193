import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var configBanner = [
//banner配置
{
  path: '/configBanner/index',
  name: 'banner配置',
  type: 3,
  meta: {
    title: 'banner配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/configBanner");
  }
}, {
  path: '/configBanner/add',
  name: '新增',
  type: 3,
  meta: {
    title: '新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/configBanner/addBanner.vue");
  }
}, {
  path: '/configBanner/edit',
  name: '编辑',
  type: 3,
  meta: {
    title: '编辑',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/configBanner/addBanner.vue");
  }
}];
export default configBanner;