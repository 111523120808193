// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var VideoApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000051
  // video 列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/video/findpage', params);
  },
  // video 详情
  videoDetail: function videoDetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/video/findone', params);
  },
  // video 增
  videoSave: function videoSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/video/save', params);
  },
  // video改
  videoUpdate: function videoUpdate() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/video/update', params);
  },
  // video 删
  videoDel: function videoDel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/video/delete', params);
  }
};
export default VideoApi;