import { POST, GET } from '@/utils/util';
var CategoryManageApi = {
  saveNewsConfig: function saveNewsConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/news/category/manage/save', params);
  },
  updateNewsConfig: function updateNewsConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/news/category/manage/update', params);
  },
  newsConfigList: function newsConfigList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/news/category/manage/findpage', params);
  },
  newsConfigFindOne: function newsConfigFindOne() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/news/category/manage/getdetails', params);
  }
};
export default CategoryManageApi;