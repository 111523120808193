import { POST, GET } from '@/utils/util';
var squareApi = {
  // 奖项管理-开奖
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/dynamic/manage/list', params);
  },
  logs: function logs() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/dynamic/manage/authorization/log', params);
  },
  audit: function audit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/dynamic/manage/audit', params);
  },
  detail: function detail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/dynamic/manage/open/detail', params);
  },
  partnerList: function partnerList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/dynamic/manage/authorization/list', params);
  },
  syncUser: function syncUser() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/dynamic/manage/sync/user', params);
  },
  addUser: function addUser() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/dynamic/manage/authorization/add', params);
  }
};
export default squareApi;