import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var seriesV2 = [{
  path: '/seriesV2/index',
  name: '系列管理',
  type: 2,
  meta: {
    title: '系列管理',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/seriesManagementV2/index.vue");
  }
}, {
  path: '/seriesV2/add',
  name: 'seriesV2',
  type: 3,
  meta: {
    title: '新建系列',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/seriesManagementV2/add.vue");
  }
}, {
  path: '/seriesV2/edit',
  name: 'seriesV2',
  type: 3,
  meta: {
    title: '管理系列',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/seriesManagementV2/add.vue");
  }
}];
export default seriesV2;