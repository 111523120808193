import { POST, GET } from "../utils/util";
var DcToolApi = {
  categoryList: function categoryList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/tools/categories/list', params);
  },
  categoryAdd: function categoryAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/tools/categories', params);
  },
  categoryInfo: function categoryInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/tools/categories/".concat(params.id));
  },
  categoryEdit: function categoryEdit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/tools/categories/".concat(params.id), params);
  },
  categorySort: function categorySort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/tools/categories/sort", params);
  },
  toolList: function toolList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/tools/list", params);
  },
  toolAdd: function toolAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/tools", params);
  },
  toolInfo: function toolInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/tools/".concat(params.id), params);
  },
  toolEdit: function toolEdit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/tools/".concat(params.id), params);
  },
  toolDel: function toolDel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/tools/".concat(params.id, "/remove"), params);
  }
};
export default DcToolApi;