import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dclist = [{
  path: '/dclist/index',
  name: 'DC列表',
  type: 2,
  meta: {
    title: 'DC列表',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dclistManagement/index.vue");
  }
}];
export default dclist;