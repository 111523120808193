import jsrsasign from 'jsrsasign';
var signData = function signData(encryptData) {
  try {
    var publicEncryKey = process.env.VUE_APP_RSA_PUB;
    var publicPemKey = '-----BEGIN PUBLIC KEY-----\n' + publicEncryKey + '\n-----END PUBLIC KEY-----';
    var pub = jsrsasign.KEYUTIL.getKey(publicPemKey);
    var enc = jsrsasign.KJUR.crypto.Cipher.encrypt(encryptData, pub, 'RSAOAEP'); //此为添加OAEP填充方式的加密方法
    return jsrsasign.hextob64(enc);
  } catch (err) {
    return '';
  }
};
export { signData };