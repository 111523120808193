// 黑名单相关接口
import { POST, GET } from '@/utils/util';
var BlacklistApi = {
  // 列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/blacklist/findpage', params);
  },
  // 移除黑名单
  remove: function remove() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/blacklist/remove', params);
  },
  // 批量封禁
  batchForbid: function batchForbid() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/blacklist/account/batch/add', params);
  },
  // 批量添加
  batchAdd: function batchAdd() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/blacklist/batch/add', params);
  },
  // //白名单批量新增接口
  // addWhite: (params = {}) => {
  //   return POST('/api/accountservice/white/add', params)
  // },

  // 解除白名单
  removeByUid: function removeByUid() {
    var userUid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return GET("/api/accountservice/white/removeByUid/".concat(userUid));
  },
  //白名单操作日志查询
  getQueryLog: function getQueryLog() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/white/queryLog', params);
  }
};
export default BlacklistApi;