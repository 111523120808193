export default {
  commentID: "评论ID",
  releaseTime: "发布时间",
  rootComment: "所属根评论",
  commentContent: "评论内容",
  likeCnts: "点赞数",
  senderID: "发布人ID",
  senderName: "发布人",
  confirmDesc: "是否确认{action}所选评论，点击确认后将执行",
  chooseReasonTemplate: "选择理由模板",
  pleaseChooseTheSuitableReason: "请选择理由模板",
  enterTheRemark: "输入备注",
  setAsPinnedComment: "设为置顶",
  previewVideo: "预览视频",
  sendTimeBegin: "发布时间_开始",
  sendTimeOver: "发布时间_结束",
  pin: "置顶",
  operatTip: '请至少选中一项进行操作！',
  operatTip2: '选项中没有“失效”数据',
  sourceType: '来源类型'
};