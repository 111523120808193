import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var Tools = [{
  path: '/dcFeatures/index',
  name: 'dcFeatures',
  type: 2,
  meta: {
    title: '内容特征库',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dcFeatures/");
  }
}, {
  path: '/dcFeatures/sublist',
  name: 'dcFeaturesSublist',
  type: 3,
  meta: {
    title: '组名称',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/dcFeatures/sublist.vue");
  }
}];
export default Tools;