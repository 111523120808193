import { render, staticRenderFns } from "./SelectPage.vue?vue&type=template&id=21287a5b&scoped=true&"
import script from "./SelectPage.vue?vue&type=script&lang=js&"
export * from "./SelectPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21287a5b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\projects\\operation-management-background\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21287a5b')) {
      api.createRecord('21287a5b', component.options)
    } else {
      api.reload('21287a5b', component.options)
    }
    module.hot.accept("./SelectPage.vue?vue&type=template&id=21287a5b&scoped=true&", function () {
      api.rerender('21287a5b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SelectPage.vue"
export default component.exports