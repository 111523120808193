import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { allUserRutes } from './componentConf';
export default [{
  path: '/index',
  name: 'index',
  meta: {
    title: '首页',
    icon: 'home'
  },
  hiddenMenu: true,
  redirect: '/login',
  component: function component() {
    return import('@/layouts/BasicLayout.vue');
  },
  children: _toConsumableArray(allUserRutes)
}, {
  path: '/',
  name: 'empty',
  redirect: '/login'
}, {
  path: '/login',
  name: 'login',
  hiddenMenu: true,
  component: function component() {
    return import('@/views/login');
  }
}, {
  path: '*',
  name: '404',
  hiddenMenu: true,
  component: function component() {
    return import('@/views/error/404.vue');
  }
}];