var getters = {
  permissions: function permissions(state) {
    return state.base.permissions;
  },
  pagePermissions: function pagePermissions(state) {
    return state.base.pagePermissions;
  },
  addRoutes: function addRoutes(state) {
    return state.base.addRoutes;
  },
  routes: function routes(state) {
    return state.base.routes;
  },
  userInfo: function userInfo(state) {
    return state.base.userInfo;
  }
};
export default getters;