import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var tradingGroup = [
// DC-交易团管理
{
  path: "/tradingGroup/tradingGroupAudit",
  name: "交易团审核",
  type: 2,
  meta: {
    title: "交易团审核",
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/tradingGroup/tradingGroupAudit/index.vue");
  }
}, {
  path: "/tradingGroup/tradingGroupManagement",
  name: "交易团管理",
  type: 2,
  meta: {
    title: "交易团管理",
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/tradingGroup/tradingGroupManagement/index.vue");
  }
}, {
  path: "/tradingGroup/rebateCommissionRecord",
  name: "返佣记录页",
  type: 2,
  meta: {
    title: "返佣记录页",
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/tradingGroup/rebateCommissionRecord/index.vue");
  }
}, {
  path: "/tradingGroup/informationEditor",
  name: "交易团信息编辑审核",
  type: 2,
  meta: {
    title: "交易团信息编辑审核",
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/tradingGroup/informationEditor/index.vue");
  }
}];
export default tradingGroup;