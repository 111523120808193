import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var combination = [{
  path: '/combination/index',
  name: '组合商品',
  type: 2,
  meta: {
    title: '组合商品',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/combinationgoods");
  }
}, {
  path: '/combination/addgoods',
  name: '新增组合商品',
  type: 2,
  meta: {
    title: '新增组合商品',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/combinationgoods/addgoods");
  }
}, {
  path: '/combination/editgoods',
  name: '编辑组合商品',
  type: 2,
  meta: {
    title: '编辑组合商品',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/combinationgoods/addgoods");
  }
}];
export default combination;