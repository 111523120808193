import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var groupManagement = [{
  path: '/groupManagement/level',
  name: '群等级配置',
  type: 2,
  meta: {
    title: '群等级配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/groupManagement/index");
  }
}, {
  path: '/groupManagement/personalLevel',
  name: '群个人等级配置',
  type: 2,
  meta: {
    title: '群个人等级配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/groupManagement/personalLevel");
  }
}];
export default groupManagement;