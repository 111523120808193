import { render, staticRenderFns } from "./DTab.vue?vue&type=template&id=70aab841&scoped=true&"
import script from "./DTab.vue?vue&type=script&lang=js&"
export * from "./DTab.vue?vue&type=script&lang=js&"
import style0 from "./DTab.vue?vue&type=style&index=0&id=70aab841&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70aab841",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\projects\\operation-management-background\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70aab841')) {
      api.createRecord('70aab841', component.options)
    } else {
      api.reload('70aab841', component.options)
    }
    module.hot.accept("./DTab.vue?vue&type=template&id=70aab841&scoped=true&", function () {
      api.rerender('70aab841', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DTab.vue"
export default component.exports