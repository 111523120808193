import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var liveTag = [
//机器人打招呼
{
  path: '/greeting/index',
  name: '',
  type: 2,
  meta: {
    title: '机器人打招呼',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/greeting/index.vue");
  }
}];
export default liveTag;