// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var SizeApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000304
  //  系统尺码列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syssize/findpage', params);
  },
  // 查询系统尺码详情
  findoneSize: function findoneSize() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/syssize/findone', params);
  },
  //添加系统尺码
  saveSize: function saveSize() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syssize/save', params);
  },
  // 更新系统尺码信息
  updateSize: function updateSize() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syssize/update', params);
  },
  // 删除尺码
  deleteSize: function deleteSize() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/syssize/delete', params);
  },
  // 尺码查询
  getsysszie: function getsysszie() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/syssize/getsysszie', params);
  },
  // 通用尺码列表
  getsyscurrencysize: function getsyscurrencysize() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/syssize/getsyscurrencysize', params);
  },
  // 添加通用尺码
  savesyscurrencysize: function savesyscurrencysize() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/syssize/savesyscurrencysize', params);
  },
  // 系统类目列表
  getcategoryall: function getcategoryall() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/syscategory/getcategoryall', params);
  }
};
export default SizeApi;