// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var AmbianceTeamApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000051
  // 查询列表

  findPage: function findPage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/atmosphere/findPage', params);
  },
  atmosphereDetails: function atmosphereDetails() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/atmosphere/id', params);
  },
  getUserBySearchValue: function getUserBySearchValue() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/getUserBySearchValue', params);
  },
  // 新增和编辑
  atmosphereAddOrEdit: function atmosphereAddOrEdit() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'add';
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return POST('/api/communityservice/atmosphere/' + url, params);
  },
  // id或name获取用户
  getUsernameOrNicknameByUids: function getUsernameOrNicknameByUids() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/getUsernameOrNicknameByUids', params);
  },
  atmosphereDelete: function atmosphereDelete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/atmosphere/delete', params);
  }
};
export default AmbianceTeamApi;