// 账号管理页面的所有接口
import { POST } from '@/utils/util';
var DcHoodManageApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000051
  // 分页查询列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/fanshoodlaunch/manage/list', params);
  }
};
export default DcHoodManageApi;