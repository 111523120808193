/**
 * @description 充值记录相关所有接口
 */

import { POST, GET } from '@/utils/util';
var RechargeApi = {
  // 获取充值记录列表
  getList: function getList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/usd/recharge/findPage', params);
  }
};
export default RechargeApi;