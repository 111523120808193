// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var OrderApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000101
  // 鉴定订单列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/check/findpage', params);
  },
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000102
  // 获取鉴定列表tab数量
  gettabcount: function gettabcount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/check/gettabcount', params);
  },
  // 获取鉴定下拉框配置
  getcheckconfig: function getcheckconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/checkservice/checker/getcheckconfig', params);
  },
  //鉴定订单导出
  getexportchecklist: function getexportchecklist() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/checkservice/check/getexportchecklist', params, true, "blob");
  }
};
export default OrderApi;