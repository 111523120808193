import { GET, POST } from '@/utils/util';
var DcOrderApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000851
  // Dc订单列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/order/management/list', params);
  },
  detail: function detail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/dcservice/order/management/detail', params);
  },
  tabcount: function tabcount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/dcservice/order/management/tab/count', params);
  }
};
export default DcOrderApi;