var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("transition", {
    attrs: {
      name: "kw-dialog-fade"
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "kw-dialog-container",
    style: {
      zIndex: _vm.zIndex,
      background: _vm.background
    }
  }, [_vm.showModal ? _c("div", {
    staticClass: "kw-dialog-modal",
    on: {
      click: _vm.clickModal
    }
  }) : _vm._e(), _c("div", {
    ref: "kwdialog",
    staticClass: "kw-dialog-wrapper",
    style: {
      top: _vm.top,
      width: _vm.width,
      height: _vm.height
    }
  }, [_c("div", {
    staticClass: "disalog-btn-group"
  }, [_vm.fullscreen ? _c("button", {
    staticClass: "disalog-btn-item",
    on: {
      click: _vm.toggleFullscreen
    }
  }, [_vm.isFullscreen && _vm.showFullscreen ? _c("img", {
    attrs: {
      src: require("@/assets/svgs/compress_solid.svg"),
      alt: ""
    }
  }) : _vm._e(), !_vm.isFullscreen && _vm.showFullscreen ? _c("img", {
    attrs: {
      src: require("@/assets/svgs/expand_solid.svg"),
      alt: ""
    }
  }) : _vm._e()]) : _vm._e(), _vm.showClose ? _c("button", {
    staticClass: "disalog-btn-item",
    on: {
      click: _vm.clickClose
    }
  }, [_c("img", {
    staticClass: "close-icon",
    attrs: {
      src: require("@/assets/svgs/close.svg"),
      alt: ""
    }
  })]) : _vm._e()]), _vm.title ? _c("h1", {
    staticClass: "kw-dialog-title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c("div", {
    staticClass: "kw-dialog-body"
  }, [_vm._t("default")], 2), _vm.showFooter ? _c("footer", {
    staticClass: "kw-dialog-footer",
    style: {
      justifyContent: _vm.center ? "center" : "flex-end"
    }
  }, [_vm._t("footer"), !_vm.$slots.footer ? _c("div", [_c("a-button", {
    staticClass: "dialog-footer-btn cancel",
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" Cancel ")]), _c("a-button", {
    staticClass: "dialog-footer-btn confirm",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleConfirm
    }
  }, [_vm._v(" Confirm ")])], 1) : _vm._e()], 2) : _vm._e()])]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };