import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var blindbox = [{
  path: '/blindbox/index',
  name: '盲盒管理',
  type: 2,
  meta: {
    title: '盲盒列表',
    breadcrumbHidden: true,
    keepAlive: false // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/blindbox/index.vue");
  }
}];
export default blindbox;