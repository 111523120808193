import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var like = [
//you may like
{
  path: '/like/index',
  name: '喜欢',
  type: 3,
  meta: {
    title: '喜欢',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/youmaylike");
  }
}, {
  path: '/like/add',
  name: '新增',
  type: 3,
  meta: {
    title: '新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/youmaylike/addyoulike");
  }
}];
export default like;