import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var adminuser = [{
  path: '/role/index',
  name: '角色管理',
  type: 2,
  meta: {
    title: '角色管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/roleManagement");
  }
}, {
  path: '/adminuser/userindex',
  name: '修改密码',
  type: 2,
  meta: {
    title: '修改密码',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/passwordManagement");
  }
}, {
  path: '/adminuser/index',
  name: '后台账户管理',
  type: 2,
  meta: {
    title: '后台账户管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/accountBackground");
  }
}];
export default adminuser;