// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var LotteryApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000767
  // 奖项管理-列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/phpservice/admin/activity/lottery/index', params);
  },
  // 奖项管理-新增
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('api/phpservice/admin/activity/lottery/add', params);
  },
  // 奖项管理-编辑
  edit: function edit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('api/phpservice/admin/activity/lottery/edit', params);
  },
  // 奖项管理-详情
  detail: function detail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('api/phpservice/admin/activity/lottery/detail', params);
  },
  // 奖项管理-启用、禁用
  forbidden: function forbidden() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('api/phpservice/admin/activity/lottery/forbidden', params);
  },
  // 奖项管理-开奖
  runLottery: function runLottery() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('api/phpservice/admin/activity/lottery/runLottery', params);
  },
  // 奖项管理-导出
  activityExportURL: function activityExportURL() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return process.env.VUE_APP_DOMAIN + '/api/phpservice/admin/activity/activityExport';
  }
};
export default LotteryApi;