import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import Vue from "vue";
import Vuex from "vuex";
import base from "./modules/base";
import orderManagement from "./modules/orderManagement";
import homeaddgoods from "./modules/homeaddgoods";
import hotwordaddgoods from "./modules/hotwordaddgoods";
import configBanner from "./modules/configBanner";
import datadc from "./modules/datadc";
import root from "./root";
import getters from "./getters";
import dcModule from "./modules/dcModule";
import dcBanner from "./modules/dcBanner";
import dcVideo from "./modules/dcVideo";
import dcFeatures from "./modules/dcFeatures";
import lang from './modules/lang';
import knBanner from "./modules/knBanner";
Vue.use(Vuex);
var store = new Vuex.Store(_objectSpread(_objectSpread({}, root), {}, {
  getters: getters,
  modules: {
    base: base,
    orderManagement: orderManagement,
    homeaddgoods: homeaddgoods,
    hotwordaddgoods: hotwordaddgoods,
    configBanner: configBanner,
    datadc: datadc,
    dcModule: dcModule,
    dcBanner: dcBanner,
    dcVideo: dcVideo,
    dcFeatures: dcFeatures,
    lang: lang,
    knBanner: knBanner
  }
}));
export default store;