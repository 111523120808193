// 直播标签
import { POST, GET, DELETE } from '@/utils/util';
var liveTagApi = {
  //  标签列表
  managmentList: function managmentList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/laber/managment/list', params);
  },
  //新增标签
  addSave: function addSave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/laber/managment/save', params);
  },
  //  修改标签
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/laber/managment/update', params);
  }
};
export default liveTagApi;