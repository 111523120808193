import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var check = [{
  path: '/check/user',
  name: '鉴定师管理',
  type: 2,
  meta: {
    title: '鉴定师管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/appraiserSettlement");
  }
}, {
  path: '/check/user/add',
  name: '鉴定师新增',
  type: 3,
  meta: {
    title: '鉴定师新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/appraiserSettlement/checkerAdd.vue");
  }
}, {
  path: '/check/user/edit',
  name: '编辑鉴定师信息',
  type: 3,
  meta: {
    title: '鉴定师新增',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/appraiserSettlement/checkerAdd.vue");
  }
}, {
  path: '/check/user/info',
  name: '详情',
  type: 3,
  meta: {
    title: '详情',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/appraiserSettlement/checkerInfo.vue");
  }
}, {
  path: '/check/settlement',
  name: '鉴定师结算',
  type: 2,
  meta: {
    title: '鉴定师结算',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/expertManagement");
  }
}, {
  path: '/check/settlement/detail',
  name: '鉴定师结算详情',
  type: 2,
  meta: {
    title: '鉴定师结算详情',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/expertManagement/detail.vue");
  }
}, {
  path: '/check/range',
  name: '鉴定范围管理',
  type: 2,
  meta: {
    title: '鉴定范围管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/scopeAppraisalManagement");
  }
}, {
  path: '/checksettlement/index',
  name: '鉴定结算管理',
  type: 2,
  meta: {
    title: '鉴定结算管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/checksettlement");
  }
}, {
  path: '/checksettlement/editchecksettlements',
  name: '编辑(批量)',
  type: 2,
  meta: {
    title: '编辑(批量)',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/checksettlement/editchecksettlement");
  }
}, {
  path: '/checksettlement/editchecksettlement',
  name: '编辑',
  type: 2,
  meta: {
    title: '编辑',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/checksettlement/editchecksettlement");
  }
}];
export default check;