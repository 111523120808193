var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", _vm._b({
    class: [_vm.modalClass, _vm.simpleClass],
    attrs: {
      visible: _vm.visible,
      footer: null,
      bodyStyle: {
        padding: 0
      }
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, "a-modal", _vm.$props, false), [_c("div", {
    staticClass: "ant-modal-body",
    style: _vm.bodyStyle
  }, [_vm._t("default")], 2), _c("div", {
    staticClass: "ant-modal-footer relative"
  }, [_vm._t("footer", function () {
    return [_c("a-button", {
      on: {
        click: _vm.handleCancel
      }
    }, [_vm._v("取消")]), _c("a-button", {
      attrs: {
        type: "primary"
      },
      on: {
        click: _vm.handleOk
      }
    }, [_vm._v("确定")])];
  })], 2), !_vm.title && _vm.title !== "" ? _c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._t("title")], 2) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };