import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var dcdata = [{
  path: '/dcdata/index',
  name: 'DCData',
  type: 2,
  meta: {
    title: 'DC-Data',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcdataManagement/index.vue");
  }
}, {
  path: '/dcdata/addcollect',
  name: 'addcollect',
  type: 2,
  meta: {
    title: '新增合集',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dcdataManagement/addCollection.vue");
  }
}, {
  path: '/dcdata/editcollect',
  name: 'editcollect',
  type: 2,
  meta: {
    title: '编辑合集',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/dcdataManagement/addCollection.vue");
  }
}, {
  path: '/dcdata/categories',
  name: 'DCData',
  type: 2,
  meta: {
    title: 'Collection分类管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcdataManagement/categories.vue");
  }
}, {
  path: '/dcdata/source',
  name: 'DCData',
  type: 2,
  meta: {
    title: 'Collection来源管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/dcdataManagement/source.vue");
  }
}, {
  path: '/information/index',
  name: 'information',
  type: 2,
  meta: {
    title: '资讯内容管理',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/informationManagement/index.vue");
  }
}];
export default dcdata;