// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var HotWordApi = {
  // 热词列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/hotword/findall', params);
  },
  // 新增热词信息
  hotWordsave: function hotWordsave() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/hotword/hotwordsave', params);
  },
  // 更新热词信息
  updatehotWord: function updatehotWord() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/hotword/updatehotword', params);
  },
  // 排序
  updatesort: function updatesort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/hotword/updatesort', params);
  },
  // 详情
  hotworddetail: function hotworddetail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/productservice/hotword/findone', params);
  },
  // 标签页
  tabcount: function tabcount() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/productservice/hotword/tabcount', params);
  }
};
export default HotWordApi;