import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import { mapGetters } from 'vuex';
export default {
  name: "V-pagination",
  computed: _objectSpread(_objectSpread({}, mapGetters('lang', ['lang'])), {}, {
    currentPage: {
      get: function get() {
        return this.page;
      },
      set: function set(val) {
        this.$emit("update:page", val);
      }
    },
    currentSize: {
      get: function get() {
        return this.size;
      },
      set: function set(val) {
        this.$emit("update:size", val);
      }
    }
  }),
  props: {
    // middle, small
    tableSize: {
      type: String,
      default: "default"
    },
    visible: {
      type: Boolean,
      default: true
    },
    showSizeChanger: {
      type: Boolean,
      default: true
    },
    showQuickJumper: {
      type: Boolean,
      default: true
    },
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 10
    },
    pageSizeOptions: {
      type: Array,
      default: function _default() {
        return ["5", "10", "20", "30", "50"];
      }
    },
    onPageSizeChange: {
      type: Function,
      default: function _default() {}
    },
    onShowSizeChange: {
      type: Function,
      default: function _default() {}
    }
  }
};