import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var pagehome = [{
  path: '/pagehome/index',
  name: '首页模块配置',
  type: 2,
  meta: {
    title: '首页模块配置',
    breadcrumbHidden: true,
    keepAlive: true // 需要缓存
  },

  hidden: true,
  component: function component() {
    return import("@/views/homeManagement");
  }
}, {
  path: '/pagehome/add',
  name: '新增',
  type: 2,
  meta: {
    title: '新增首页模块配置',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/homeManagement/addHomepage");
  }
}, {
  path: '/pagehome/edit',
  name: '编辑',
  type: 2,
  meta: {
    title: '编辑首页模块配置',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/homeManagement/editHomepage");
  }
}, {
  path: '/pagehome/detail',
  name: '查看',
  type: 2,
  meta: {
    title: '首页模块配置详情',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/homeManagement/editHomepage");
  }
}, {
  path: '/pagehome/addlist',
  name: '添加商品',
  type: 2,
  meta: {
    title: '编辑首页模块配置/添加商品',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/homeManagement/addlist");
  }
}];
export default pagehome;