import Vue from 'vue';
import hasRole from './hasRole';
import hasPermission from './hasPermission';
import clipboard from './clipboard';
var install = function install(Vue) {
  Vue.directive('hasRole', hasRole);
  Vue.directive('hasPermission', hasPermission);
  Vue.directive('copy', clipboard);
};
if (window.Vue) {
  window['hasRole'] = hasRole;
  window['hasPermission'] = hasPermission;
  Vue.use(install); // eslint-disable-line
}

Vue.use(install);
export default install;