import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.error.cause.js";
import store from '@/store';
export default {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var all_permission = "*:*:*";
    var permissions = store.getters && store.getters.permissions;
    if (value && value instanceof Array && value.length > 0 && permissions) {
      var permissionFlag = value;
      var hasPermissions = permissions.some(function (permission) {
        return all_permission === permission || permissionFlag.includes(permission);
      });
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("\u8BF7\u8BBE\u7F6E\u64CD\u4F5C\u6743\u9650\u6807\u7B7E\u503C");
    }
  }
};