import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var topic = [{
  path: '/topic/list',
  name: 'TopicList',
  type: 3,
  meta: {
    title: '话题列表',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/topic/list");
  }
}, {
  path: '/topic/list/relation',
  name: 'TopicRelationList',
  type: 3,
  meta: {
    title: '关联列表',
    breadcrumbHidden: true
  },
  hidden: true,
  issea: true,
  component: function component() {
    return import("@/views/topic/list/relation");
  }
}];
export default topic;