import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var fansStatistics = [{
  path: '/fans-statistics',
  name: '创作者粉丝统计',
  type: 1,
  meta: {
    title: '创作者粉丝统计',
    breadcrumbHidden: true
  },
  hidden: true,
  component: function component() {
    return import("@/views/fans-statistics");
  }
}];
export default fansStatistics;