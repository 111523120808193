// 首页模块配置相关
import { POST, GET } from '@/utils/util';
import { get } from 'lodash';
var DcModuleApi = {
  // https://www.tapd.cn/68250976/markdown_wikis/show/#1168250976001000989
  // DC新闻列表
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/home/save', params);
  },
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/home/update', params);
  },
  find: function find() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/home/findone', params);
  },
  delete: function _delete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/home/delete', params);
  },
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/home/list', params);
  },
  dragsort: function dragsort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/home/dragsort', params);
  },
  getVideoReportList: function getVideoReportList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/workorder/findpage', params);
  },
  updateWorkorderStatus: function updateWorkorderStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/workorder/batchcorrection', params);
  },
  getVideoLogs: function getVideoLogs() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videolog/findpage', params);
  },
  getRejectReasonTemps: function getRejectReasonTemps() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/videos/rejectreason', params);
  },
  getRejectReasons: function getRejectReasons() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/open/getreportwords', params);
  },
  // 获取子工单
  getSubWorkorerList: function getSubWorkorerList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/workorder/getfindpageitem', params);
  },
  getLabelSceneList: function getLabelSceneList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/v2/label/scene', params);
  },
  getVideoLabelList: function getVideoLabelList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/v2/label/list', params);
  },
  // 批量更新视频标签数据状态
  updateVideoLabelStatus: function updateVideoLabelStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/v2/label/update/batch', params);
  },
  // 获取标签详情
  getVideoLabelDetail: function getVideoLabelDetail(id) {
    return GET('/api/communityservice/v2/label/' + id);
  },
  // 保存标签详情
  saveVideoLabel: function saveVideoLabel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/v2/label/save', params);
  },
  // 批量修改状态
  batchUpdateVideoLabelStatus: function batchUpdateVideoLabelStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/v2/label/update/batch', params);
  },
  // 修改标签
  updateVideoLabelInfo: function updateVideoLabelInfo() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var id = params.id;
    delete params.id;
    return POST("/api/communityservice/v2/label/".concat(id, "/update"), params);
  },
  // 获取标签日志
  getLabelLog: function getLabelLog() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/v2/label/log', params);
  },
  // 获取新运营数据
  getOperateconfig: function getOperateconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/operateconfig/findpagev2', params);
  },
  // 更新运营位状态
  updateOperateStatus: function updateOperateStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/operateconfig/updatestatusv2', params);
  },
  // 删除运营位配置
  deleteOperateconfig: function deleteOperateconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/operateconfig/deletev2', params);
  },
  // 添加运营位配置
  addOperateconfig: function addOperateconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/operateconfig/savev2', params);
  },
  // 修改运营位配置
  updateOperateconfig: function updateOperateconfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/operateconfig/updatev2', params);
  },
  // 题库列表
  getQuizList: function getQuizList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/quiz/open/manage/list', params);
  },
  // 新建答题
  createQuiz: function createQuiz() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/quiz/open/manage/create', params);
  },
  // 段位等级列表(答题)
  getQuizRankList: function getQuizRankList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/quiz/open/rank/config', params);
  },
  // 编辑题库(答题)
  editQuiz: function editQuiz() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/quiz/open/manage/edit', params);
  },
  // 删除题库(答题)
  deleteQuizById: function deleteQuizById() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/quiz/open/manage/delete', params);
  },
  // 上传模板(答题)
  uploadQuizTemp: function uploadQuizTemp() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/quiz/open/import', params, false);
  },
  // 贡献题库审核通过(答题)
  passAuditQuiz: function passAuditQuiz() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/quiz/open/manage/audit/pass', params, false);
  },
  // 贡献题库审核拒绝(答题)
  rejectAuditQuiz: function rejectAuditQuiz() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/quiz/open/manage/audit/reject', params, false);
  },
  // 题库详情(答题)
  getQuizDetails: function getQuizDetails(id) {
    return GET("/api/communityservice/quiz/open/manage/detail/".concat(id), {}, false);
  },
  // 贡献题库列表(答题)
  getAuditQuizList: function getAuditQuizList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/quiz/open/manage/audit/list', params, false);
  },
  // 活动奖励发放列表(答题)
  getQuizRewardList: function getQuizRewardList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/quiz/reward/open/list', params, false);
  },
  // 活动奖励发放类型(答题)
  getQuizRewardTyepList: function getQuizRewardTyepList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/quiz/reward/open/type', params, false);
  },
  // 获取充值记录列表
  getRechargeRecordList: function getRechargeRecordList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/recharge/records/list', params, false);
  },
  // 获取转移记录列表
  getTransferRecordList: function getTransferRecordList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/user/token/transfer/records/list', params, false);
  },
  // 获取网络类型
  getNetworkTypesList: function getNetworkTypesList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/accountservice/user/recharge/records/network/types', params, false);
  }
};
export default DcModuleApi;